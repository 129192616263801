import firebase from 'firebase';

export default {
  pessoaRef() {
    try {
      return firebase.firestore().collection('pessoas');
    } catch (error) {
      console.error('pessoa ref error', error);
      throw error;
    }
  },

  async saveByUserId(bankAccount, userid) {
    try {
      if (!userid) {
        throw 'userid is required';
      }
      const pessoaRef = this.pessoaRef().doc(userid);
      const snapshot = await pessoaRef.get();
      if (!snapshot.exists) {
        throw 'user data not exists';
      }

      const data = snapshot.data();
      const currentValue = data.dados_bancarios || {};
      const nextValue = {
        ...currentValue,
        ...bankAccount,
      };
      await pessoaRef.update({
        dados_bancarios: nextValue,
      });
    } catch (error) {
      console.error('save banck account error', error);
    }
  },
};
