<script>
import {SlideYUpTransition} from 'vue2-transitions';

export default {
  name: 'confirmation-modal',
  components: {
    SlideYUpTransition,
  },
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes',
    },
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration',
    },
    itemType: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
    confirmDelete() {
      this.$emit('confirmDelete', this.itemType);
    },
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
      } else {
        documentClasses.remove('modal-open');
      }
    },
  },
};
</script>

<template>
  <SlideYUpTransition :duration="animationDuration">
    <div
      class="modal fade"
      @click.self="closeModal"
      :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
      v-show="show"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!show"
    >
      <div class="modal-dialog">
        <div class="modal-content confirmation-modal">
          <i class="far fa-trash-alt"></i>

          <h1 class="modal-content-title">
            Você está deletando um item
          </h1>

          <p class="modal-content-description">
            Esta ação irá excluí-lo permanentemente
            <span>Você tem certeza?</span>
          </p>

          <div class="modal-buttons">
            <button
              @click="closeModal()"
              class="cancel-action"
            >
              Cancelar
            </button>

            <button
              class="delete-action"
              @click="confirmDelete()"
            >
              Deletar
            </button>
          </div>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>

<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
