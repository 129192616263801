<script>
import UsersTable from '@/components/Users/UsersTable';

import pessoasService from '@/services/Pessoas/pessoasService';
import authenticationService from '@/services/Authentication/authenticationService';
import claimsService from '@/services/Authentication/claimsService';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

import Vue from 'vue';

import { TelefonePadrao } from '@/store/cwl_enums';

import logsService from '@/services/Logs/logsService';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

export default {
  components: {
    UsersTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Usuários'
      },
      pessoas: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getPessoas();
  },
  methods: {
    async getPessoas() {
      this.$refs.topProgress.start();

      const pessoas = await pessoasService.getUsers();

      if (pessoas.empty) {
        this.$notify({
          title: 'Não há usuários cadastrados',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      pessoas.forEach((pessoa) => {
        var newPessoa = this.$CWLNewPessoaPadrao();
        newPessoa = pessoa.data();
        newPessoa.id = pessoa.id;

        var master = newPessoa.caracteristicas.filter(function(elem){
            if(elem == "Master") return elem;
        });
        if (master.length == 0) {
          this.pessoas.push(newPessoa);
        }
      });

      this.isLoading = false;

      this.$refs.topProgress.done();
    },
    async deletePessoa(preDeletedItem) {
      try {
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Remover Usuário', '', logContent);

        pessoasService.deletePessoa(preDeletedItem.item.id);

        authenticationService.deleteUser(preDeletedItem.item);

        claimsService.deleteClaim(preDeletedItem);

        this.pessoas.splice(preDeletedItem.index, 1);

        this.$notify({
          title: 'Usuário removido com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :titleBold="true"
          :addItems="true"
          :itemType="'Usuário'"
          :routerType="'user'"
        >
          <div class="table-responsive">
            <users-table
              :data="pessoas"
              :hasActions="true"
              :isLoading="isLoading"
              thead-classes="text-primary"
              @deleteItem="deletePessoa"
            ></users-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

