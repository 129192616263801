<script>
import ConfirmationModal from '@/components/ConfirmationModal';
import Vue from 'vue';
import moment from 'moment';
import XLSX from 'xlsx';

import { SlideYUpTransition } from 'vue2-transitions';
import HelperPlugin from '@/plugins/HelperPlugin';
import { CWLStatusCampanha } from '@/store/cwl_enums';

import store from '@/store/index';
import { formatCurrency } from '@/formatter/NumberFormatter';

Vue.use(HelperPlugin);

export default {
  name: 'campaigns-general-table',
  props: {
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
    SlideYUpTransition,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      showModal: false,
      searchBar: '',
      campaignStatus: CWLStatusCampanha,
      selectedStatus: '',
      campaignPeriod: [],
      items: [],
      items_exportar: [],
      totalizador: {
        total_meta: 0,
        total_arrecadado: 0,
      },
      searchBar: 'Em Andamento',
      modalidade: process.env.VUE_APP_TYPE,
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {
      this.items = [];
      this.items_exportar = [];
      this.totalizador = {
        total_meta: 0,
        total_arrecadado: 0,
      };

      const filteredItems = this.data.filter((obj) => {
        const campanhaInicio = moment(obj.dates.started_at, 'YYYY/MM/DD');
        const campanhaFim = moment(obj.dates.end_at, 'YYYY/MM/DD');
        const selectedStart = moment(this.campaignPeriod[0], 'YYYY/MM/DD');
        const selectedEnd = moment(this.campaignPeriod[1], 'YYYY/MM/DD');

        var statusFilter = true;
        if (this.selectedStatus.length > 0) {
          statusFilter = (obj.status ? obj.status : '')
            .toLowerCase()
            .includes(this.selectedStatus);
        }

        var periodoFilter = true;
        if (this.campaignPeriod.length > 0) {
          if (this.campaignPeriod[0] && this.campaignPeriod[1]) {
            periodoFilter =
              campanhaInicio.isBetween(
                selectedStart,
                selectedEnd,
                undefined,
                '[]',
              ) ||
              campanhaFim.isBetween(
                selectedStart,
                selectedEnd,
                undefined,
                '[]',
              );
          }
        }

        var pesquisarFilter = true;
        if ( this.searchBar.length > 0 ) {
          pesquisarFilter = (obj.name ? obj.name : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.dates ? this.formatDate(obj.dates.created_at) : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.status ? this.statusDescription(obj.status) : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase());
        }

        return statusFilter && periodoFilter && pesquisarFilter;
      });

      filteredItems.map((item) => {
        const values = item.summary ? item.summary.values || {} : {};
        const dates = item.summary ? item.summary.dates || {} : {};
        this.totalizador.total_meta += values.goal;
        this.totalizador.total_arrecadado += values.total;
        this.items.push({
          id: item.id,
          nome: item.name,
          inicio_fim:
            moment(item.dates.started_at).format('DD/MM/YYYY') +
            ' - ' +
            moment(item.dates.end_at).format('DD/MM/YYYY'),
          meta_captacao: formatCurrency(values.goal),
          valor_arrecadado: formatCurrency(values.total),
          status: this.statusDescription(item.status),
        });

        this.items_exportar.push({
          id: item.id,
          nome: item.name,
          inicio_fim:
            moment(item.dates.started_at).format('DD/MM/YYYY') +
            ' - ' +
            moment(item.dates.end_at).format('DD/MM/YYYY'),
          meta_captacao: formatCurrency(values.goal),
          valor_arrecadado: formatCurrency(values.total),
          status: this.statusDescription(item.status),
        });
      });

      return this.items;
    },
  },
  methods: {
    formatDate(date) {
      return this.$CWLFormatDate(date);
    },

    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },

    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.filteredData.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = page * perPage - perPage;
      const to = page * perPage;

      return data.slice(from, to);
    },

    closeModal() {
      this.showPublicarModal = false;
      this.showEnviarParaAnaliseModal = false;
    },
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    download() {
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'cwl.xlsx');
    },
    downloadCSV() {
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'cwl.csv');
    },
    viewContributions(item, index) {
      this.$router.push(`/reports/campaign-statement/${item}`);
    },
  },
  watch: {
    filteredData() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label
            class="control-label"
            v-tooltip="
              'Você pode pesquisar digitando no campo de busca abaixo. A pesquisa é realizada por nome, data de criação e status.'
            "
            style="cursor: default"
            >Pesquisar</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Para pesquisar, digite sua busca aqui..."
            v-model="searchBar"
          />
        </div>
      </div>

      <div class="col-lg-3 col-12 mb-3">
        <label for="" v-if="modalidade == 'doacao'">Período da Campanha</label>
        <label for="" v-if="modalidade == 'equity'">Período da Oferta</label>
        <date-picker
          v-model="campaignPeriod"
          valueType="format"
          range
          format="DD/MM/YYYY"
        ></date-picker>
      </div>

      <div class="col-lg-3 col-12">
        <base-input label="Status da Campanha" v-if="modalidade == 'doacao'">
          <select class="form-control" required v-model="selectedStatus">
            <option value="" selected>Selecionar</option>
            <option
              :value="status"
              v-for="(status, index) in campaignStatus"
              :key="index"
            >
              {{ statusDescription(status) }}
            </option>
          </select>
        </base-input>

        <base-input label="Status da Oferta" v-if="modalidade == 'equity'">
          <select class="form-control" required v-model="selectedStatus">
            <option value="" selected>Selecionar</option>
            <option
              :value="status"
              v-for="(status, index) in campaignStatus"
              :key="index"
            >
              {{ statusDescription(status) }}
            </option>
          </select>
        </base-input>
      </div>
    </div>

    <table class="table table-hover" :class="tableClass">
      <thead class="thead-light">
        <tr>
          <slot name="columns">
            <th>Nome</th>
            <th>Início/fim</th>
            <th>Meta de captação</th>
            <th>Valor aportado</th>
            <th>Status</th>
            <th>Ações</th>
          </slot>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in displayedData" :key="index">
          <td>
            {{ item.nome }}
          </td>
          <td>
            {{ item.inicio_fim }}
          </td>
          <td>
            {{ item.meta_captacao }}
          </td>
          <td>
            {{ item.valor_arrecadado }}
          </td>
          <td>
            {{ item.status }}
          </td>
          <td>
            <a
              @click="viewContributions(item.id, index)"
              href="#"
              title="Extrato por Campanha"
              class="button-action"
              v-if="modalidade == 'doacao'"
            >
              <i class="tim-icons icon-money-coins"></i>
            </a>
            <a
              @click="viewContributions(item.id, index)"
              href="#"
              title="Extrato por Oferta"
              class="button-action"
              v-if="modalidade == 'equity'"
            >
              <i class="tim-icons icon-money-coins"></i>
            </a>
          </td>
        </tr>
      </tbody>

      <tfoot class="thead-light" v-if="displayedData.length">
        <tr>
          <th colspan="2" style="text-align: center">Totais</th>
          <th>{{ totalizador.total_meta | currency }}</th>
          <th>{{ totalizador.total_arrecadado | currency }}</th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>

    <div class="no-data" v-if="!displayedData.length && modalidade == 'doacao'">
      Não há campanhas com os filtros selecionados :(
    </div>
    <div class="no-data" v-if="!displayedData.length && modalidade == 'equity'">
      Não há ofertas com os filtros selecionados :(
    </div>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <div class="export-button">
      <button
        class="btn btn-primary"
        @click="download"
        :disabled="!displayedData.length"
      >
        <i class="tim-icons icon-cloud-download-93"></i>
        Exportar para Excel
      </button>
      <button
        class="btn btn-primary"
        @click="downloadCSV"
        :disabled="!displayedData.length"
      >
        <i class="tim-icons icon-cloud-download-93"></i>
        Exportar para CSV
      </button>
    </div>
  </div>
</template>

<style
  lang="scss"
  scoped
  src="@/assets/sass/black-dashboard/components/_campaigns-general-table.scss"
></style>
