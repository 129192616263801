<script>
import moment from 'moment';
import { uuid } from 'vue-uuid';
import uniq from 'lodash/uniq';

export default {
  name: 'logs-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    limitExamples: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: [],
      searchBar: '',
      showAllContent: [],
      filterUser: '',
      filterDate: '',
      filterPlatform: '',
      filterClassMethodName: '',
      optionsFilters: {
        user: [],
        platform: [],
        class_method_name: [],
      },
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      const filteredData = this.data
        .filter((obj) => {
          return (
            (obj.user.email ? obj.user.email : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase()) ||
            (obj.date ? this.formatDateToFilter(obj.date) : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase()) ||
            (obj.platform ? obj.platform : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase()) ||
            (obj.class_method_name ? obj.class_method_name : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase()) ||
            (obj.content ? JSON.stringify(obj.content) : '')
              .toLowerCase()
              .includes(this.searchBar.toLowerCase())
          );
        })
        .filter((item) => {
          return (item.user.email ? item.user.email : '')
            .toLowerCase()
            .includes(this.filterUser.toLowerCase());
        })
        .filter((item) => {
          return (item.date ? this.formatDateToFilter(item.date) : '')
            .toLowerCase()
            .includes(this.filterDate ? this.filterDate.toLowerCase() : '');
        })
        .filter((item) => {
          return (item.platform ? item.platform : '')
            .toLowerCase()
            .includes(this.filterPlatform.toLowerCase());
        })
        .filter((item) => {
          return (item.class_method_name ? item.class_method_name : '')
            .toLowerCase()
            .includes(this.filterClassMethodName.toLowerCase());
        });

      const filteredDataWithId = filteredData.map((item) => {
        return {
          ...item,
          id: item.id || uuid.v4(),
        };
      });

      let users = [];
      let platforms = [];
      let class_method_names = [];

      this.data.forEach((item) => {
        if (item.user.email) {
          users.push(item.user.email);
        }
        if (item.platform) {
          platforms.push(item.platform);
        }
        if (item.class_method_name) {
          class_method_names.push(item.class_method_name);
        }
      });

      this.optionsFilters.user = uniq(users);
      this.optionsFilters.platform = uniq(platforms);
      this.optionsFilters.class_method_name = uniq(class_method_names);

      return filteredDataWithId;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }
      const numberOfPages = Math.ceil(this.displayedData.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    setShowAllContent(id, action) {
      if (action === 'show') {
        this.showAllContent.push(id);
      } else {
        this.showAllContent = this.showAllContent.filter(
          (content) => content !== id,
        );
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = page * perPage - perPage;
      const to = page * perPage;

      return data.slice(from, to);
    },
    formatDate(value) {
      if (moment(value).isValid()) {
        return moment(value).format('DD/MM/YYYY HH:mm');
      } else {
        return value;
      }
    },
    formatDateToFilter(value, type = 'search') {
      if (moment(value).isValid()) {
        if (type === 'search') {
          return moment(value).format('DD/MM/YYYY HH:mm');
        } else {
          return moment(value).format('DD/MM/YYYY');
        }
      } else if (type === 'search') {
        // need to return a string to be able to search
        return 'Timestamp' + JSON.stringify(value);
      }
    },
    highlightWord(words) {
      if (!this.searchBar) {
        return words;
      }
      if (!words) {
        return words;
      }
      const query = this.searchBar;

      return words.replace(new RegExp(query, 'gi'), (match) => {
        return '<span class="highlightText">' + match + '</span>';
      });
    },
    formatContentText(content, id) {
      return (
        this.highlightWord(JSON.stringify(content)).substring(
          0,
          !this.showAllContent.includes(id) ? 120 : 999999999,
        ) +
        (!this.showAllContent.includes(id) &&
          JSON.stringify(content).length > 120
          ? '...'
          : '')
      );
    },
    showAllContentWithResults() {
      if (this.searchBar) {
        this.showAllContent = this.displayedData.map((item) => item.id);
      } else {
        this.showAllContent = [];
      }
    },
  },
  watch: {
    data() {
      this.setPages();
    },
    searchBar() {
      this.setPages();
      this.showAllContentWithResults();
    },
    filterUser() {
      this.setPages();
    },
    filterDate() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-6 col-xl-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..."
            v-model="searchBar" />
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-2">
        <base-input>
          <select class="form-control" v-model="filterUser">
            <option value="" selected>Selecione o usuário</option>
            <option :value="user" v-for="(user, index) in optionsFilters.user" :key="index">
              {{ user }}
            </option>
          </select>
        </base-input>
      </div>
      <div class="col-12 col-lg-6 col-xl-2">
        <base-input>
          <select class="form-control" v-model="filterPlatform">
            <option value="" selected>Selecione a plataforma</option>
            <option :value="platform" v-for="(platform, index) in optionsFilters.platform" :key="index">
              {{ platform }}
            </option>
          </select>
        </base-input>
      </div>
      <div class="col-12 col-lg-6 col-xl-2">
        <base-input>
          <select class="form-control" v-model="filterClassMethodName">
            <option value="" selected>Selecione a ação</option>
            <option :value="classMethodName" v-for="(
                  classMethodName, index
                ) in optionsFilters.class_method_name" :key="index">
              {{ classMethodName }}
            </option>
          </select>
        </base-input>
      </div>
      <div class="col-12 col-lg-6 col-xl-2 mb-3">
        <date-picker v-model="filterDate" valueType="format" format="DD/MM/YYYY"></date-picker>
      </div>
    </div>
    <table class="table tablesorter limits-table" :class="tableClass">
      <thead :class="theadClasses">
        <tr>
          <slot name="columns">
            <th v-for="column in columns" :key="column">{{ column }}</th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in this.paginate(displayedData)" :key="index">
          <td v-html="highlightWord(item.user.email)"></td>
          <td v-html="highlightWord(item.platform)"></td>
          <td v-html="highlightWord(item.class_method_name)"></td>
          <td v-html="highlightWord(formatDateToFilter(item.date))"></td>
          <td :key="index" @click="
            () =>
              setShowAllContent(
                item.id,
                !showAllContent.includes(item.id) ? 'show' : 'hide',
              )
          ">
            <div class="logs-wrapper">
              <div class="logs-content" v-html="formatContentText(item.content, item.id)"></div>
              <div>
                <i v-if="JSON.stringify(item.content).length > 120" style="margin-left: 8px; margin-right: 8px" :class="{
                  'fas fa-chevron-up': showAllContent.includes(item.id),
                  'fas fa-chevron-down': !showAllContent.includes(item.id),
                }">
                </i>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button type="button" class="page-link" v-if="page != 1" @click="page--">
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button type="button" class="page-link" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index" @click="page = pageNumber" :class="{ 'is-active': page === pageNumber }">
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button type="button" class="page-link" v-if="page < pages.length" @click="page++">
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style>
div.logs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.logs-content {
  max-width: 100%;
}

@media(max-width: 1999px) {
  div.logs-content {
    max-width: 80vw;
  }
}

@media(max-width: 1799px) {
  div.logs-content {
    max-width: 600px;
  }
}

@media(max-width: 1580px) {
  div.logs-content {
    max-width: 400px;
  }
}

.highlightText {
  color: white;
  background-color: #525f7f;
}

.table-responsive {
  overflow: auto;
  padding: 0 15px;
}
</style>
