import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import App from './App';

import router from './router/index';
import store from './store/index';

import BlackDashboard from './plugins/blackDashboard';
import i18n from './i18n';
import './registerServiceWorker';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import moment from 'moment';
import VueMoment from 'vue-moment';
import VueTour from 'vue-tour';
import DatePicker from 'vue2-datepicker';

require('moment/locale/pt-br');
require('../node_modules/vue-birth-datepicker/dist/vueBirthDatepicker.css');
require('vue-tour/dist/vue-tour.css');
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import { initializeFirebaseApp } from './firebase';

moment.locale('pt-br');
Vue.use(VueMoment, { moment });
Vue.use(DatePicker);
Vue.use(VueTour);
Vue.use(VueTheMask);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

Vue.filter('currency', function (value) {
  const val = parseFloat(value).toFixed(2).replace('.', ',');
  return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('percentage', function (value) {
  return parseFloat(value).toFixed(2) + '%';
});

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'BRL' },
};
Vue.use(VueCurrencyInput, pluginOptions);

/* eslint-disable no-new */
const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});

initializeFirebaseApp(() => app.$mount('#app'));
