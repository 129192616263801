import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  ref() {
    return firebase.firestore().collection('contratos_investidor');
  },

  getAll() {
    return withLog(async () => {
      const snapshot = await this.ref().get();
      return snapshot.docs.map((e) => ({
        ...e.data(),
        id: e.id,
      }));
    });
  },

  get(id) {
    return withLog(() => this.ref().doc(id).get());
  },

  getByOrderId(orderId) {
    return withLog(async () => {
        const snapshot = await this.ref().where("order_id", "==", orderId).get();
        return snapshot.docs.map((e) => ({
          ...e.data(),
          id: e.id,
        }));
      });
  },

  update(id, object) {
    return withLog(() => this.ref().doc(id).update(object));
  },
};
