import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  investmentProfileRef() {
    return firebase.firestore().collection('investments-profile');
  },

  getAllInvestments() {
    return withLog(() =>
      this.investmentProfileRef().orderBy('profile', 'asc').get(),
    );
  },

  createNewInvestment(investment) {
    withLog(() => this.investmentProfileRef().add(investment));
  },

  deleteInvestment(investment) {
    withLog(() => this.investmentProfileRef().doc(investment).delete());
  },

  getInvestment(id) {
    return withLog(() => this.investmentProfileRef().doc(id).get());
  },

  updateInvestment(id, investment) {
    return withLog(() =>
      this.investmentProfileRef().doc(id).update(investment),
    );
  },
};
