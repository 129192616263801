import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

import logsService from '@/services/Logs/logsService';

Vue.use(Vuex);

const getDefaultState = () => ({
  paymentCheckout: {
    financingId: null,
  },
  user: {},
  claim: {},
  pessoa: {},
  isTermOfUseAccepted: false,
  uploadedDocuments: {},
  user_temp: {},
});

const vuexKeyByEnvironment = (key) => {
  const suffix = process.env.VUE_APP_VUEX_KEY_SUFFIX || '';
  return key + suffix;
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) =>
          Cookies.get(vuexKeyByEnvironment(key), {
            domain: process.env.VUE_APP_DOMAIN,
            path: '/',
          }),
        setItem: (key, value) =>
          Cookies.set(vuexKeyByEnvironment(key), value, {
            expires: 1,
            secure: false,
            domain: process.env.VUE_APP_DOMAIN,
            path: '/',
          }),
        removeItem: (key) =>
          Cookies.remove(vuexKeyByEnvironment(key), {
            domain: process.env.VUE_APP_DOMAIN,
            path: '/',
          }),
      },
    }),
  ],
  state: getDefaultState(),
  getters: {
    isLoggedIn: (state) => state.user,
    getUserTemp: (state) => state.user_temp,
    userClaim: (state) => state.claim || { role: 'Usuário' },
    getPessoa: (state) => state.pessoa,
    isTermOfUseAccepted: (state) => state.isTermOfUseAccepted,
    uploadedDocuments: (state) => state.uploadedDocuments,
    getOferta: (state) => state.oferta,
    paymentCheckout: (state) => state.paymentCheckout,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USER_TEMP: (state, user) => {
      state.user_temp = user;
    },
    RESET: (state) => {
      logsService.createNewLog(state, 'RESET', 'Logout', 'POST', '');

      Object.assign(state, getDefaultState());
    },
    SET_CLAIM: (state, claim) => {
      state.claim = claim;
    },
    SET_PESSOA: (state, pessoa) => {
      state.pessoa = pessoa;
    },
    SET_TERM_OF_USE_ACCEPTED: (state, isTermOfUseAccepted) => {
      state.isTermOfUseAccepted = isTermOfUseAccepted;
    },
    SET_UPLOAD_DOCUMENT: (state, document) => {
      state.uploadedDocuments[document] = true;
    },
    SET_OFERTA_ID_TEMP: (state, oferta) => {
      if (oferta.length == 0) {
        state.oferta = {};
      } else {
        state.oferta.oferta_id = oferta;
      }
    },
  },
  actions: {
    login: ({ commit }, { user }) => {
      commit('SET_USER', user);
    },
    loginTemp: ({ commit }, { user }) => {
      commit('SET_USER_TEMP', user);
    },
    newPessoa: ({ commit }, { pessoa }) => {
      commit('SET_PESSOA', pessoa);
    },
    logout: ({ commit }) => {
      commit('RESET', '');
    },
    newClaim: ({ commit }, { claim }) => {
      commit('SET_CLAIM', claim);
    },
  },
});
