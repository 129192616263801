<script>
import pessoasService from '@/services/Pessoas/pessoasService';
import { DoubleBounce } from 'vue-loading-spinner';
import { cnpj } from '@/validate/customValidators.js';

export default {
  name: 'company-identification-form',
  props: {
    error: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Identificação da empresa',
    },
    onBack: {
      type: Function,
      required: false,
    },
    initialValue: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      value: {
        identificationNumber: '',
        name: '',
        displayName: '',
        ...this.initialValue,
      },
    };
  },
  validations: {
    value: {
      identificationNumber: {
        valid: cnpj,
      },
    },
  },
  computed: {},
  components: {
    DoubleBounce,
  },
  methods: {
    handleChange() {
      this.$emit('change', this.value);
    },
    async checkNumber() {
      this.loading = true;
      try {
        if (!this.value.identificationNumber) {
          this.value = {
            identificationNumber: '',
            name: '',
            displayName: '',
          };
          return;
        }

        const numberPattern = /\d+/g;
        const number = this.value.identificationNumber
          .match(numberPattern)
          .join('');
        if (number.length == 14) {
          let response = await pessoasService.searchPJByCNPJ(number);
          if (response.size === 0) {
            response = await pessoasService.searchExternalPJByCNPJ(number);
          }

          if (!response) {
            this.$notify({
              title: 'Ooopss',
              message:
                'Não foi possível realizar a criação para esta empresa privada ou entidade sem fins lucrativos. Por favor, entre em contato com a plataforma ou desmarque esta opção.',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
          } else {
            this.value = {
              ...this.value,
              name: response.nome,
              displayName:
                response.dados_complementares &&
                response.dados_complementares.nome_fantasia
                  ? response.dados_complementares.nome_fantasia
                  : response.nome,
            };
          }
        }
      } catch (error) {
        console.error('error on check company number', error);
      } finally {
        this.loading = false;
        this.handleChange();
      }
    },
  },
  async mounted() {
    this.loading = false;
  },
};
</script>

<template>
  <card>
    <double-bounce v-if="loading"></double-bounce>
    <form @submit.prevent="handleChange()" v-if="!loading">
      <h6 slot="header" class="title">
        <a v-if="onBack" v-on:click="onBack()" class="fa fa-arrow-left"></a>
        {{ title }}
      </h6>

      <div style="color: red" v-if="error">{{ error }}</div>

      <div class="row">
        <div class="col-md-4 mt-3">
          <base-input
            label="CNPJ*"
            v-model="value.identificationNumber"
            placeholder="Informe o CNPJ da entidade"
            v-mask="['##.###.###/####-##']"
            v-on:change="checkNumber"
            :disabled="readonly"
            required
          ></base-input>
          <div style="color: red" v-if="$v.value.identificationNumber.$invalid">
            Informe um CNPJ válido.
          </div>
        </div>
        <div class="col-md-4 mt-3">
          <base-input
            label="Razão Social*"
            v-model="value.name"
            placeholder="Informe a razão social da entidade"
            required
            :disabled="readonly"
          ></base-input>
        </div>
        <div class="col-md-4 mt-3">
          <base-input
            label="Nome Fantasia*"
            v-model="value.displayName"
            placeholder="Informe o nome fantasia da entidade"
            required
            :disabled="readonly"
          ></base-input>
        </div>
      </div>
    </form>
  </card>
</template>
