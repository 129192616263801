<script>
import CategoriasTable from '@/components/Categorias/CategoriasTable';
import categoriasService from '@/services/Categorias/categoriasService';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

const tableColumns = ['Nome'];

export default {
  components: {
    CategoriasTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Categorias',
        columns: [...tableColumns],
      },
      categorias: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getCategorias();
  },
  methods: {
    async getCategorias() {
      this.$refs.topProgress.start();
      try {
        this.categorias = await categoriasService.getAllCategorias();
        if (this.categorias.length === 0) {
          this.$notify({
            title: 'Não há Categorias cadastrados, tente novamente.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }
      } catch (error) {}
      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    deleteCategoria(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(
          logUser,
          '',
          'Excluir Categoria',
          '',
          logContent,
        );

        categoriasService.deleteCategoria(preDeletedItem.item.id);

        this.getCategorias();

        this.$notify({
          title: 'A Categoria foi deletado com sucesso.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          title:
            'Houve um erro ao deletar a Categoria! por favor, tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Categoria'"
          :routerType="'categoria'"
        >
          <div class="table-responsive">
            <categorias-table
              :data="categorias"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteCategoria"
            ></categorias-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
