<script>
import SidebarLink from './SidebarLink';
import settingsService from '@/services/Settings/settingsService';

export default {
  props: {
    title: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: 'primary',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        const acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink,
    };
  },
  components: {
    SidebarLink,
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @return {{transform: string}}
     */
    arrowMovePx() {
      return this.linkHeight * this.activeLinkIndex;
    },
    shortTitle() {
      return this.title
        .split(' ')
        .map((word) => word.charAt(0))
        .join('')
        .toUpperCase();
    },
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
  },
  data() {
    return {
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: [],
      logoUrl: require(process.env.VUE_APP_LOGO_URL),
      titulo: '',
    };
  },
  methods: {
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
    async getSettings() {
      let privateSettings = (await settingsService.getPrivateSettings()) || {};

      this.titulo = privateSettings.platformName || '';
    },
    addLink(link) {
      const index = this.$slots.links.indexOf(link.$vnode);
      this.links.splice(index, 0, link);
    },
    removeLink(link) {
      const index = this.links.indexOf(link);
      if (index > -1) {
        this.links.splice(index, 1);
      }
    },
  },
  mounted() {
    this.$watch('$route', this.findActiveLink, {
      immediate: true,
    });
    this.getSettings();
  },
};
</script>

<template>
  <div class="sidebar" :data="backgroundColor">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <img src="@/assets/images/logo_ajx.png" alt="Customer Logo" class="brand" style="width: 200px !important;" />
      </div>
      <slot> </slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="index"
            :to="link.path"
            :name="link.name"
            :icon="link.icon"
          >
          </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>

<style scoped>
#logo {
    /* padding: 40px 0 0 0; */
    overflow: hidden;
    background-image: "@/assets/images/logo_ajx.png";
    background-repeat: no-repeat;
    height: 0px !important;
    /* height: 70px; */
    background-position: center;
    background-color: #333;
}
</style>