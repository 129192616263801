/**
 * ENUMs principalmente utilizados na entidade Pessoa
 */

const TipoPessoa = {
  PF: 'Pessoa Física',
  PJ: 'Pessoa Jurídica',
};

const CWLStatusInvestidor = {
  Incompleto: 'Incompleto',
  EmAnalise: 'Em Análise',
  Aprovado: 'Aprovado',
  Reprovado: 'Reprovado',
  Ativo: 'Ativo',
  Inativo: 'Inativo'
};

/**
 * Endereços
 */
const TipoEndereco = {
  Residencial: 'Residencial',
  Comercial: 'Comercial',
};

const TiposEndereco = [TipoEndereco.Residencial, TipoEndereco.Comercial];

/**
 * Telefones
 */
const TipoTelefone = {
  Celular: 'Celular',
  Fixo: 'Fixo',
  Comercial: 'Comercial',
};

const TiposTelefone = [TipoTelefone.Celular, TipoTelefone.Fixo];

/**
 * E-mails
 */
const TipoEmail = {
  Pessoal: 'Pessoal',
  Profissional: 'Profissional',
  Comercial: 'Comercial',
};

const TiposEmail = [TipoEmail.Pessoal, TipoEmail.Profissional];

/**
 * Documentos
 */
const TipoDocumento = {
  CPF: 'CPF',
  RG: 'RG',
  CNPJ: 'CNPJ',
};

const TiposDocumento = [TipoDocumento.CPF, TipoDocumento.RG];

/**
 * Características
 */
const PessoaCaracteristica = {
  Master: 'master',
  Gestor: 'gestor',
  Curador: 'curador',
  Financiador: 'financiador',
  Usuario: 'usuario',
  Doador: 'doador',
  Investidor: 'investidor',
  InvestorRole: 'investor',
  OrganizadorSocial: 'organizador-social',
  Empreendedor: 'empreendedor',
  Patrocinador: 'patrocinador',
  PessoaFisica: 'pessoa-fisica',
  PessoaJuridica: 'pessoa-juridica',
  Apoiador: 'apoiador',
  InvestidorEquity: 'investidor-equity',
  InvestidorDebt: 'investidor-debt',
};

const PessoaCaracteristicas = [
  PessoaCaracteristica.Master,
  PessoaCaracteristica.Gestor,
  PessoaCaracteristica.Curador,
  PessoaCaracteristica.Financiador,
  PessoaCaracteristica.Doador,
  PessoaCaracteristica.Investidor,
  PessoaCaracteristica.Usuario,
  PessoaCaracteristica.Empreendedor,
];

/**
 * Status das Campanhas
 */

const CWLStatusCampanha = {
  EmCriacao: 'em-criacao',
  AguardandoAnalise: 'aguardando-analise',
  EmAnalise: 'em-analise',
  Aprovada: 'aprovada',
  Reprovada: 'reprovada',
  EmAndamento: 'em-andamento',
  RemovidaDoAr: 'removida-do-ar',
  ConcluidaComSucesso: 'concluida-com-sucesso',
  ConcluidaSemSucesso: 'concluida-sem-sucesso',
  Suspensa: 'suspensa',
  Cancelada: 'cancelada',
  Excluida: 'excluida'
};

const CWLTipoPagamento = {
  CartaoCredito: 'CartaoCredito',
  Boleto: 'Boleto',
  Reservado: 'Reservado',
  TED: 'TED',
  PIX: 'Pix'
};

const CWLTipoTaxaPagamento = {
  Fixo: '2',
  Percentual: '1'
};

const CWLTipoRepasse = {
  Fixo: '2',
  Percentual: '1'
};

const CWLInvestimentoDashboard = {
  Pagos: 'pagos',
  PagosPendentes: 'pagos_pendentes'
};

/**
 * Objetos padrões
 */
const EmailPadrao = {
  principal: true,
  tipo: TipoEmail.Pessoal,
  email: '',
};

const TelefonePadrao = {
  principal: true,
  tipo: TipoTelefone.Celular,
  telefone: '',
};

const EnderecoPadrao = {
  principal: true,
  tipo: TipoEndereco.Residencial,
  rua: '',
  numero: '',
  complemento: '',
  bairro: '',
  cep: '',
  cidade: '',
  estado: '',
  pais: '',
};

const DocumentoPadrao = {
  principal: true,
  tipo: TipoDocumento.CPF,
  numero: '',
};

const PessoaPadrao = {
  tipo_pessoa: TipoPessoa.PF,
  caracteristicas: [],
  nome: '',
  enderecos: [],
  endereco: {},
  telefones: [],
  telefone: {},
  emails: [],
  email: {},
  documentos: [],
  documento: {},
  photoURL: '',
  dados_complementares: {
    dados_bancarios: {},
    perfil: {}
  },
};

const PessoaPadraoPJ = {
  tipo_pessoa: TipoPessoa.PJ,
  caracteristicas: [],
  nome: '',
  enderecos: [],
  endereco: {},
  telefones: [],
  telefone: {},
  emails: [],
  email: {},
  documentos: [],
  documento: {},
  photoURL: '',
  dados_complementares: {},
};

const FinanciamentoCategoriaPadrao = {
  name: '',
};

const FinanciamentoEstadoPadrao = {
  name: 'Rio Grande do Sul',
  order: 2,
  uf: 'RS',
};

const FinanciamentoPadrao = {
  fields: {},
  status: CWLStatusCampanha.EmCriacao,
  imageURL:
    'https://firebasestorage.googleapis.com/v0/b/crowdfunding-wl-dev.appspot.com/o/Financings%2Fempty-image.png?alt=media&token=395d0874-22b4-42e4-acad-37be72fa73cd',
  dates: {},
  users: {},
  name: '',
  categoria: null,
  estado: {},
  cidade: null,
  is_empresa: false,
  dados_complementares: {},
  totais: {},
  curadoria: {},
};

const BancarizadorIntegracaoID = {
  Socinal: "SOCINAL",
  Fiducia: "FIDÚCIA",
  Manual: "MANUAL"
};

/**
 * Retornos de erro da Firebase
 */
const CWLFirebaseError = {
  emailJaExistente: 'auth/email-already-in-use',
};

/**
 * Referencias para redirect
 */

const CWLRedirectRef = {
  siteCriarCampanha: 'refSiteCriarCampanha',
};

export {
  TipoEndereco,
  TiposEndereco,
  TipoTelefone,
  TiposTelefone,
  TipoEmail,
  TiposEmail,
  TipoDocumento,
  TiposDocumento,
  PessoaCaracteristica,
  PessoaCaracteristicas,
  TipoPessoa,
  EmailPadrao,
  TelefonePadrao,
  EnderecoPadrao,
  DocumentoPadrao,
  PessoaPadrao,
  PessoaPadraoPJ,
  CWLFirebaseError,
  CWLRedirectRef,
  FinanciamentoPadrao,
  FinanciamentoCategoriaPadrao,
  FinanciamentoEstadoPadrao,
  CWLStatusCampanha,
  CWLTipoPagamento,
  CWLTipoRepasse,
  CWLStatusInvestidor,
  CWLTipoTaxaPagamento,
  CWLInvestimentoDashboard,
  BancarizadorIntegracaoID
};
