<script>
import categoriasService from '@/services/Categorias/categoriasService';
import logsService from '@/services/Logs/logsService';
import { vueTopprogress } from 'vue-top-progress';

export default {
  data() {
    return {
      categoria: {},
      cachedCategoria: {},
    };
  },
  components: {
    vueTopprogress,
  },
  computed: {
    categoriaId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.loadCategoria();
  },
  methods: {
    async loadCategoria() {
      this.$refs.topProgress.start();
      try {
        this.categoria = await categoriasService.findById(this.categoriaId);
      } catch (error) {
        this.$notify({
          title: 'Não foi possível carregar a categoria!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
      this.$refs.topProgress.done();
    },

    async updateCategoria() {
      this.$refs.topProgress.start();

      try {
        const user = {
          email: this.currentPessoa.email.email,
        };

        await categoriasService.update(this.categoria, user);

        this.$refs.topProgress.done();

        this.$router.push('/categorias');

        this.$notify({
          title: 'Atualização da Categoria foi realizada com sucesso.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          title: 'Erro ao atualizar informações da Categoria.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateCategoria()">
        <h5 slot="header" class="title">
          <router-link to="/categorias" class="fa fa-arrow-left"></router-link>
          Editar Categoria
        </h5>

        <div class="row">
          <div class="col-md-4 pl-md-1">
            <base-input
              label="Nome da Categoria*"
              placeholder="Nome da Categoria"
              v-model="categoria.name"
              required
            >
            </base-input>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Editar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
