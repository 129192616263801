<script>
import banksService from '@/services/Banks/banksService';
import UpdatedBankNotification from '@/components/Notifications/Banks/UpdatedBankNotification';
import UpdatedBankErrorNotification from '@/components/Notifications/Banks/UpdatedBankErrorNotification';
import GetBankErrorNotification from '@/components/Notifications/Banks/GetBankErrorNotification';
import logsService from '@/services/Logs/logsService';
import {vueTopprogress} from 'vue-top-progress';

export default {
  data() {
    return {
      bank: {},
      cachedBank: {},
    };
  },
  components: {
    vueTopprogress,
  },
  computed: {
    bankId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getBank();
  },
  methods: {
    async getBank() {
      this.$refs.topProgress.start();

      try {
        banksService.getBank(this.bankId)
            .then(function(bank) {
              this.bank = bank.data();
              this.cachedBank = bank.data();

              this.$refs.topProgress.done();
            }.bind(this));
      } catch (error) {
        this.$notify({
          component: GetBankErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async updateBank() {
      this.$refs.topProgress.start();

      try {
        this.$refs.topProgress.done();

        const bankFound = await banksService.getByCode(this.bank.code);
        if ( bankFound.size > 1 ) {
          this.$notify({
            title: 'Ooopss',
            message: 'Desculpe, banco já existente.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          this.$refs.topProgress.done();
          return;
        }
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        logsService.createNewLog(logUser, '', 'Atualizar Banco', '', this.bank);
        await banksService.updateBank(this.bankId, this.bank);

        this.$router.push('/banks');

        this.$notify({
          component: UpdatedBankNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          component: UpdatedBankErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateBank()">
        <h5 slot="header" class="title">
          <router-link to="/banks" class="fa fa-arrow-left"></router-link>
          Editar Banco
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
          <base-input
              label="Código*"
              placeholder="Código"
              v-mask="['########']"
              v-model="bank.code"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Nome do Banco*"
              placeholder="Nome do Banco"
              v-model="bank.name"
              required
            >
            </base-input>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-sm"
        >
          Editar
        </button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
