import utils from './utils';
import { withLog } from '../../logger/with_log';
import firebase from 'firebase';

export default {
  orderRef() {
    return firebase.firestore().collection('orders');
  },

  async getOrdersByFieldEqualsTo(field, value) {
    try {
      const snapshot = await this.orderRef().where(field, '==', value).get();
      if (snapshot.empty) {
        return [];
      } else {
        return snapshot.docs.map((value) => ({
          id: value.id,
          ...value.data(),
        }));
      }
    } catch (error) {
      console.error(
        'get orders by ' + field + ' == ' + value + ' error',
        error,
      );
      return [];
    }
  },

  async getOrdersByUser(id) {
    return this.getOrdersByFieldEqualsTo('pessoa.id', id);
  },

  async getOrdersByFinancingId(id) {
    return this.getOrdersByFieldEqualsTo('financing_id', id);
  },

  async update(id, order) {
    return withLog(() => this.orderRef().doc(id).update(order));
  },

  async get(id) {
    return withLog(() => this.orderRef().doc(id).get());
  },

  async getOrderByUserAndId(pessoaId, id) {
    const orders = await this.getOrdersByUser(pessoaId);
    return await utils.orderById(orders, id);
  },
};
