import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  bancarizadoresRef() {
    return firebase.firestore().collection('bancarizadores');
  },

  getAll() {
    return withLog(async () => {
      const snapshot = await this.bancarizadoresRef().where("excluido", "==", false).orderBy('razao_social', 'asc').get();
      return snapshot.docs.map((e) => ({
        ...e.data(),
        id: e.id,
      }));
    });
  },

  add(bancarizador) {
    bancarizador.excluido = false;
    return withLog(() => this.bancarizadoresRef().add(bancarizador));
  },

  delete(bancarizador) {
    withLog(() => this.bancarizadoresRef().doc(bancarizador).delete());
  },

  logicalDelete(bancarizador) {
    bancarizador.excluido = true;
    return withLog(() => this.bancarizadoresRef().doc(bancarizador.id).update(bancarizador));
  },

  get(id) {
    return withLog(() => this.bancarizadoresRef().doc(id).get().then((snapshot) => (snapshot ? snapshot.data() : null)));
  },

  update(id, bancarizador) {
    return withLog(() => this.bancarizadoresRef().doc(id).update(bancarizador));
  },

  async removerPadraoDeTodos() {
    let all = await this.getAll();
    all.forEach(async bancarizador => {
      if ( !bancarizador.padrao ) {
        bancarizador.padrao = {
          value: false,
          label: 'NÃO'
        };
      }
      else {
        bancarizador.padrao.value = false;
        bancarizador.padrao.label = 'NÃO';
      }

      await this.update(bancarizador.id, bancarizador);
    });
  }
};
