<script>
import StatesTable from '@/components/States/StatesTable';
import statesService from '@/services/States/statesService';

import EmptyStateNotification from '@/components/Notifications/States/EmptyStateNotification';
import DeleteStateErrorNotification from '@/components/Notifications/States/DeleteStateErrorNotification';
import DeleteStateSuccessNotification from '@/components/Notifications/States/DeleteStateSuccessNotification';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

const tableColumns = ['Nome', 'UF'];

export default {
  components: {
    StatesTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Estados',
        columns: [...tableColumns],
      },
      states: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getStates();
  },
  methods: {
    async getStates() {
      this.$refs.topProgress.start();
      try {
        this.states = await statesService.getAllStates();
        if (this.states.length === 0) {
          this.$notify({
            component: EmptyStateNotification,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;

      this.$refs.topProgress.done();
    },
    deleteState(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Remover Estado', '', logContent);

        statesService.deleteStates(preDeletedItem.item.id);

        this.getStates();

        this.$notify({
          component: DeleteStateSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          component: DeleteStateErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Estado'"
          :routerType="'state'"
        >
          <div class="table-responsive">
            <states-table
              :data="states"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteState"
            ></states-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
