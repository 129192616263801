<script>
import operationalsService from '@/services/Operationals/operationalsService';

import GetUserErrorNotification from '@/components/Notifications/Users/GetUserErrorNotification';
import UpdateUserErrorNotification from '@/components/Notifications/Users/UpdateUserErrorNotification';
import UpdateUserSuccessNotification from '@/components/Notifications/Users/UpdateUserSuccessNotification';
// import {PessoaPadrao} from '@/store/cwl_enums';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import {vueTopprogress} from 'vue-top-progress';

export default {
  name: 'edit-operational-form',
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    vueTopprogress,
  },
  data() {
    return {
      operationalInformations: [],
      pessoa: {},
    };
  },
  computed: {
    operational() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    this.pessoa = this.$CWLNewPessoaPadrao();
    this.getUserInformations();
  },
  methods: {
    async getUserInformations() {
      this.$refs.topProgress.start();

      try {
        const userInformations = await operationalsService.getOperational(this.operational.email);

        await userInformations.forEach((operational) => {
          const newOperational = operational.data();
          newOperational.id = operational.id;

          this.operationalInformations.push(newOperational);
        });

        this.pessoa = this.operationalInformations[0];
      } catch (e) {
        this.$notify({
          component: GetUserErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async updateOperationalInformations() {
      this.$refs.topProgress.start();

      try {
        const userId = this.pessoa.id;

        await operationalsService.updateOperational(userId, this.pessoa);

        this.getUserInformations();

        this.$notify({
          component: UpdateUserSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (e) {
        this.$notify({
          component: UpdateUserErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
  },
};
</script>

<template>
  <card>
    <router-link to="profile" tag="h5" slot="header" class="title">Meus Dados</router-link>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input
          label="Nome*"
          placeholder="Nome"
          v-model="pessoa.nome">
        </base-input>
      </div>
      <div class="col-md-5 px-md-1">
        <base-input
          label="Sobrenome*"
          placeholder="Sobrenome"
          v-model="pessoa.dados_complementares.lastname"
        ></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10">
        <base-input
          label="E-mail*"
          v-model="pessoa.email.email"
          disabled
          placeholder="E-mail"
        > </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input
          label="Celular com DDD*"
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-model="pessoa.telefone.telefone"
          placeholder="Celular com DDD"
        > </base-input>
      </div>
      <div class="col-md-5 pl-md-1">
        <base-input
          label="Data de Nascimento*"
          type="text"
          placeholder="dd/mm/yyyy"
          v-model="pessoa.dados_complementares.birthdate"
          v-mask="'##/##/####'"
        ></base-input>
      </div>
    </div>

    <base-button slot="footer" type="primary" @click="updateOperationalInformations()">
      Salvar
    </base-button>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </card>
</template>

