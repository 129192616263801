import axios from 'axios';
import moment from 'moment';
import clickSignService from '@/services/ClickSign/clickSignService';

export default {
  async adicionarSignatarioAoDocumento(document_key, signer_key) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'clicksign_add_signatario_to_doc';

    const payload = {
        document_key: document_key,
        signer_key: signer_key
    };
    return axios.post(endpoint, payload).then(r => r.data);
  },

  async cancelarDocumento(document_key) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'clicksign_cancelar_doc';

    const payload = {
        document_key: document_key
    };

    try {
        return axios.post(endpoint, payload).then(r => r.data);
    } catch (error) {
        throw new Error(error.message);
    }
  }
};