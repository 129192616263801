<script>
import GetInvestorErrorNotification from '@/components/Notifications/Investors/GetInvestorErrorNotification';

import pessoasService from '@/services/Pessoas/pessoasService';

import {
  CWLStatusInvestidor,
  PessoaCaracteristica
} from '@/store/cwl_enums';

export default {
  name: 'register-disclaimer',
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    role() {
      const value = (this.$store.getters.userClaim && this.$store.getters.userClaim.role)
        ? this.$store.getters.userClaim.role
        : 'investor';
      return value.toLowerCase();
    },
    isUsuario() {
      return this.role == PessoaCaracteristica.InvestorRole;
    },
    CWLStatusInvestidor() {
      return CWLStatusInvestidor;
    }
  },
  data() {
    return {
      submodalidade: process.env.VUE_APP_SUBTYPE,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div>
    <div
      v-if="isUsuario && currentPessoa.dados_complementares.approvalStatus === CWLStatusInvestidor.Incompleto && submodalidade != '588'"
      role="alert"
      class="alert alert-warning"
    >
      <span>
        Seu cadastro não está completo. Por favor verifique os dados que faltam na opção 
        <router-link to="/profile" tag="span" style="cursor: pointer !important;">
          <b>Meus Dados</b>.
        </router-link>
      </span>
    </div>

    <div
      v-if="isUsuario && currentPessoa.dados_complementares.approvalStatus === CWLStatusInvestidor.EmAnalise && submodalidade != '588'"
      role="alert"
      class="alert alert-info"
      style="background-color: #00035A !important;"
    >
      <span>
        Seu cadastro já foi preenchido, e está sob análse. Você será informado por e-mail de qualquer atualização.
      </span>
    </div>

    <div
      v-if="isUsuario && currentPessoa.dados_complementares.approvalStatus === CWLStatusInvestidor.Reprovado && submodalidade != '588'"
      role="alert"
      class="alert alert-info"
      style="background-color: #00035A !important;"
    >
      <span>
        Seu cadastro foi reprovado. Por favor verifique o motivo e faça as correções na opção 
        <router-link to="/profile" tag="span" style="cursor: pointer !important;">
          <b>Meus Dados</b>.
        </router-link>
      </span>
    </div>
  </div>
</template>
