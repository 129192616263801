<script>
import valuesService from '@/services/Values/valuesService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import {vueTopprogress} from 'vue-top-progress';

export default {
  data() {
    return {
      value: {},
    };
  },
  components: {
    vueTopprogress,
  },
  methods: {
    async addNewValue() {
      this.$refs.topProgress.start();

      try {
        await valuesService.createNewValue(this.value);

        this.$refs.topProgress.done();

        this.$router.push('values');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.value = {};
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewValue()">
        <h5 slot="header" class="title">
          <router-link to="/values" class="fa fa-arrow-left"></router-link>
          Adicionar Valores
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
          <base-input
              label="Valor*"
              placeholder="Valor"
              v-model="value.values"
              required
            ></base-input>
          </div>
          <div class="col-md-4 pr-md-1 mb-2">
          <base-input
              label="Ordem*"
              placeholder="Ordem"
              v-model="value.order"
              required
            ></base-input>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Adicionar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
