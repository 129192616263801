<script>
import dynamicFieldsService from '@/services/DynamicFields/dynamicFieldsService';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';

import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect);

export default {
  data() {
    return {
      dynamicField: {},
      cachedDynamicField: {},
      templates: [
        'equity', 'debt', 'Doação'
      ],

      cadastros: [
        'financing',
      ],

      tipoCampos: [
        'currency',
        'date',
        'string',
        'number',
      ],

      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },

      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE
    };
  },
  components: {
    vueTopprogress,
  },
  computed: {
    dynamicFieldId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    disabledField() {
      return this.dynamicField.reference == "Encerramento da Oferta" 
          || this.dynamicField.reference == "Quantidade de Cotas" 
          || this.dynamicField.reference == "Quantidade Inicial de Ações"
          || this.dynamicField.reference == "Receita Bruta Último Exercício"
          || this.dynamicField.reference == "Site"
          || this.dynamicField.reference == "Investimento Mínimo Permitido"
          || this.dynamicField.reference == "Valor da Ação"
          || this.dynamicField.reference == "Tipo Societário"
          || this.dynamicField.reference == "Valor"
          || this.dynamicField.reference == "Início da Oferta"
    }
  },
  mounted() {
    this.getDynamicField();

    if ( this.modalidade == 'equity' ) {
      if ( this.submodalidade == 'bacen' ) {
        this.templates = ['bacen'];
      }
      else {
        this.templates = ['equity', 'debt'];
      }
    }
    else {
      this.templates = ['Doação'];
    }
  },
  methods: {
    async getDynamicField() {
      this.$refs.topProgress.start();

      try {
        dynamicFieldsService.get(this.dynamicFieldId)
            .then(function(obj) {
              this.dynamicField = obj.data();
              this.cachedDynamicField = obj.data();

              this.$refs.topProgress.done();
            }.bind(this));
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async updateDynamicField() {
      this.$refs.topProgress.start();

      try {
        this.$refs.topProgress.done();

        const logUser = {
          user: {
              email: this.currentPessoa.email.email
          }
        };
        const logContent = this.dynamicField;
        logsService.createNewLog(logUser, '', 'Atualizar Campo Dinâmico', '', logContent);

        await dynamicFieldsService.update(this.dynamicFieldId, this.dynamicField);

        this.$router.push('/dynamic-fields');

        this.$notify({
          title: 'Campo dinâmico atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    checkAcceptTerms() {
       this.$refs.accept_term.click();
    },

    checkIsPublic() {
      this.$refs.is_public.click();
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateDynamicField()">
        <h5 slot="header" class="title">
          <router-link to="/dynamic-fields" class="fa fa-arrow-left"></router-link>
          Editar Campo Dinâmico
        </h5>

        <div class="row">
          <div class="col-md-4 pl-md-1">
            <base-input required label="Template*">
              <v-select
                label="name"
                class="style-chooser"
                :options="templates"
                v-model="dynamicField.template"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>

          <div class="col-md-4 pr-md-1 mb-2">
            <base-input required label="Cadastro*">
              <v-select
                label="name"
                class="style-chooser"
                :options="cadastros"
                v-model="dynamicField.crud"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input required label="Tipo do campo*">
              <v-select
                label="name"
                class="style-chooser"
                :options="tipoCampos"
                v-model="dynamicField.type"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
        </div>
        <div class="row">
            <div class="col-md-4 pl-md-1">
                <base-input
                label="Label*"
                placeholder="Informe o label do campo"
                v-model="dynamicField.label"
                required
                >
                </base-input>
            </div>

            <div class="col-md-4 pl-md-1">
                <base-input
                label="Placeholder*"
                placeholder="Informe o placeholder do campo"
                v-model="dynamicField.placeholder"
                required
                >
                </base-input>
            </div>

            <div class="col-md-4 pl-md-1">
                <base-input
                label="Referência*"
                placeholder="Informe a referência do campo"
                v-model="dynamicField.reference"
                required
                :disabled="disabledField"
                >
                </base-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 pl-md-1">
                <base-input
                label="Ordenação*"
                placeholder="Informe a ordem em que o campo deve aparecer"
                v-model="dynamicField.ordenacao"
                v-mask="'###'"
                required
                >
                </base-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 pl-md-1">
                <input name="accept_term" ref="accept_term" type="checkbox" v-model="dynamicField.required"/>
                <label for="accept_term" style="margin-left: 5px;" @click="checkAcceptTerms"> Campo obrigatório</label>
            </div>
            <div class="col-md-2 pl-md-1">
                <input name="is_public" ref="is_public" type="checkbox" v-model="dynamicField.is_public"/>
                <label for="is_public" style="margin-left: 5px;" @click="checkIsPublic"> Campo público</label>
            </div>
        </div>

        <button
          type="submit"
          class="btn btn-sm"
        >
          Editar
        </button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
