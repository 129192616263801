<script>
import FinancingTable from '@/components/Financing/FinancingTable';
import financingService from '@/services/Financing/financingService';

import EmptyFinancingNotification from '@/components/Notifications/Financing/EmptyFinancingNotification';
import DeleteFinancingErrorNotification from '@/components/Notifications/Financing/DeleteFinancingErrorNotification';
import DeleteFinancingSuccessNotification from '@/components/Notifications/Financing/DeleteFinancingSuccessNotification';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import moment from 'moment';

import { CWLStatusCampanha, PessoaCaracteristica } from '@/store/cwl_enums';

import logsService from '@/services/Logs/logsService';

const tableColumns = ['Criada em', 'Imagem', 'Nome', 'Status'];

export default {
  components: {
    FinancingTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: '',
        columns: [...tableColumns],
      },
      financing: [],
      isLoading: true,
      closeModal: false,
      subTitle: 'Aqui são mostradas as campanhas que você criou',
      modalidade: process.env.VUE_APP_TYPE,
    };
  },
  computed: {
    role() {
      return this.$store.getters.userClaim.role.toLowerCase();
    },
    isAdminOrGestorOrCurador() {
      return (
        this.role == PessoaCaracteristica.Master ||
        this.role == PessoaCaracteristica.Gestor ||
        this.role == PessoaCaracteristica.Curador ||
        this.role == 'master'
      );
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    tableTitle() {
      if (this.modalidade == 'doacao') {
        return this.isAdminOrGestorOrCurador ? 'Campanhas' : 'Minhas campanhas';
      } else {
        return this.isAdminOrGestorOrCurador ? 'Ofertas' : 'Minhas ofertas';
      }
    },
    tableSubTitle() {
      if (this.modalidade == 'doacao') {
        return this.isAdminOrGestorOrCurador
          ? ''
          : 'Aqui são mostradas as campanhas que você criou';
      } else {
        return this.isAdminOrGestorOrCurador
          ? ''
          : 'Aqui são mostradas as ofertas que você criou';
      }
    },
  },
  mounted() {
    this.getFinancing();
    this.table.title = this.tableTitle;
  },
  methods: {
    getFinancing() {
      this.financing = [];

      this.$refs.topProgress.start();

      const financingQuery = this.isAdminOrGestorOrCurador
        ? financingService.getAll()
        : financingService.getOnlyCreatedBy(this.currentPessoa);
      financingQuery.then(
        function (financing) {
          if (financing.empty) {
            if (this.modalidade == 'doacao') {
              this.$notify({
                title: 'Não há campanhas cadastradas',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
            } else {
              this.$notify({
                title: 'Não há ofertas cadastradas',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
            }
          }
          financing.forEach(
            function (oneFinancing) {
              const newFinancing = oneFinancing.data();
              newFinancing.id = oneFinancing.id;

              this.financing.push(newFinancing);
            }.bind(this),
          );

          this.isLoading = false;

          this.$refs.topProgress.done();
        }.bind(this),
      );
    },
    async enviarParaAnalise(preEnviarParaAnaliseItem) {
      this.$refs.topProgress.start();

      try {
        const financing = preEnviarParaAnaliseItem.item;
        financing.status = CWLStatusCampanha.AguardandoAnalise;

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = financing;
        logsService.createNewLog(
          logUser,
          '',
          'Enviar Para Análise',
          '',
          logContent,
        );

        await financingService.update(financing.id, financing);

        this.$refs.topProgress.done();
        this.$router.push('/resume-financing/' + financing.id);

        preEnviarParaAnaliseItem = {};
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
    async publicarCampanha(prePublicarCampanhaItem) {
      this.$refs.topProgress.start();

      try {
        const financing = prePublicarCampanhaItem.item;
        financing.status = CWLStatusCampanha.EmAndamento;
        financing.dates.started_at = moment().format('YYYY/MM/DD HH:mm:ss');

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = financing;
        logsService.createNewLog(
          logUser,
          '',
          'Publicar Campanha',
          '',
          logContent,
        );

        await financingService.update(financing.id, financing);

        this.$refs.topProgress.done();

        this.$notify({
          title: 'Publicada com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$router.push('/financing');

        prePublicarCampanhaItem = {};
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
    async updateCampaign(preDeletedItem) {
      this.$refs.topProgress.start();

      const { item } = preDeletedItem;

      try {
        await financingService.update(item.id, item);

        this.$notify({
          title: 'Status atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
    async executarEstornos(item) {
      this.$refs.topProgress.start();
      await this.$CWLestornarCampanhaSemSucesso(item.id);
      this.$refs.topProgress.done();
    },
    deleteFinancing(preDeletedItem) {
      try {
        financingService.delete(preDeletedItem.item.id);

        this.financing.splice(preDeletedItem.index, 1);

        this.$notify({
          component: DeleteFinancingSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :addItems="!isCurador"
          :itemType="modalidade == 'doacao' ? 'Campanha' : 'Oferta'"
          :routerType="'financing'"
          :title="table.title"
          :titleBold="true"
          :subTitle="tableSubTitle"
          :subtitleBold="true"
        >
          <div class="table-responsive">
            <financing-table
              :data="financing"
              :columns="table.columns"
              :hasActions="true"
              :canEdit="true"
              :canStop="false"
              :canEnviarParaAnalise="!isAdminOrGestorOrCurador"
              :canPublicarCampanha="
                isGestor ||
                (modalidade == 'doacao' && !isAdminOrGestorOrCurador)
              "
              thead-classes="text-primary"
              @deleteItem="deleteFinancing"
              @enviarParaAnalise="enviarParaAnalise"
              @publicarCampanha="publicarCampanha"
              @updateCampaign="updateCampaign"
              @executarEstornos="executarEstornos"
            ></financing-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
