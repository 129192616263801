<script>
import logsService from '@/services/Logs/logsService';
import LogsTable from '@/components/Logs/LogsTable';

const tableColumns = ['Usuário', 'Plataforma', 'Ação', 'Data', 'Dados'];

export default {
  components: {
    LogsTable,
  },
  data() {
    return {
      logs: [],
      table: {
        title: 'Lista de Logs',
        columns: [...tableColumns],
      },
    };
  },
  mounted() {
    this.getLogs();
  },
  methods: {
    async getLogs() {
      const logs = await logsService.getAllLogs();

      logs.forEach((log) => {
        this.logs.push(log.data());
      });
    },
  },
};
</script>

<template>
  <div class="logs-list">
    <div class="row">
      <div class="col-12">
        <card :title="table.title">
          <div class="table-responsive">
            <logs-table
              thead-classes="text-primary"
              :columns="table.columns"
              :data="logs"
            ></logs-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
