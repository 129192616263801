<script>
import {FadeTransition} from 'vue2-transitions';
import RegisterDisclaimer from '@/components/RegisterDisclaimer/RegisterDisclaimer';

export default {
  props: {
    layout: {
      type: String,
      default: '',
    },
  },
  components: {
    FadeTransition,
    RegisterDisclaimer,
  },
  data() {
    return {
    };
  },
  computed: {
    currentRoute() {
      if (this.$route.name != 'Meus Dados') {
        return true;
      }

      return false;
    },
    userRole() {
      const Claim = this.$store.getters.userClaim;

      return Claim.role;
    },
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    alertMessage() {
      return this.$store.getters.getInvestor.status.message;
    },
    alertClass() {
      return this.$store.getters.getInvestor.status.class;
    },
  },
};
</script>


<template>
  <div class="content">
    <register-disclaimer
      v-if="currentRoute
        && !layout"
    />

    <fade-transition :duration="100" mode="out-in">
      <!-- your content here -->
      <router-view></router-view>
    </fade-transition>
  </div>
</template>
