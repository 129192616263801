<script>
import segmentsService from '@/services/Segments/segmentsService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      segment: {},
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    vueTopprogress,
  },
  methods: {
    async addNewSegment() {
      this.$refs.topProgress.start();

      try {
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.segment;
        logsService.createNewLog(logUser, '', 'Adicionar Segmento', '', logContent);

        await segmentsService.createNewSegment(this.segment);

        this.$refs.topProgress.done();

        this.$router.push('segment-interest');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.segment = {};
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewSegment()">
        <h5 slot="header" class="title">
          <router-link to="/segment-interest" class="fa fa-arrow-left"></router-link>
          Adicionar Segmento
        </h5>

        <div class="row">
          <div class="col-md-4">
            <base-input
              label="Nome do Segmento*"
              placeholder="Nome do Segmento"
              v-model="segment.name"
              required
            >
            </base-input>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Adicionar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
