<script>
import ConfirmationModal from '@/components/ConfirmationModal';
import Vue from 'vue';
import moment from 'moment-timezone';

import { SlideYUpTransition } from 'vue2-transitions';
import HelperPlugin from '@/plugins/HelperPlugin';
import { CWLStatusCampanha, PessoaCaracteristica } from '@/store/cwl_enums';

import store from '@/store/index';

Vue.use(HelperPlugin);

export default {
  name: 'financing-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canStop: {
      type: Boolean,
      default: false,
    },
    canEnviarParaAnalise: {
      type: Boolean,
      default: false,
    },
    canPublicarCampanha: {
      type: Boolean,
      default: false,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
    SlideYUpTransition,
  },
  data() {
    return {
      CWLStatusCampanha: CWLStatusCampanha,
      PessoaCaracteristica: PessoaCaracteristica,
      page: 1,
      perPage: 10,
      pages: [],
      showModal: false,
      preDeletedItem: {},

      showEnviarParaAnaliseModal: false,
      preEnviarParaAnaliseObj: {
        item: {},
      },

      showPublicarModal: false,
      prePublicarCampanhaObj: {
        item: {},
      },

      searchBar: '',
      modalidade: process.env.VUE_APP_TYPE,
    };
  },
  computed: {
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isAdminOrGestorOrCurador() {
      return (
        this.role == PessoaCaracteristica.Master ||
        this.role == PessoaCaracteristica.Gestor ||
        this.role == PessoaCaracteristica.Curador
      );
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {
      return this.data.filter((obj) => {
        return (
          (obj.name ? obj.name : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.dates ? this.formatDate(obj.dates.created_at) : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase()) ||
          (obj.status ? this.statusDescription(obj.status) : '')
            .toLowerCase()
            .includes(this.searchBar.toLowerCase())
        );
      });
    },
  },
  mounted() {
    if (this.isCurador) {
      this.searchBar = this.statusDescription(
        CWLStatusCampanha.AguardandoAnalise,
      );
    }
    else {
      this.searchBar = this.statusDescription(
        CWLStatusCampanha.EmAndamento,
      );
    }
  },
  methods: {
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    nomeCriador(item) {
      if (item.is_empresa) {
        if (item.dados_complementares && item.dados_complementares.empresa) {
          return item.dados_complementares.empresa.nome;
        }
      } else if (item.users && item.users.created_by) {
        return item.users.created_by.nome;
      }
      return '';
    },
    formatDate(date) {
      // return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY')
      return this.$CWLFormatDate(date);
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.filteredData.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      var from = page * perPage - perPage;
      var to = page * perPage;

      if (this.searchBar.length > 0) {
        from = 0;
        to = 1024;
      }
      return data.slice(from, to);
    },
    preDeleteItem(item, index) {
      this.preDeletedItem = {
        item,
        index,
      };

      this.showModal = true;
    },
    preEnviarParaAnalise(item, index) {
      this.preEnviarParaAnaliseObj = {
        item,
        index,
      };

      this.showEnviarParaAnaliseModal = true;
    },
    enviarParaAnalise() {
      this.$emit('enviarParaAnalise', this.preEnviarParaAnaliseObj);

      this.showEnviarParaAnaliseModal = false;
    },
    prePublicarCampanha(item, index) {
      this.prePublicarCampanhaObj = {
        item,
        index,
      };

      this.showPublicarModal = true;
    },
    publicarCampanha() {
      this.$emit('publicarCampanha', this.prePublicarCampanhaObj);

      this.showPublicarModal = false;
    },
    deleteItem() {
      this.$emit('deleteItem', this.preDeletedItem);

      this.showModal = false;
    },
    downloadItem(user) {
      this.$emit('downloadItem', user);
    },
    closeModal() {
      this.showPublicarModal = false;
      this.showEnviarParaAnaliseModal = false;
    },
    updateCampaignStatus(item, index) {
      item.status = 'removida-do-ar';

      this.prePublicarCampanhaObj = {
        item,
        index,
      };

      this.$emit('updateCampaign', this.prePublicarCampanhaObj);
    },
    viewContributions(item, index) {
      this.$router.push(`/contributions/${item.id}`);
    },
    async executarEstornos(item, index) {
      this.$emit('executarEstornos', item);
    },
  },
  watch: {
    data() {
      this.setPages();
    },
    filteredData() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label
            class="control-label"
            v-tooltip="
              'Você pode pesquisar digitando no campo de busca abaixo. A pesquisa é realizada por qualquer informação que está na tela.'
            "
            style="cursor: default"
            >Pesquisar</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Para pesquisar, digite sua busca aqui..."
            v-model="searchBar"
          />
        </div>
      </div>
    </div>
    <table class="table table-hover" :class="tableClass">
      <thead class="thead-light" :class="theadClasses">
        <tr>
          <slot name="columns">
            <th></th>
            <th>Criada em</th>
            <th style="text-align: center">Imagem</th>
            <th>Nome</th>
            <th style="text-align: center" v-if="isAdminOrGestorOrCurador">
              Criador
            </th>
            <th style="text-align: center">Status</th>
            <th style="text-align: center">Ações</th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr
          v-for="(item, index) in displayedData"
          :key="index"
          style="cursor: default !important; height: 60px"
        >
          <td>
            <i
              v-if="!item.is_empresa"
              class="fa fa-user-o"
              aria-hidden="true"
              v-tooltip="'Esta campanha é para uma pessoa física.'"
            ></i>
            <!-- <i
              v-if="item.is_empresa"
              class="fa fa-heart-o"
              aria-hidden="true"
              v-tooltip="
                'Esta campanha é para uma entidade sem fins lucrativos.'
              "
            ></i> -->
          </td>
          <td width="12%">
            {{ formatDate(item.dates.created_at) }}
          </td>
          <td width="12%" style="text-align: center">
            <img :src="item.imageURL" style="height: 50px" />
          </td>
          <td>
            <span v-if="!canEdit">{{ item.name }}</span>
            <router-link
              v-if="canEdit && modalidade == 'doacao'"
              :to="`edit-financing/${item.id}`"
              title="Ver campanha"
            >
              {{ item.name }}
            </router-link>
            <router-link
              v-if="canEdit && modalidade == 'equity'"
              :to="`edit-financing/${item.id}`"
              title="Ver oferta"
            >
              {{ item.name }}
            </router-link>
          </td>
          <td
            width="15%"
            v-if="isAdminOrGestorOrCurador"
            style="text-align: center"
          >
            {{ nomeCriador(item) }}
          </td>
          <td width="15%" style="text-align: center">
            {{ statusDescription(item.status) }}
          </td>
          <td style="text-align: center" v-if="hasActions" width="20%">
            <a
              v-if="
                canEnviarParaAnalise &&
                (item.status == CWLStatusCampanha.EmCriacao ||
                  item.status == CWLStatusCampanha.Reprovada)
              "
              @click="preEnviarParaAnalise(item, index)"
              href="#"
              title="Enviar para análise"
              class="button-action"
            >
              <i class="tim-icons icon-send"></i>
            </a>
            <a
              v-if="
                canEnviarParaAnalise &&
                item.status != CWLStatusCampanha.EmCriacao &&
                item.status != CWLStatusCampanha.Reprovada
              "
              href="#"
              :title="'Campanha ' + statusDescription(item.status)"
              class="button-action"
              style="color: #ccc; cursor: default"
            >
              <i class="tim-icons icon-send"></i>
            </a>
            <a
              v-if="
                canPublicarCampanha &&
                (item.status == CWLStatusCampanha.Aprovada ||
                  item.status == CWLStatusCampanha.RemovidaDoAr)
              "
              @click="prePublicarCampanha(item, index)"
              href="#"
              title="Publicar campanha"
              class="button-action"
            >
              <i class="tim-icons icon-spaceship"></i>
            </a>
            <a
              v-if="
                canPublicarCampanha &&
                item.status != CWLStatusCampanha.Aprovada &&
                item.status != CWLStatusCampanha.RemovidaDoAr
              "
              href="#"
              :title="'Campanha ' + statusDescription(item.status)"
              class="button-action"
              style="color: #ccc; cursor: default"
            >
              <i class="tim-icons icon-spaceship"></i>
            </a>
            <router-link
              v-if="canEdit && modalidade == 'doacao'"
              :to="`edit-financing/${item.id}`"
              title="Ver campanha"
              class="button-action"
            >
              <i class="tim-icons icon-paper"></i>
            </router-link>
            <router-link
              v-if="canEdit && modalidade == 'equity'"
              :to="`edit-financing/${item.id}`"
              title="Ver oferta"
              class="button-action"
            >
              <i class="tim-icons icon-paper"></i>
            </router-link>
            <a
              v-if="isGestor && item.status != CWLStatusCampanha.RemovidaDoAr"
              title="Remover do Ar"
              class="button-action"
              @click="updateCampaignStatus(item, index)"
            >
              <img src="@/assets/images/cloud-off.svg" alt="Cloud Off" />
            </a>
            <a
              v-if="isGestor && item.status == CWLStatusCampanha.RemovidaDoAr"
              title=""
              class="button-action"
              @click="updateCampaignStatus(item, index)"
            >
              <img
                v-if="isGestor"
                src="@/assets/images/cloud-off-disabled.svg"
                alt="Cloud Off"
              />
            </a>
            <a
              v-if="canStop"
              @click="preDeleteItem(item, index)"
              href="#"
              style="color: red"
              title="Encerrar campanha"
              class="button-action"
            >
              <i class="tim-icons icon-button-power"></i>
            </a>
            <a
              @click="viewContributions(item, index)"
              href="#"
              title="Ver Aportes"
              class="button-action"
            >
              <i class="tim-icons icon-money-coins"></i>
            </a>
            <a
              v-if="false"
              @click="executarEstornos(item, index)"
              href="#"
              title="Executar Estornos"
              class="button-action"
            >
              <i class="tim-icons icon-refresh-01"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="table-pagination" v-if="!searchBar.length">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <confirmation-modal
      :show="showModal"
      @close="showModal = false"
      @confirmDelete="deleteItem()"
    ></confirmation-modal>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showEnviarParaAnaliseModal },
            { 'd-none': !showEnviarParaAnaliseModal },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showEnviarParaAnaliseModal"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showEnviarParaAnaliseModal"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-5x"></i>

              <h1
                class="modal-content-title"
                v-if="preEnviarParaAnaliseObj.item.campanha_pronta_para_analise"
              >
                Cuidado
              </h1>

              <h1
                class="modal-content-title"
                v-if="
                  !preEnviarParaAnaliseObj.item.campanha_pronta_para_analise
                "
              >
                Atenção
              </h1>

              <p
                class="modal-content-description"
                v-if="
                  preEnviarParaAnaliseObj.item.campanha_pronta_para_analise &&
                  modalidade == 'doacao'
                "
              >
                Se enviar esta campanha para análise, você não poderá mais
                editá-la.
                <span>Você tem certeza?</span>
              </p>

              <p
                class="modal-content-description"
                v-if="
                  preEnviarParaAnaliseObj.item.campanha_pronta_para_analise &&
                  modalidade == 'equity'
                "
              >
                Se enviar esta oferta para análise, você não poderá mais
                editá-la.
                <span>Você tem certeza?</span>
              </p>

              <span
                class="modal-content-description"
                v-if="
                  !preEnviarParaAnaliseObj.item.campanha_pronta_para_analise &&
                  modalidade == 'doacao'
                "
                style="text-align: left !important"
              >
                Esta campanha ainda não está pronta para ser enviada para
                análise.
                <br />
                <br />
                Os possíveis motivos são:
                <br />
                <br />
                <span
                  ><i class="fa fa-info-circle" aria-hidden="true"></i> Leitura
                  e aceite das <b>Condições, Termos de Uso</b> e
                  <b>Taxas e Prazos</b></span
                >
                <!-- <br /> -->
                <span
                  ><i class="fa fa-info-circle" aria-hidden="true"></i>
                  Preenchimento das informações obrigatórias da campanha</span
                >
              </span>

              <span
                class="modal-content-description"
                v-if="
                  !preEnviarParaAnaliseObj.item.campanha_pronta_para_analise &&
                  modalidade == 'equity'
                "
                style="text-align: left !important"
              >
                Esta oferta ainda não está pronta para ser enviada para análise.
                <br />
                <br />
                Os possíveis motivos são:
                <br />
                <br />
                <span
                  ><i class="fa fa-info-circle" aria-hidden="true"></i> Leitura
                  e aceite das <b>Condições, Termos de Uso</b> e
                  <b>Taxas e Prazos</b></span
                >
                <!-- <br /> -->
                <span
                  ><i class="fa fa-info-circle" aria-hidden="true"></i>
                  Preenchimento das informações obrigatórias da oferta</span
                >
              </span>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  v-if="
                    preEnviarParaAnaliseObj.item.campanha_pronta_para_analise
                  "
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="enviarParaAnalise()"
                >
                  Sim, quero enviar para análise
                </button>

                <router-link
                  v-if="
                    !preEnviarParaAnaliseObj.item.campanha_pronta_para_analise
                  "
                  :to="`edit-financing/${preEnviarParaAnaliseObj.item.id}`"
                  title="Resolver"
                  class="button-action"
                  style="
                    background-color: #288cf7;
                    color: #fff;
                    padding: 8px;
                    border-radius: 5px;
                  "
                >
                  Resolver
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showPublicarModal },
            { 'd-none': !showPublicarModal },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showPublicarModal"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showPublicarModal"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-5x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p
                class="modal-content-description"
                v-if="modalidade == 'doacao'"
              >
                Se publicar esta campanha, ela ficará visível para todos e você
                não poderá mais edita-la.
                <span>Você tem certeza?</span>
              </p>

              <p
                class="modal-content-description"
                v-if="modalidade == 'equity'"
              >
                Se publicar esta oferta, ela ficará visível para todos e você
                não poderá mais edita-la.
                <span>Você tem certeza?</span>
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="publicarCampanha()"
                >
                  Sim, quero publicar
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>
  </div>
</template>

<style scoped>
.button-action {
  margin-left: 10px;
  margin-right: 10px;
}

.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
