import { withLog } from '../../logger/with_log';
import firebase from 'firebase';

import * as municipios from '@/assets/data/municipios.json';

export default {
  stateRef() {
    return firebase.firestore().collection('states');
  },
  async getAllStates() {
    try {
      return municipios.estados.map((e) => ({
        name: e.nome,
        uf: e.sigla,
      }));
    } catch (error) {
      console.error('get all states error', error);
    }
  },

  async getCitiesByState(state) {
    const value = municipios.estados.find(
      (e) => e.sigla === state || e.sigla === state.uf,
    );
    return value ? value.cidades : [];
  },

  createNewState(state) {
    withLog(() => this.stateRef().add(state));
  },

  deleteStates(state) {
    withLog(() => this.stateRef().doc(state).delete());
  },

  getState(id) {
    return withLog(() => this.stateRef().doc(id).get());
  },

  updateState(id, state) {
    return withLog(() => this.stateRef().doc(id).update(state));
  },
};
