<script>
import {SlideYUpTransition} from 'vue2-transitions';
import AttachmentTable from '@/components/Attachment/AttachmentTable';
import EmptyTermsOfUse from '@/components/Notifications/TermsOfUse/EmptyTermsOfUse';
import GetTermsOfUseError from '@/components/Notifications/TermsOfUse/GetTermsOfUseError';
import TermsOfUseModal from '@/components/TermsOfUseModal';

import attachmentsService from '@/services/Attachments/attachmentsService';
import investorsService from '@/services/Investors/investorsService';

import {vueTopprogress} from 'vue-top-progress';

import store from '@/store/index';

import pdfvuer from 'pdfvuer';

export default {
  name: 'terms-of-use-modal',
  components: {
    SlideYUpTransition,
    pdf: pdfvuer,
    vueTopprogress,
  },
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes',
    },
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration',
    },
    itemType: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.getAttachments();
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
    confirmDelete() {
      this.$emit('confirmDelete', this.itemType);
    },
    async accept() {
      this.$refs.topProgress.start();
      await investorsService
          .getInvestor(store.getters.isLoggedIn.email)
          .then((response) => {
            response.forEach((doc) => {
              const investor = doc.data();
              investor.dados_complementares.termsOfUseAccepted = true;
              store.commit('SET_TERM_OF_USE_ACCEPTED', true);
              investorsService.updateInvestor(doc.id, investor);

              this.$refs.topProgress.done();
            });
          });
      this.$router.push('dashboard');
    },
    getPdf(url) {
      const self = this;

      self.pdfdata = pdfvuer.createLoadingTask(url);
      self.pdfdata.then((pdf) => {
        self.numPages = pdf.numPages;
        window.onscroll = function() {
          changePage();
        };

        function changePage() {
          let i = 1;
          const count = Number(pdf.numPages);

          do {
            if (
              window.pageYOffset >= self.findPos(document.getElementById(i)) &&
              window.pageYOffset <= self.findPos(document.getElementById(i + 1))
            ) {
              self.page = i;
            }
            i++;
          } while (i < count);

          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i;
          }
        }
      });
    },
    async getAttachments() {
      this.$refs.topProgress.start();

      try {
        const attachments = await attachmentsService.getAttachments(
            'TermsOfUse',
        );
        const attachmentsItems = await attachments.items;
        if (attachmentsItems.length == 0) {
          this.$notify({
            component: EmptyTermsOfUse,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        } else {
          this.lastAttachment = attachmentsItems[0];
          const url = await attachmentsService.urlAttachment(
              this.lastAttachment.name,
              'TermsOfUse',
          );
          this.getPdf(url);
        }
      } catch (e) {
        this.$notify({
          component: GetTermsOfUseError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    findPos(obj) {
      return obj.offsetTop;
    },
  },
  data() {
    return {
      pdfUrl:
        'https://firebasestorage.googleapis.com/v0/b/crowdfunding-wl-dev.appspot.com/o/TermsOfUse%2FTermo%20de%20Uso%2024_04_2020?alt=media&token=f65b5d81-a601-48d1-a133-5f59b809931d',
      page: 1,
      numPages: 0,
      scale: 'page-width',
    };
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
      } else {
        documentClasses.remove('modal-open');
      }
    },
  },
};
</script>

<template>
  <SlideYUpTransition :duration="animationDuration">
    <div
      class="modal fade"
      @click.self="closeModal"
      :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
      v-show="show"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!show"
    >
      <div class="modal-dialog">
        <div class="modal-content confirmation-modal">
          <form @submit.prevent="accept" class="login-form">
            <pdf
              :src="pdfdata"
              v-for="i in numPages"
              :key="i"
              :id="i"
              :page="i"
              :scale.sync="scale"
              style="width: 100%;"
            >
              <template slot="loading">loading content here...</template>
            </pdf>

            <button type="submit" class="btn btn-danger">Sair</button>
            <button type="submit" class="btn btn-primary">Aceitar</button>
          </form>
        </div>
      </div>

      <vue-topprogress ref="topProgress"></vue-topprogress>
    </div>
  </SlideYUpTransition>
</template>

<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
