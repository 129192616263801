/**
 * @param empresa user empresa data
 */
export default ({ dados_bancarios }) => {
  let bankAccount = dados_bancarios || {};

  const agencyNumber = bankAccount.agencia
    ? bankAccount.agencia.split('-')
    : undefined;

  const agency = {
    number: agencyNumber ? agencyNumber[0] : '',
    digit: agencyNumber && agencyNumber.length == 2 ? agencyNumber[1] : '',
  };

  const accountNumber = bankAccount.conta
    ? bankAccount.conta.split('-')
    : undefined;

  const account = {
    number: accountNumber ? accountNumber[0] : '',
    digit: accountNumber && accountNumber.length == 2 ? accountNumber[1] : '',
  };

  bankAccount = {
    bank: {
      name: bankAccount.banco,
    },
    agency,
    account,
  };

  return bankAccount;
};
