<template>
  <div class="card" :class="[type && `card-${type}`]">
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div class="card-header" v-if="$slots.header || title" :class="headerClasses">
      <slot name="header">
        <h4 v-if="!titleBold" class="card-title">{{title}}</h4>
        <h4 v-if="titleBold" class="card-title"><b>{{title}}</b></h4>
        <p class="card-category" v-if="subTitle && !subtitleBold">{{subTitle}}</p>
        <p class="card-category" v-if="subTitle && subtitleBold"><b>{{subTitle}}</b></p>

        <router-link
          :to="`/add-${routerType}`"
          class="btn btn-primary btn-sm"
          v-if="addItems"
        >
          Criar {{ itemType }}
        </router-link>
      </slot>
    </div>
    <div class="card-body" v-if="$slots.default">
      <slot></slot>
    </div>
    <div class="card-image" v-if="$slots['image-bottom']">
      <slot name="image-bottom"></slot>
    </div>
    <slot name="raw-content"></slot>
    <div class="card-footer" :class="footerClasses" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'card',
  props: {
    title: {
      type: String,
      description: 'Card title',
    },
    subTitle: {
      type: String,
      description: 'Card subtitle',
    },
    type: {
      type: String,
      description: 'Card type (e.g primary/danger etc)',
    },
    headerClasses: {
      type: [String, Object, Array],
      description: 'Card header css classes',
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: 'Card body css classes',
    },
    footerClasses: {
      type: [String, Object, Array],
      description: 'Card footer css classes',
    },
    addItems: {
      type: Boolean,
      default: () => false,
    },
    titleBold: {
      type: Boolean,
      default: false
    },
    subtitleBold: {
      type: Boolean,
      default: false
    },
    itemType: {
      type: String,
      default: () => '',
    },
    routerType: {
      type: String,
      default: () => '',
    },
  },
};
</script>
<style>
</style>
