import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  valuesRef() {
    return firebase.firestore().collection('values');
  },

  getAllValues() {
    return withLog(() => this.valuesRef().orderBy('order', 'asc').get());
  },

  createNewValue(value) {
    withLog(() => this.valuesRef().add(value));
  },

  deleteValue(value) {
    withLog(() => this.valuesRef().doc(value).delete());
  },

  getValue(id) {
    return withLog(() => this.valuesRef().doc(id).get());
  },

  updateValue(id, value) {
    return withLog(() => this.valuesRef().doc(id).update(value));
  },
};
