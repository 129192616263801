<script>
import bancarizadoresService from '@/services/Bancarizadores/bancarizadoresService';
import logsService from '@/services/Logs/logsService';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';

import ConfirmationModal from '@/components/ConfirmationModal';

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
    ConfirmationModal
  },
  data() {
    return {
      data: [],
      isLoading: true,
      
      // Paginação
      page: 1,
      perPage: 10,
      pages: [],

      // Filtros
      searchBar: '',

      // Remover
      showModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {
      return this.data.filter(obj => {
        return(
             (obj.name ? obj.name : '').toLowerCase().includes(this.searchBar.toLowerCase())
        )
      });
    },
  },
  mounted() {
    this.getBancarizadores();
  },
  methods: {
    async getBancarizadores() {
      this.$refs.topProgress.start();
      this.data = (await bancarizadoresService.getAll().catch((e) => [])) || [];
      
      if (this.data.length == 0) {
        this.$notify({
          title: 'Não encontramos bancarizadores cadastrados em nossa base',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },

    async deleteItem() {
      try {
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Remover Bancarizador', '', logContent);
        bancarizadoresService.logicalDelete(this.preDeletedItem.item);
        
        this.data.splice(this.preDeletedItem.index, 1);

        this.$notify({
          title: 'Bancarizador removido com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.preDeletedItem = {};
      this.showModal = false;
    },
    preDeleteItem(item, index) {
      this.preDeletedItem = {
        item,
        index,
      };

      this.showModal = true;
    },

    // Paginação
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card :title="'Bancarizadores'" :titleBold="true" :addItems="true" :itemType="'Bancarizador'" :routerType="'bancarizador'">
          
          <!-- Filtros e Busca -->
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Para pesquisar, digite sua busca aqui..." v-model="searchBar" />
              </div>
            </div>
          </div>

          <!-- Listagem dos dados -->
          <div class="row">
            <div class="col-12">
              <table class="table table-hover table-responsive">
                <thead class="thead-light">
                  <tr>
                    <th width="30%">Nome</th>
                    <th width="10%">Tipo</th>
                    <th width="5%" style="text-align: center;">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in displayedData" :key="index" style="cursor: default !important; height: 60px;">
                    <td>
                      <router-link :to="`edit-bancarizador/${item.id}`" title="Ver bancarizador">
                        {{ item.name }}
                      </router-link>
                    </td>
                    <td>
                      {{  item.tipo  }}
                    </td>
                    <td style="text-align: center;">
                      <router-link :to="`edit-bancarizador/${item.id}`" title="Ver bancarizador" class="button-action">
                        <i class="tim-icons icon-paper"></i>
                      </router-link>
                      <a @click="preDeleteItem(item, index)" href="#" style="color: red" title="Excluir bancarizador" class="button-action">
                        <i class="tim-icons icon-trash-simple"></i>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="displayedData.length == 0">
                    <td colspan="3" style="text-align: center; font-style: italic;">
                      Não foram encontrados registros.
                    </td>
                  </tr>
                </tbody>
              </table>

              <nav class="table-pagination">
                <ul class="pagination">
                  <li class="page-item">
                    <button
                      type="button"
                      class="page-link"
                      v-if="page != 1"
                      @click="page--"
                    >
                      <i class="fas fa-angle-left"></i>
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      type="button"
                      class="page-link"
                      v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{ 'is-active': page === pageNumber }"
                    >
                      {{ pageNumber }}
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      type="button"
                      class="page-link"
                      v-if="page < pages.length"
                      @click="page++"
                    >
                      <i class="fas fa-angle-right"></i>
                    </button>
                  </li>
                </ul>
              </nav>

              <confirmation-modal
                :show="showModal"
                @close="showModal = false"
                @confirmDelete="deleteItem()"
              ></confirmation-modal>
            </div>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
