import { collectionRef } from '../../firebase';
import { withLog } from '../../logger/with_log';

export default {
  _ref() {
    return collectionRef('logs');
  },
  createNewLog(
    request,
    class_name,
    class_method_name,
    request_method,
    content,
  ) {
    if (request.user && request.user.email) {
      withLog(() =>
        this._ref().add({
          user: request.user.email,
          class_name,
          class_method_name,
          request_method,
          platform: 'portal',
          content,
          date: new Date(),
        }),
      );
    }
  },

  getAllLogs() {
    return withLog(() => this._ref().orderBy('date', 'desc').get());
  },
};
