<script>
import { DoubleBounce } from 'vue-loading-spinner';
import Modal from '@/components/Modal';

import settingsService from '@/services/Settings/settingsService';

export default {
  name: 'company-partner-confimation-form',
  props: {
    error: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Sociedade da empresa',
    },
    onBack: {
      type: Function,
      required: false,
    },
    initialValue: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      showTerms: false,
      terms: '',
      value: {
        isPartner: false,
        ...this.initialValue,
      },
    };
  },
  computed: {},
  components: {
    DoubleBounce,
    Modal,
  },
  methods: {
    handleChange() {
      this.$emit('change', this.value);
    },
    openTerms() {
      this.showTerms = true;
    },
    checkAcceptTerms() {
      this.$refs.accept_term.click();
    },
  },
  async mounted() {
    try {
      this.settings = await settingsService.getPublicSettings();
      const terms = this.settings.terms ? this.settings.terms.agent_term : '';
      this.terms = terms || '';
    } catch (error) {
      console.error('mount company partner form error', error);
    }
    this.loading = false;
  },
};
</script>

<template>
  <div>
    <card>
      <double-bounce v-if="loading"></double-bounce>
      <form @submit.prevent="handleChange()" v-if="!loading" class="login-form">
        <h6 slot="header" class="title">
          <a v-if="onBack" v-on:click="onBack()" class="fa fa-arrow-left"></a>
          {{ title }}
        </h6>
        <div style="color: red" v-if="error">{{ error }}</div>
        <div class="row">
          <div class="col-md-12 mb-1">
            <base-checkbox
              v-model="value.isPartner"
              :disabled="readonly"
              v-on:input="handleChange"
            >
              Confirmo que sou sócio/proprietário desta empresa e estou de
              acordo com o
              <span style="color: blue" @click="openTerms"
                >Termos de Parceria de Serviço</span
              >
            </base-checkbox>
          </div>
        </div>
      </form>
    </card>

    <modal :show="showTerms" @close="showTerms = false">
      <div class="term-of-use">
        <div>
          <b>Termos de Parceria de Serviço</b> para
          {{ $t('social_transformation.title') }}.
        </div>
        <div>Leia com atenção e qualquer dúvida, entre em contato.</div>
        <button
          style="
            background-color: rgb(255, 255, 255);
            border: 1px solid #aaa;
            border-radius: 20px;
            height: 40px;
            width: 40px;
            position: absolute;
            right: 0;
            top: 10px;
            cursor: pointer;
            color: #888;
          "
          @click="showTerms = false"
        >
          <i class="tim-icons icon-simple-remove"></i>
        </button>
        <br />
        <br />
        <div
          class="card"
          style="
            background-color: #fff !important;
            max-width: 1024px !important;
          "
        >
          <div class="login-form" style="max-width: 1024px">
            <div class="row">
              <div class="col-md-12 mb-1">
                <div v-html="terms"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
