import bankMapper from '@/services/Banks/mapper/bankMapper';
import companyIdentificationMapper from '@/services/Company/mapper/companyIdentificationMapper';

const BANK_ACCOUNT_INIT = {
  bank: {
    name: '',
  },
  agency: {
    number: '',
    digit: '',
  },
  account: {
    number: '',
    digit: '',
  },
};

const COMPANY_INIT = {
  identification: {},
  address: {},
  partner: {
    isPartner: false,
  },
  bankAccount: BANK_ACCOUNT_INIT,
};

/**
 * @param empresa user empresa data
 */
export default (empresa) =>
  empresa
    ? {
        id: empresa.id,
        identification: companyIdentificationMapper(empresa),
        address: empresa.endereco,
        bankAccount: bankMapper({ dados_bancarios: empresa.dados_bancarios }),
      }
    : COMPANY_INIT;
