/* eslint-disable require-jsdoc */
import firebase from 'firebase';

export default {
  collection() {
    return firebase.firestore().collection('settings');
  },

  async getMarketplaceToken() {
    const privateSettings = await this.getPrivateSettings();
    const settings = privateSettings.integracao.safe2pay.safe2pay_api_keys || {};
    const sandbox = settings.marketplace_token_sandbox_active === true;
    return sandbox
      ? settings.marketplace_token_sandbox
      : settings.marketplace_token_prd;
  },

  async getPublicSettings() {
    try {
      const snapshot = await this.collection().doc('public').get();
      return snapshot.data() || {};
    } catch (e) {
      console.error('get settings error', e);
      return {};
    }
  },

  async getPrivateSettings() {
    try {
      const snapshot = await this.collection().doc('private').get();
      return snapshot.data() || {};
    } catch (e) {
      console.error('get settings error', e);
      return {};
    }
  },

  async getFeaturesToggle() {
    try {
      const snapshot = await this.collection().doc('private').get();
      const docData = snapshot.data();
      if ( docData && docData._features ) {
        return docData._features;
      }
      else {
        return {};
      }
    } catch (e) {
      console.error('get settings error', e);
      return {};
    }
  },

  async getSettings() {
    const publicSettings = await this.getPublicSettings();
    const privateSettings = await this.getPrivateSettings();

    return {
      ...publicSettings,
      ...privateSettings,
    };
  },

  async updatePublicSettings(settings) {
    await this.collection().doc('public').update(settings);
  },

  async updatePrivateSettings(settings) {
    await this.collection().doc('private').update(settings);
  },

  async getDashboardLimits() {
    const settings = await this.getPublicSettings();
    return settings.dashboardLimits || {};
  },

  async socialTransformationEnabled() {
    const settings = await this.getPublicSettings();
    return (
      settings.socialTransformation &&
      settings.socialTransformation.enabled === true
    );
  },

  async getLocaleIdentifier() {
    const settings = await this.getPrivateSettings();
    if ( settings._locale_identifier ) {
      return settings._locale_identifier;
    }
    return "default";
  },
};
