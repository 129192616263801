import firebase from 'firebase';
import { withLog } from '../../logger/with_log';
import settingsService from '@/services/Settings/settingsService';
import axios from 'axios';

export default {
  forumRef() {
    return firebase.firestore().collection('forum');
  },

  async findAll() {
    try {
      const snapshot = await this.forumRef().get();
      if (snapshot.empty) {
        return [];
      }

      return snapshot.docs.map((value) => ({
        id: value.id,
        ...value.data(),
      }));
    } catch (error) {
      console.error('forum fnd all error', error);
    }
  },
  
  getAllOrderByCreate(financing_id) {
    return withLog(() =>
      this.forumRef()
        .where('financing_id', '==', financing_id)
        .orderBy("created_at", "desc").get(),
    );
  },

  getAll() {
    return withLog(() => this.forumRef().get());
  },

  create(forum) {
    try {
      const doc = this.forumRef().doc();
      const value = {
        ...forum,
        id: doc.id,
      };
      doc.set(value);
      return value;
    } catch (error) {
      console.error('forum service create error', error);
    }
  },

  delete(forum) {
    withLog(() => this.forumRef().doc(forum).delete());
  },

  async findById(id) {
    try {
      return (await this.forumRef().doc(id).get()).data();
    } catch (error) {
      console.error('forum servic find by id error', error);
      throw Error('Não foi possível consultar os dados da campanha.');
    }
  },

  get(id) {
    return withLog(() => this.forumRef().doc(id).get());
  },

  update(id, forum) {
    return withLog(() => this.forumRef().doc(id).update(forum));
  },
};
