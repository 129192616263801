import Axios from 'axios';

export default async financingId => {
  const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'getFinancingSummary';
  try {
    return await Axios.post(endpoint, { financingId }).then(r => r.data);
  } catch (error) {
    throw new Error(error.message);
  }
};
