<script>
import BanksTable from '@/components/Banks/BanksTable';
import banksService from '@/services/Banks/banksService';

import EmptyBanksNotification from '@/components/Notifications/Banks/EmptyBanksNotification';
import DeleteBankErrorNotification from '@/components/Notifications/Banks/DeleteBankErrorNotification';
import DeleteBankSuccessNotification from '@/components/Notifications/Banks/DeleteBankSuccessNotification';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';
import logsService from '@/services/Logs/logsService';

const tableColumns = ['Código', 'Nome'];

export default {
  components: {
    BanksTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Bancos',
        columns: [...tableColumns],
      },
      banks: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getBanks();
  },
  methods: {
    async getBanks() {
      this.$refs.topProgress.start();
      this.banks = (await banksService.getAllBanks().catch((e) => [])) || [];
      if (this.banks.lenght === 0) {
        this.$notify({
          component: EmptyBanksNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    deleteBank(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Excluir Banco', '', logContent);

        banksService.deleteBank(preDeletedItem.item.id);

        this.getBanks();

        this.$notify({
          component: DeleteBankSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          component: DeleteBankErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Banco'"
          :routerType="'bank'"
        >
          <div class="table-responsive">
            <banks-table
              :data="banks"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteBank"
            ></banks-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
