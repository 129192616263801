/**
 * @param empresa user empresa data
 */
export default (empresa = {}) => ({
  identificationNumber: empresa.documento
    ? empresa.documento.numero
    : undefined,
  name: empresa.nome,
  displayName:
    empresa.dados_complementares && empresa.dados_complementares.nome_fantasia
      ? empresa.dados_complementares.nome_fantasia
      : '',
});
