<script>
export default {
  name: 'investor-confirmation',
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pessoa: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    investor() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    saveProfileInformations() {
      this.$emit('saveProfileInformations');
    },
    approveInvestor() {
      this.$emit('approveInvestor');
    },
    refuseInvestor() {
      this.$emit('refuseInvestor');
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent>
        <h5 slot="header" class="title">
          <router-link to="/investors" class="fa fa-arrow-left"></router-link>
          Análise de Dados do Investidor
        </h5>
        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              readonly
              required
              label="CPF*"
              v-model="pessoa.dados_complementares.user.cpf"
              placeholder="CPF"
            ></base-input>
          </div>
          <div class="col-md-4 px-md-1">
            <base-input
              required
              v-model="pessoa.dados_complementares.user.rg"
              readonly
              label="RG"
              placeholder="RG"
            ></base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input
              required
              v-model="pessoa.dados_complementares.user.birthdate"
              label="Data de Nascimento*"
              type="text"
              readonly
              placeholder="dd/mm/yyyy"
            ></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              required
              v-model="pessoa.dados_complementares.user.phone"
              label="Celular com DDD*"
              readonly
              placeholder="Celular com DDD"
            > </base-input>
          </div>
          <div class="col-md-4 px-md-1">
            <base-input label="Estado Civil*">
              <select class="form-control" required v-model="pessoa.dados_complementares.user.maritalStatus" disabled>
                <option value="">Selecionar</option>
                <option value="single">Solteiro(a)</option>
                <option value="married">Casado(a)</option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input label="Genêro*" v-model="pessoa.dados_complementares.user.gender">
              <select class="form-control" required disabled>
                <option value="male">Masculino</option>
                <option value="female">Feminino</option>
              </select>
            </base-input>
          </div>
        </div>

        <h5 class="title mt-3">Endereço</h5>

        <div class="row">
          <div class="col-md-12 pr-md-1">
            <base-input
              required
              label="CEP*"
              v-mask="'#####-###'"
              placeholder="CEP"
              disabled
              v-model="pessoa.dados_complementares.address.cep"
            ></base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              disabled
              required
              label="Cidade*"
              v-model="pessoa.dados_complementares.address.city"
              placeholder="Cidade"
            ></base-input>
          </div>
          <div class="col-md-4 px-md-1">
            <base-input
              required
              disabled
              label="Bairro*"
              v-model="pessoa.dados_complementares.address.neighborhood"
              placeholder="Bairro"
            ></base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input label="Estado*" required>
              <select class="form-control" disabled>
                <option value="" disabled selected>{{ pessoa.dados_complementares.address.state.name }}</option>
              </select>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 px-md-1">
            <base-input
              required
              disabled
              label="Rua*"
              v-model="pessoa.dados_complementares.address.street"
              placeholder="Rua">
            </base-input>
          </div>
          <div class="col-md-4 px-md-1">
            <base-input
              required
              label="Número*"
              placeholder="Número"
              disabled
              v-model="pessoa.dados_complementares.address.number"
            ></base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input
              label="Complemento"
              placeholder="Complemento"
              disabled
              v-model="pessoa.dados_complementares.address.complement"
            ></base-input>
          </div>
        </div>

        <h5 class="title mt-3">Profissão</h5>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              required
              label="Ocupação*"
              placeholder="Ocupação"
              disabled
              v-model="pessoa.dados_complementares.occupation"
            ></base-input>
          </div>
          <div class="col-md-4 px-md-1">
            <base-input
              required
              label="Nome da Empresa*"
              v-model="pessoa.dados_complementares.businessName"
              disabled
              placeholder="Nome da Empresa"
            ></base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input
              required
              label="Cargo*"
              v-model="pessoa.dados_complementares.office"
              disabled
              placeholder="Cargo"
            ></base-input>
          </div>
        </div>

        <h5 class="title mt-3">Dados Bancários</h5>

        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input
              required
              label="Agência sem dígito*"
              disabled
              v-model="pessoa.dados_complementares.bank.agency"
              placeholder="Agência sem dígito"
            ></base-input>
          </div>
          <div class="col-md-4 px-md-1">
            <base-input
              required
              label="Conta com dígito*"
              v-model="pessoa.dados_complementares.bank.account"
              disabled
              placeholder="Conta com dígito"
            ></base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input label="Banco*" required>
              <select class="form-control" disabled>
                <option selected value="">{{ pessoa.dados_complementares.bank.bankName }}</option>
              </select>
            </base-input>
          </div>
        </div>

        <h5 class="title mt-3">Perfil</h5>

        <div class="row">
          <div class="col-md-12">
            <base-input required label="Qual situação melhor se encaixa ao seu perfil atual em relação a investimentos? (opcional)*">
              <select class="form-control" disabled>
                <option value="" selected>{{ pessoa.dados_complementares.perfil.perfil }}</option>
              </select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <base-input label="Qual o principal segmento você tem interesse em investir? (opcional)*" required>
              <select class="form-control" disabled>
                <option value="" selected>{{ pessoa.dados_complementares.perfil.segmento }}</option>
              </select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <base-input label="Quanto do seu patrimônio você está disposto a investir? (opcional)*" required>
              <select class="form-control" disabled>
                <option value="" selected>{{ pessoa.dados_complementares.perfil.value }}</option>
              </select>
            </base-input>
          </div>
        </div>

        <div class="confirmation-buttons mt-3">
          <button
            @click="refuseInvestor()"
            type="submit"
            slot="footer"
            class="btn btn-sm btn-primary btn-simple mr-2"
            fill
            :disabled="pessoa.dados_complementares.approvalStatus === 'Recusado'"
          >
            Recusar
          </button>

          <button
            @click="approveInvestor()"
            type="submit"
            slot="footer"
            class="btn btn-sm btn-primary"
            fill
          >
            Aprovar
          </button>
        </div>
      </form>
    </card>
  </div>
</template>

