<script>
import ProfilesTable from '@/components/Profile/ProfilesTable';
import investmentsProfileService from '@/services/InvestmentsProfile/investmentsProfileService';

import EmptyInvestmentsNotification from '@/components/Notifications/Investments/EmptyInvestmentsNotification';
import DeleteInvestmentsErrorNotification from '@/components/Notifications/Investments/DeleteInvestmentsErrorNotification';
import DeleteInvestmentsSuccessNotification from '@/components/Notifications/Investments/DeleteInvestmentsSuccessNotification';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

import logsService from '@/services/Logs/logsService';

const tableColumns = ['Tipo de Perfil'];

export default {
  components: {
    ProfilesTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Perfil de Investimentos',
        columns: [...tableColumns],
      },
      investments: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getInvestments();
  },
  methods: {
    getInvestments() {
      this.investments = [];
      this.$refs.topProgress.start();

      investmentsProfileService.getAllInvestments()
          .then(function(investments) {
            if (investments.empty) {
              this.$notify({
                component: EmptyInvestmentsNotification,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
            }

            investments.forEach(function(investment) {
              const newInvestment = investment.data();
              newInvestment.id = investment.id;

              this.investments.push(newInvestment);
            }.bind(this));

            this.isLoading = false;

            this.$refs.topProgress.done();
          }.bind(this));
    },
    deleteInvestment(preDeletedItem) {
      try {
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(logUser, '', 'Deletar Perfil de Investimento', '', logContent);

        investmentsProfileService.deleteInvestment(preDeletedItem.item.id);

        this.getInvestments();

        this.$notify({
          component: DeleteInvestmentsSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          component: DeleteInvestmentsErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Investimento'"
          :routerType="'investment'"
        >
          <div class="table-responsive">
            <profiles-table
              :data="investments"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteInvestment"
            ></profiles-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
