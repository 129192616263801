<script>
import AttachmentTable from '@/components/Attachment/AttachmentTable';
import GetAttachmentsError from '@/components/Notifications/Attachments/GetAttachmentsError';
import DeleteAttachmentError from '@/components/Notifications/Attachments/DeleteAttachmentError';
import DeleteAttachmentSuccess from '@/components/Notifications/Attachments/DeleteAttachmentSuccess';
import DownloadsError from '@/components/Notifications/Downloads/DownloadsError';

import attachmentsService from '@/services/Attachments/attachmentsService';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

const tableColumns = ['Nome'];

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
    AttachmentTable,
  },
  data() {
    return {
      table: {
        title: 'Lista de Anexos',
        columns: [...tableColumns],
      },
      attachments: [],
      isLoading: true,
    };
  },
  mounted() {
    this.getAttachments();
  },
  methods: {
    async getAttachments() {
      this.attachments = [];
      this.$refs.topProgress.start();

      try {
        const attachments = await attachmentsService.getAttachments();
        const attachmentsItems = await attachments.items;

        await attachmentsItems.map((item) => {
          this.attachments.push(item);
        });
      } catch (e) {
        this.$notify({
          component: GetAttachmentsError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    deleteAttachments(preDeletedItem) {
      try {
        attachmentsService.deleteAttachment(preDeletedItem.item.name);

        this.getAttachments();

        this.$notify({
          component: DeleteAttachmentSuccess,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.preDeletedItem = {};
      } catch (e) {
        this.$notify({
          component: DeleteAttachmentError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    downloadItem(user) {
      try {
        attachmentsService.downloadAttachment(user.name);
      } catch (e) {
        this.$notify({
          component: DownloadsError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Anexo'"
          :routerType="'attachment'"
        >
          <div class="table-responsive">
            <attachment-table
              :data="attachments"
              :columns="table.columns"
              :hasActions="true"
              :itemType="'Anexo'"
              :routerType="'attachment'"
              :isNonEditable="true"
              :isDownloadable="true"
              thead-classes="text-primary"
              @deleteItem="deleteAttachments"
              @downloadItem="downloadItem"
            ></attachment-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
