<script>
export default {
  name: 'investor-confirmation-user-card',
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pessoa: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>


<template>
  <card type="user">
    <p class="card-text">
    </p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>

      <a href="#">
        <img class="avatar" src="@/assets/images/anime6.png" alt="..." />

        <div class="row">
          <div class="col-md-12">
            <base-input
              disabled
              v-model="pessoa.dados_complementares.fullName"
              label="Nome completo*"
              placeholder="Nome Completo"
            ></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <base-input label="Tipo de Pessoa*">
              <select class="form-control" v-model="pessoa.dados_complementares.perfil.personType" disabled>
                <option value="" disabled selected>Selecionar</option>
                <option value="physical-person">Pessoa Física</option>
                <option value="legal-person">Pessoa Jurídica</option>
              </select>
            </base-input>
          </div>
        </div>
         <div class="row">
          <div class="col-md-12">
            <base-input
              disabled
              label="Email*"
              :value="loggedUser.email"
              placeholder="Email"
            ></base-input>
          </div>
        </div>
      </a>
    </div>
  </card>
</template>
