<script>
import { DoubleBounce } from 'vue-loading-spinner';

import socialTransformationService from '@/services/SocialTransformation/socialTransformationService';
import pessoasService from '@/services/Pessoas/pessoasService';
import partnerMapper from '@/services/Pessoas/mapper/partnerMapper';
import companyMapper from '@/services/Company/mapper/companyMapper';

import authenticationService from '@/services/Authentication/authenticationService';

import Vue from 'vue';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

import {
  PessoaCaracteristica,
  PessoaCaracteristicas,
  CWLFirebaseError,
} from '@/store/cwl_enums';

import { vueTopprogress } from 'vue-top-progress';

import birthDatepicker from 'vue-birth-datepicker';
import Password from 'vue-password-strength-meter';
import logsService from '@/services/Logs/logsService';

import AddressForm from '@/components/Address/AddressForm';
import CompanyIdentificationForm from '@/components/Company/CompanyIdentificationForm';
import CompanyPartnerConfirmationForm from '@/components/Company/CompanyPartnerConfirmationForm';
import BankAccountInformationForm from '@/components/Banks/BankAccountInformationForm';

export default {
  data() {
    return {
      loading: false,
      pessoa: {
        telefone: {},
        documento: {},
        email: {},
        dados_complementares: {},
      },
      address: {},
      company: companyMapper(),
      cachedPessoa: {
        telefone: {},
        documento: {},
        email: {},
        dados_complementares: {},
      },
      birthday: '',
      telefone: '',
      telefone_original: '',

      tipos_usuario: PessoaCaracteristicas,
      tipo_usuario: '',

      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
    };
  },
  components: {
    DoubleBounce,
    vueTopprogress,
    birthDatepicker,
    Password,
    AddressForm,
    CompanyIdentificationForm,
    CompanyPartnerConfirmationForm,
    BankAccountInformationForm,
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    status() {
      return this.pessoa &&
        this.pessoa.social_transformation_agent &&
        this.pessoa.social_transformation_agent.status
        ? this.pessoa.social_transformation_agent.status
        : '';
    },
    statusTranslated() {
      return this.pessoa && this.pessoa.social_transformation_agent
        ? this.$t(
            'social_transformation.agent.' +
              this.pessoa.social_transformation_agent.status,
          )
        : '';
    },
  },
  async mounted() {
    this.loading = true;
    try {
      this.pessoa = this.$CWLNewPessoaPadrao();
      this.cachedPessoa = this.$CWLNewPessoaPadrao();
      await this.getPessoa();

      if (this.pessoa.empresa && this.pessoa.empresa.id) {
        const empresa = await this.getCompanyById(this.pessoa.empresa.id);

        this.company = {
          ...companyMapper(empresa),
          partner: partnerMapper({ empresa: this.pessoa.empresa }),
        };
      }
    } catch (error) {
      console.error('edit social agent mount error', error);
    }
    this.loading = false;
  },
  methods: {
    async approve() {
      try {
        await socialTransformationService.approveSocialAgent(
          this.pessoa.id,
          this.pessoa.email ? this.pessoa.email.email : '',
        );
        this.$router.push('/social-transformation/agents');
      } catch (error) {
        console.error('approve error', error);
      }
    },
    async disapprove() {
      try {
        await socialTransformationService.disapproveSocialAgent(this.pessoa.id);
        this.$router.push('/social-transformation/agents');
      } catch (error) {
        console.error('approve error', error);
      }
    },
    async getPessoa() {
      this.$refs.topProgress.start();

      try {
        this.pessoa = await pessoasService.getPessoaById(this.userId);

        this.birthday = this.pessoa.dados_complementares.birthday;
        this.telefone = this.pessoa.telefone.telefone;
        this.telefone_original = this.pessoa.telefone.telefone;
        this.tipo_usuario = this.$CWLDescricaoCaracteristica(
          this.pessoa.caracteristicas,
        );
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      } finally {
        this.$refs.topProgress.done();
      }
    },
    async getCompanyById(id) {
      return id
        ? (await pessoasService.findCompanyByIdentificationNumber(id)) || {}
        : {};
    },
  },
};
</script>

<template>
  <div class="add-user">
    <double-bounce v-if="loading"></double-bounce>
    <card v-if="!loading">
      <h5 slot="header" class="title">
        <router-link
          to="/social-transformation/agents"
          class="fa fa-arrow-left"
        ></router-link>
        Ver todos os agentes
      </h5>

      <card>
        <form @submit.prevent="false" v-disable-all>
          <div class="row">
            <div class="col col-md-6 pr-md-1">
              <base-input
                required
                label="Situação do registro"
                v-model="statusTranslated"
                readonly
              >
              </base-input>
            </div>
            <div class="col col-md-6 pr-md-1">
              <base-input
                required
                label="Tipo de usuário*"
                v-model="tipo_usuario"
                readonly
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 pr-md-1">
              <base-input
                label="Nome*"
                placeholder="Informe o nome"
                v-model="pessoa.nome"
                readonly
                required
              ></base-input>
            </div>

            <div class="col-md-4 pr-md-1">
              <base-input
                label="Celular*"
                v-model="telefone"
                placeholder="Informe o melhor celular"
                v-mask="['(##) ####-####', '(##) #####-####']"
                required
                readonly
              ></base-input>
            </div>

            <div class="col-md-4 pl-md-1">
              <label for="" class="control-label">Data de Nascimento</label
              ><br />
              <birth-datepicker
                v-model="birthday"
                :valueIsString="true"
                :high="true"
                :hideHeader="true"
                :attachment="'bottom'"
                :class="'Password__field'"
                :delimiter="'/'"
                :placeholder="'Informe a data de nascimento. Ex: 27/12/1993'"
                :locale="[
                  'Jan',
                  'Fev',
                  'Mar',
                  'Abr',
                  'Mai',
                  'Jun',
                  'Jul',
                  'Ago',
                  'Set',
                  'Out',
                  'Nov',
                ]"
                readonly
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 pr-md-3">
              <base-input
                label="CPF*"
                v-model="pessoa.documento.numero"
                placeholder="Informe o seu cpf"
                type="cpf"
                v-mask="['###.###.###-##']"
                required
                disabled
              ></base-input>
            </div>

            <div class="col-md-4 pr-md-3">
              <base-input
                label="E-mail*"
                v-model="pessoa.email.email"
                placeholder="Informe o melhor e-mail"
                type="email"
                disabled
              ></base-input>
            </div>
          </div>
        </form>
      </card>

      <address-form :initialValue="pessoa.endereco" :readonly="true" />

      <company-identification-form
        :initialValue="company.identification"
        :readonly="true"
      />

      <address-form
        title="Endereço da empresa"
        :initialValue="company.address"
        :readonly="true"
      />

      <company-partner-confirmation-form
        :initialValue="company.partner"
        :readonly="true"
      />

      <bank-account-information-form
        :initialValue="company.bankAccount"
        title="Dados Bancários da Empresa"
        :readonly="true"
      />

      <div v-if="status != 'draft'">
        <button @click="approve()" class="btn btn-sm">Aprovar</button>
        <button @click="disapprove()" class="btn btn-sm btn-danger">
          Reprovar
        </button>
      </div>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style scoped>
.Password__field {
  background-color: #f1f1f1 !important;
  border: 1px solid #ccc !important;
  border-radius: 15px !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
  padding: 13px !important;
  width: 100% !important;
  height: 40px !important;
}
</style>