<script>
import investmentsProfileService from '@/services/InvestmentsProfile/investmentsProfileService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      investment: {},
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    vueTopprogress,
  },
  methods: {
    async addNewInvestment() {
      this.$refs.topProgress.start();

      try {
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.investment;
        logsService.createNewLog(logUser, '', 'Adicionar Perfil de Investimento', '', logContent);

        await investmentsProfileService.createNewInvestment(this.investment);

        this.$refs.topProgress.done();

        this.$router.push('investments-profile');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.investment = {};
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewInvestment()">
        <h5 slot="header" class="title">
          <router-link to="/investments-profile" class="fa fa-arrow-left"></router-link>
          Adicionar Investimento
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
          <base-input
              label="Perfil*"
              placeholder="Perfil"
              v-model="investment.profile"
              required
            ></base-input>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Adicionar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
