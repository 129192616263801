<script>
import CampaignsGeneralTable from '@/components/Reports/CampaignsGeneralTable';
import financingService from '@/services/Financing/financingService';
import getFinancingSummary from '@/functions/getFinancingSummary';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  components: {
    CampaignsGeneralTable,
    DoubleBounce,
  },
  data() {
    return {
      loading: true,
      financings: [],
      modalidade: process.env.VUE_APP_TYPE,
    };
  },
  async mounted() {
    try {
      await this.getFinancings();
    } catch (e) {}
    this.loading = false;
  },
  methods: {
    async getFinancings() {
      const financings = await financingService.findAll();
      for (const financing of financings) {
        financing.summary = await getFinancingSummary(financing.id).catch(
          (e) => ({}),
        );
      }
      this.financings = financings;
    },
  },
};
</script>

<template>
  <div class="annual-report">
    <card>
      <div class="report-filter">
        <h5 v-if="modalidade == 'doacao'">Financeiro Geral de Campanhas</h5>
        <h5 v-if="modalidade == 'equity'">Financeiro Geral de Ofertas</h5>
      </div>

      <double-bounce v-if="loading"></double-bounce>
      <campaigns-general-table :data="financings" v-if="!loading" />
    </card>
  </div>
</template>
