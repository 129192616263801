import axios from 'axios';
import moment from 'moment';
import clickSignService from '@/services/ClickSign/clickSignService';
import pessoasService from '@/services/Pessoas/pessoasService';
import bancarizadoresService from '@/services/Bancarizadores/bancarizadoresService';
import contratosInvestidorService from '@/services/ContratosInvestidor/contratosInvestidorService';

export default {
  async encerrarOperacao(objeto) {
    let order = objeto.order;
    let financing = objeto.financing;
    let contratosInvestidor = await contratosInvestidorService.getByOrderId(order.id);
    if ( contratosInvestidor.length > 0 ) {
        var contratoInvestidor = contratosInvestidor[0];
        let bancarizador = await bancarizadoresService.get(financing.bancarizador.id);
        await Promise.all(bancarizador.responsaveis.map(async responsavel => {
          let pessoa = await pessoasService.getPessoaById(responsavel.id);
          if ( !pessoa ) {
            throw new Error("Pessoa do bancarizador não encontrada.");
          }
          let result = await clickSignService.adicionarSignatarioAoDocumento(contratoInvestidor.document.document_key, pessoa.clicksign.signatario_key);
          if ( result.key ) {
              contratoInvestidor.pessoas.push({
                  pessoa_id: pessoa.id,
                  pessoa_tipo: 'credor',
                  signer_key: pessoa.clicksign.signatario_key
              });
      
              await contratosInvestidorService.update(contratoInvestidor.id, contratoInvestidor);
          }
        }));

        const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'clicksign_finalizar_doc_automatico';
        try {
          let payload = {
            document_key: contratoInvestidor.document.document_key
          };
          await axios.post(endpoint, payload).then(r => r.data);
        } catch (error) {
          throw new Error(error.message);
        }
    }
  },

  async cancelarOperacao(objeto) {
    let order = objeto.order;
    let contratosInvestidor = await contratosInvestidorService.getByOrderId(order.id);
    if ( contratosInvestidor.length > 0 ) {
        let payload = {
          numero_ccb: order.ccb.numero_ccb
        };
        const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'socinal_cancelar_proposta';
    
        try {
          await axios.post(endpoint, payload).then(r => r.data);

          var contratoInvestidor = contratosInvestidor[0];
          await clickSignService.cancelarDocumento(contratoInvestidor.document.document_key);
          // if ( result.key ) {
          contratoInvestidor.status = "cancelado";
          await contratosInvestidorService.update(contratoInvestidor.id, contratoInvestidor);
          // }
        } catch (error) {
          throw new Error(error.message);
        }
    }
  }
};
