<script>
import InvestorsTable from '@/components/Investors/InvestorsTable';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

import investorsService from '@/services/Investors/investorsService';

const tableColumns = ['Nome Completo', 'Email', 'Telefone', 'Status'];

export default {
  components: {
    InvestorsTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista de Investidores',
        columns: [...tableColumns],
      },
      investors: [],
      isLoading: true,
      closeModal: false,
    };
  },
  mounted() {
    this.getInvestors();
  },
  methods: {
    async getInvestors() {
      try {
        const investors = await investorsService.getAllInvestors();

        await investors.forEach((investor) => {
          const newInvestor = investor.data();
          newInvestor.id = investor.id;
          if ( newInvestor.caracteristicas && newInvestor.caracteristicas.includes('usuario') && !newInvestor.disabled ) {
            this.investors.push(newInvestor);
          }
        });

        this.isLoading = false;
      } catch (error) {}
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="false"
          :itemType="'Investidor'"
          :routerType="'investor'"
        >
          <div class="table-responsive">
            <investors-table
              :data="investors"
              :columns="table.columns"
              :hasActions="false"
              :isLoading="isLoading"
              thead-classes="text-primary"
            ></investors-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
