const customerIdentifier = process.env.VUE_APP_CUSTOMER_IDENTIFIER;

export default {
    install(Vue, options) {
      Vue.prototype.$CWLGetPageInstance = function (page) {

        try {
            let fileExists = require('@/pages/' + customerIdentifier + '/' + page);
            return () => import('@/pages/' + customerIdentifier + '/' + page);
        }
        catch (e) {
            return () => import('@/pages/_default/' + page);
        }
      }

      Vue.prototype.$CWLGetComponentInstance = function (component) {

        try {
          let moduleToReturn = require('@/components/' + customerIdentifier + '/' + component);
          return moduleToReturn.default;
        }
        catch (e) {
          let moduleToReturn = require('@/components/_default/' + component);
          return moduleToReturn.default;
        }
      }
    },
};