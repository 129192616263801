<script>
import categoriasService from '@/services/Categorias/categoriasService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import { vueTopprogress } from 'vue-top-progress';

export default {
  data() {
    return {
      categoria: {},
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  components: {
    vueTopprogress,
  },
  methods: {
    async addNewCategoria() {
      this.$refs.topProgress.start();

      try {
        const user = {
          email: this.currentPessoa.email.email,
        };

        await categoriasService.createNewCategoria(this.categoria, user);

        this.$refs.topProgress.done();

        this.$router.push('categorias');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();

        this.$notify({
          title: 'Ooopss',
          message: error.message,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.categoria = {};
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewCategoria()">
        <h5 slot="header" class="title">
          <router-link to="/categorias" class="fa fa-arrow-left"></router-link>
          Adicionar Categoria
        </h5>

        <div class="row">
          <div class="col-md-4 pl-md-1">
            <base-input
              label="Nome da Categoria*"
              placeholder="Nome da Categoria"
              v-model="categoria.name"
              required
            >
            </base-input>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Adicionar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
