<script>
import ContributionsTable from '@/components/Financing/ContributionsTable.vue';
import ordersService from '@/services/Orders/ordersService';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  components: {
    ContributionsTable,
    DoubleBounce,
  },
  computed: {
    campaignId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: true,
      table: {
        title: 'Limites de Investimentos',
        data: [],
      },
    };
  },
  async mounted() {
    try {
      await this.getOrders();
    } catch (e) {}
    this.loading = false;
  },
  methods: {
    async getOrders() {
      const orders = await ordersService.getOrdersByFinancingId(
        this.campaignId,
      );
      orders.forEach((o) => this.table.data.push(o));
    },
  },
};
</script>

<template>
  <div class="contributions">
    <card>
      <h5 slot="header" class="title">
        <router-link
          to="/financing"
          class="fa fa-arrow-left"
          title="Voltar"
        ></router-link>
        Visualização de Aportes
      </h5>

      <double-bounce v-if="loading"></double-bounce>
      <contributions-table :data="table.data" />
    </card>
  </div>
</template>

<style
  src="@/assets/sass/black-dashboard/views/_contributions.scss"
  lang="scss"
  scoped
></style>
