<script>
import segmentsService from '@/services/Segments/segmentsService';
import UpdatedSegmentNotification from '@/components/Notifications/Segments/UpdatedSegmentNotification';
import UpdatedErrorNotification from '@/components/Notifications/General/UpdatedErrorNotification';
import GetSegmentErrorNotification from '@/components/Notifications/Segments/GetSegmentErrorNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      segment: {},
      cachedSegment: {},
    };
  },
  components: {
    vueTopprogress,
  },
  computed: {
    segmentId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getSegment();
  },
  methods: {
    async getSegment() {
      this.$refs.topProgress.start();

      try {
        segmentsService.getSegment(this.segmentId)
            .then(function(segment) {
              this.segment = segment.data();
              this.cachedSegment = segment.data();

              this.$refs.topProgress.done();
            }.bind(this));
      } catch (error) {
        this.$notify({
          component: GetSegmentErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async updateSegment() {
      this.$refs.topProgress.start();

      try {
        this.$refs.topProgress.done();

        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.segment;
        logsService.createNewLog(logUser, '', 'Atualizar Segmento', '', logContent);

        
        await segmentsService.updateSegment(this.segmentId, this.segment);

        this.$router.push('/segment-interest');

        this.$notify({
          component: UpdatedSegmentNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          component: UpdatedErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateSegment()">
        <h5 slot="header" class="title">
          <router-link to="/segment-interest" class="fa fa-arrow-left"></router-link>
          Editar Segmento
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Nome do Segmento*"
              placeholder="Nome do Segmento"
              v-model="segment.name"
              required
            ></base-input>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-sm"
        >
          Editar
        </button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
