<script>
import VueHtml2pdf from 'vue-html2pdf';
import settingsService from '@/services/Settings/settingsService';
import financingService from '@/services/Financing/financingService';

export default {
  data() {
    return {
      reportInformations: {
        patrimony: 0,
        completedSuccessfully: 0,
        inProgress: 0,
        completedUnsuccessfully: 0,
        referenceYear: '',
      },
      settings: {},
      financings: [],
      years: ['2020'],
    };
  },
  components: {
    VueHtml2pdf,
  },
  computed: {
    listOfFinancing() {
      return this.reportInformations.referenceYear + 'ano';
    },
  },
  mounted() {
    this.getInformations();
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async getInformations() {
      const financials = await financingService.getAll();

      financials.forEach((financial) => {
        const newFinancial = financial.data();

        this.financings.push(newFinancial);

        if (newFinancial.status == 'completed-unsuccessfully') {
          this.reportInformations.completedUnsuccessfully = +1;
        } else if (newFinancial.status == 'completed-successfully') {
          this.reportInformations.completedSuccessfully = +1;
        } else {
          this.reportInformations.inProgress = +1;
        }
      });

      this.getSettings();
    },
    async getSettings() {
      this.settings = await settingsService.getPublicSettings();
    },
  },
};
</script>

<template>
  <div class="annual-report">
    <form @submit.prevent="generateReport()">
      <card>
        <div class="report-filter">
          <h5>
            Relatório Anual CVM
            <base-input label="Ano de Referência">
              <select
                class="form-control"
                v-model="reportInformations.referenceYear"
                required
              >
                <option value="" selected disabled>Selecionar</option>
                <option
                  v-for="(year, index) in years"
                  :key="index"
                  :value="year"
                >
                  {{ year }}
                </option>
              </select>
            </base-input>
          </h5>
        </div>

        <!-- <div class="generate-report">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <base-input
                v-model="reportInformations.partnerNumber"
                type="number"
                required
                label="N.º de Sócios"
                placeholder="Informe o N.º de sócios da Plataforma"
              ></base-input>
            </div>

            <div class="col-lg-4 col-md-6">
              <base-input
                v-model="reportInformations.employeesNumber"
                type="number"
                required
                label="N.º de Funcionários"
                placeholder="Informe o N.º de funcionários da Plataforma"
              ></base-input>
            </div>

            <div class="col-lg-4 col-md-6">
              <div id="currencyInput">
                <label>Patrimônio Líquido</label>
                <currency-input
                  v-model="reportInformations.patrimony"
                  required
                  class="form-control"
                  placeholder="Informe o patrimônio líquido"
                  currency="BRL"
                  locale="pt-br"
                />
              </div>
            </div>
          </div>

          <div class="generate-button">
            <button type="submit" class="btn btn-primary mt-4">
              Gerar Relatório <i class="fas fa-arrow-circle-down"></i>
            </button>
          </div>
        </div> -->
      </card>
    </form>
    <!--
    <vue-html2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1500"
      filename="Relatório Anual CVM"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="pdf-content">
        <h1>Anexo 27-II</h1>
        <p class="sub-title">Relatório Anual - Plataformas Eletrônicas de Investimento Participativo</p>
        <div class="content-box">
          <div class="identification-box">
            <h4>Identificação da Plataforma</h4>
            <ul class="items">
              <li class="item">
                Nome <span>: {{ settings.platformName }}</span>
              </li>
              <li class="item">
                CNPJ
                <span>: {{ settings.cnpj }}</span>
              </li>
              <li class="item">
                Pagina na rede mundial de computadores
                <span>: {{ settings.site }}</span>
              </li>
            </ul>
          </div>
          <div class="identification-box">
            <h4>Relatório Anual</h4>
            <ul class="items">
              <li class="item">
                Ano de Referência
                <span>: {{ reportInformations.referenceYear }}</span>
              </li>
              <li class="item">
                Nº de ofertas encerradas cujo valor alvo mínimo de captação foi atingido
                <span>: {{ reportInformations.completedSuccessfully }}</span>
              </li>
              <li class="item">
                Nº de ofertas encerradas cujo valor alvo mínimo de captação não foi atingido
                <span>: {{ reportInformations.completedUnsuccessfully }} </span>
              </li>
              <li class="item">
                Nº de ofertas em andamento
                <span>: {{ reportInformations.inProgress }}</span>
              </li>
              <li class="item">
                Nº de sócios
                <span>: {{ reportInformations.partnerNumber }}</span>
              </li>
              <li class="item">
                Nº de funcionários
                <span>: {{ reportInformations.employeesNumber }}</span>
              </li>
              <li class="item">
                Patrimônio líquido (R$)
                <span>: {{ reportInformations.patrimony | currency }}</span>
              </li>
            </ul>
          </div>
          <div class="identification-box">
            <h4>Informações Sobre as Ofertas Encerradas Cujo Valor Alvo de Captação Mínimo não foi Atingido</h4>
            <ul class="items" v-for="(financing, index) in financings" :key="index">
              <li class="item">
                Sociedade empresária de pequeno porte
                <span>: {{ financing.name }}</span>
              </li>
              <li class="item">
                CNPJ
                <span></span>
              </li>
              <li class="item">
                Tipo societário
                <span></span>
              </li>
              <li class="item">
                Tipo de veículo de investimento do sindicado de investimento participativo (se houver)
                <span></span>
                <span></span>
              </li>
              <li class="item">
                Investidor líder do sindicato (se houver)
                <span></span>
                <span></span>
              </li>
              <li class="item">
                Página da sociedade na rede mundial de computadores, se houver
                <span></span>
              </li>
              <li class="item">
                Data de início da oferta
                <span>: {{ financing.fields['Início da Oferta'].value | moment('LL') }}</span>
              </li>
              <li class="item">
                Data de encerramento da oferta
                <span>: {{ financing.fields['Encerramento da Oferta'].value | moment('LL') }}</span>
              </li>
              <li class="item">
                Quantidade de valores mobiliários objeto da oferta
                <span>: {{ financing.fields['Quantidade de Ações'].value }}</span>
              </li>
              <li class="item">
                Espécie
                <span></span>
              </li>
              <li class="item">
                Classe
                <span></span>
              </li>
              <li class="item">
                Preço unitário
                <span>: {{ financing.fields['Valor da Ação'].value | currency }}</span>
              </li>
              <li class="item">
                Valor alvo máximo da oferta
                <span>: {{ financing.fields['Valor da Captação'].value | currency }}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </vue-html2pdf> -->
  </div>
</template>
