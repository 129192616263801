<script>
import statesService from '@/services/States/statesService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      state: {},
      customMask: {
        mask: 'FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
        tokens: {
          'F': {
            pattern: /^[a-zA-Z\s]*$/
          },
        }
      },
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    vueTopprogress,
  },
  methods: {
    async addNewState() {
      this.$refs.topProgress.start();

      try {
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.state;
        logsService.createNewLog(logUser, '', 'Adicionar Estado', '', logContent);

        await statesService.createNewState(this.state);

        this.$refs.topProgress.done();

        this.$router.push('states');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.state = {};
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewState()">
        <h5 slot="header" class="title">
          <router-link to="/states" class="fa fa-arrow-left"></router-link>
          Adicionar Estado
        </h5>

        <div class="row">
          <div class="col-md-4">
            <base-input
              label="Nome do Estado*"
              placeholder="Nome do Estado"
              v-model="state.name"
              required
              v-mask="customMask"
            >
            </base-input>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Nome do UF*"
              placeholder="Nome do UF"
              v-model="state.uf"
              v-mask="['AA']"
              required
            >
            </base-input>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Adicionar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
