<script>
import { DoubleBounce } from 'vue-loading-spinner';
import pessoaEnderecoService from '@/services/Pessoas/pessoaEnderecoService';

export default {
  name: 'register-social-transformation-agent',
  props: {
    address: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      value: this.address,
    };
  },
  computed: {},
  components: {
    DoubleBounce,
  },
  methods: {
    async handleAddress(address) {
      this.value = {
        ...this.value,
        ...address,
      };
      pessoaEnderecoService.saveCurrentUserAddress(this.value);
    },
  },
  async mounted() {
    this.$data.loading = false;
  },
};
</script>

<template>
  <address-form :initialValue="value" @submit="handleAddress" />
</template>
