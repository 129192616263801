<script>
import investmentsProfileService from '@/services/InvestmentsProfile/investmentsProfileService';
import UpdatedInvestmentNotification from '@/components/Notifications/Investments/UpdatedInvestmentNotification';
import UpdatedInvestmentErrorNotification from '@/components/Notifications/Investments/UpdatedInvestmentErrorNotification';
import GetInvestmentErrorNotification from '@/components/Notifications/Investments/GetInvestmentErrorNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      investment: {},
      cachedInvestment: {},
    };
  },
  components: {
    vueTopprogress,
  },
  computed: {
    investmentId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getInvestment();
  },
  methods: {
    async getInvestment() {
      this.$refs.topProgress.start();

      try {
        investmentsProfileService.getInvestment(this.investmentId)
            .then(function(investment) {
              this.investment = investment.data();
              this.cachedInvestment = investment.data();

              this.$refs.topProgress.done();
            }.bind(this));
      } catch (error) {
        this.$notify({
          component: GetInvestmentErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async updateInvestment() {
      this.$refs.topProgress.start();

      try {
        this.$refs.topProgress.done();

        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.investment;
        logsService.createNewLog(logUser, '', 'Atualizar Perfil de Investimento', '', logContent);
        
        await investmentsProfileService.updateInvestment(this.investmentId, this.investment);

        this.$router.push('/investments-profile');

        this.$notify({
          component: UpdatedInvestmentNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          component: UpdatedInvestmentErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateInvestment()">
        <h5 slot="header" class="title">
          <router-link to="/investments-profile" class="fa fa-arrow-left"></router-link>
          Editar Investimento
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
          <base-input
              label="Perfil*"
              placeholder="Perfil"
              v-model="investment.profile"
              required
            ></base-input>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-sm"
        >
          Editar
        </button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
