import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  storageRef(path) {
    return firebase.storage().ref(path);
  },

  periodicInformationRef() {
    return firebase.firestore().collection("periodic-information");
  },

  async findAll() {
    try {
      const snapshot = await this.periodicInformationRef().get();
      if (snapshot.empty) {
        return [];
      }

      return snapshot.docs.map((value) => ({
        id: value.id,
        ...value.data(),
      }));
    } catch (error) {
      console.error('periodicInformation fnd all error', error);
    }
  },

  getAll() {
    return withLog(() => this.periodicInformationRef().get());
  },

  getAllOrderByCreate(financing_id) {
    return withLog(() =>
      this.periodicInformationRef()
        .where('financing_id', '==', financing_id)
        .orderBy("created_at", "desc").get(),
    );
  },

  create(periodicInformation) {
    try {
      const doc = this.periodicInformationRef().doc();
      const value = {
        ...periodicInformation,
        id: doc.id,
      };
      doc.set(value);
      return value;
    } catch (error) {
      console.error('periodic-information service create error', error);
    }
  },


  delete(periodicInformation) {
    withLog(() => this.periodicInformationRef().doc(periodicInformation).delete());
  },

  async findById(id) {
    try {
      return (await this.periodicInformationRef().doc(id).get()).data();
    } catch (error) {
      console.error('periodicInformation servic find by id error', error);
      throw Error('Não foi possível consultar os dados da campanha.');
    }
  },

  get(id) {
    return withLog(() => this.periodicInformationRef().doc(id).get());
  },

  update(id, periodicInformation) {
    return withLog(() => this.periodicInformationRef().doc(id).update(periodicInformation));
  },

  getAllOrderByCreate(financing_id) {
    return withLog(() =>
      this.periodicInformationRef()
        .where('financing_id', '==', financing_id)
        .orderBy("created_at", "desc").get(),
    );
  },

  async downloadPeriodicInformation(file, storage = 'PeriodicInformations', type = undefined) {
    const operationalFinancing = this.storageRef(storage);

    operationalFinancing
      .child(file)
      .getDownloadURL()
      .then(function (url) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const a = document.createElement('a');

          a.href = window.URL.createObjectURL(xhr.response);
          a.download = file;
          if ( type ) {
            a.download = file + type;
          }
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
        };
        xhr.open('GET', url);
        xhr.send();
      });
  },

  async addNewPeriodicInforamtionImage(
    attachment,
    fileName,
    storage,
    periodicInforamtionId,
  ) {
    const attach = this.storageRef(storage);
    const fileNameAdjusted = fileName.replace(/\//g, '_');

    const response = await attach
      .child(periodicInforamtionId)
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },
};
