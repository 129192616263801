<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import settingsService from '@/services/Settings/settingsService';

import { collectionRef } from '@/firebase';

import { vueTopprogress } from 'vue-top-progress';
import { quillEditor } from 'vue-quill-editor';
import { cnpj } from '@/validate/customValidators.js';
import { withLog } from '@/logger/with_log';
import investorsService from '@/services/Investors/investorsService';
import hubspotService from '@/services/HubSpot/hubspotService';
import Tabs from '@/components/Tabs/Tabs';
import Tab from '@/components/Tabs/Tab';
import Modal from '@/components/Modal';

import {
  CWLTipoTaxaPagamento, CWLInvestimentoDashboard
} from '@/store/cwl_enums';

// import pdfvuer from 'pdfvuer';
// import {DoubleBounce} from 'vue-loading-spinner';

export default {
  components: {
    vueTopprogress,
    quillEditor,
    'zz-tabs': Tabs,
    'zz-tab': Tab,
    Modal,
    // DoubleBounce,
    // pdf: pdfvuer,
  },
  data() {
    return {
      contentTermosUso: '',
      contentTaxasPrazo: '',
      contentTermos4A: '',
      contentTermos4B: '',
      contentTermos4C: '',
      contentSocialAgent: '',
      editorCondicoesTermosUsoOption: {
        placeholder:
          'Descreva aqui as condições e termos de uso da plataforma.',
      },
      editorTaxasPrazosOption: {
        placeholder: 'Descreva aqui as taxas e prazos da plataforma.',
      },
      editorTermosAgenteOption: {
        placeholder: 'Descreva aqui os termos de serviço para Agente (API3).',
      },
      editorTermoUso4A: {
        placeholder: 'Descreva aqui os termos.',
      },
      editorTermoUso4B: {
        placeholder: 'Descreva aqui os termos.',
      },
      editorTermoUso4C: {
        placeholder: 'Descreva aqui os termos.',
      },
      settingsPublic: {
        overwriteMinGlobalInvestment: undefined,
      },
      settings: {
        integracao: {
          safe2pay: {
            safe2pay_api_keys: {},
            credit_card: {},
            doc: {},
            ted: {},
            pix: {}
          },
          socinal: {
            username: "",
            password: ""
          },
          fiducia: {
            username: "",
            password: "",
            header_user: ""
          }
        },
        terms: {},
        emails: {},
        _features: {},
      },
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      showSafe2PayModal: false,
      showSocinalModal: false,
      showFiduciaModal: false,
      settingsLoaded: false,

      CWLTipoTaxaPagamento,
      CWLInvestimentoDashboard,

      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },

      bancos: [],

      dados_bancarios: {
        banco: '',
        agencia_numero: '',
        agencia_digito: '',
        conta_numero: '',
        conta_digito: '',
      },
      dados_bancarios_plataforma: {
        banco: '',
        agencia_numero: '',
        agencia_digito: '',
        conta_numero: '',
        conta_digito: '',
      },
    };
  },
  validations: {
    settings: {
      cnpj: {
        valid: cnpj,
      },
    },
  },
  methods: {
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.contentTermosUso = html;
      this.settings.terms.condicoes_termos_uso = html;
    },
    onTaxasPrazoEditorChange({ html }) {
      this.contentTaxasPrazo = html;
      this.settings.terms.taxas_prazos = html;
    },
    onEditorAgentChange({ html }) {
      this.contentSocialAgent = html;
      this.settings.terms.agent_term = html;
    },
    onTermos4AEditorChange({ html }) {
      this.contentTermos4A = html;
      this.settings.termos_4a = html;
    },

    onTermos4BEditorChange({ html }) {
      this.contentTermos4B = html;
      this.settings.termos_4b = html;
    },

    onTermos4CEditorChange({ html }) {
      this.contentTermos4C = html;
      this.settings.termos_4c = html;
    },

    async loadSettings() {
      this.settingsPublic = await settingsService.getPublicSettings();
      var settings = await settingsService.getPrivateSettings();
      if ( settings.dados_bancarios ) {
        this.dados_bancarios = settings.dados_bancarios;
      }
      if ( settings.dados_bancarios_plataforma ) {
        this.dados_bancarios_plataforma = settings.dados_bancarios_plataforma;
      }
      
      this.settings = {
        ...settings,
        emails: settings.emails || {},
        terms: settings.terms || {},
        _features: settings._features || {},
      };
      if ( !this.settings.integracao.socinal ) {
        this.settings.integracao.socinal = {
          username: "",
          password: ""
        };
      }
      if ( !this.settings.integracao.fiducia ) {
        this.settings.integracao.fiducia = {
          username: "",
          password: "",
          header_user: ""
        };
      }
      this.settingsLoaded = true;
    },
    checkSandboxAtivo() {
      this.$refs.marketplace_token_sandbox_active.click();
    },
    checkEmailSecure() {
      this.$refs.email_secure.click();
    },
    checkOverwriteGlobalInvestment() {
      this.$refs.overwriteMinGlobalInvestment.click();
    },
    checkValidarLimiteInvestimento() {
      this.$refs.validarLimiteInvestimento.click();
    },
    checkSubcontaPlataformaSafe2Pay() {
      this.$refs.subcontaPlataformaSafe2Pay.click();
    },
    checkIsBancarizadorManual() {
      this.$refs.isBancarizadorManual.click();
    },
    async integrarContatosCRM() {
      let investors = await investorsService.getAllInvestorsUsuario();
      if ( !investors.empty ) {
        for ( const investor of investors.docs ) {
          const pessoa = investor.data();

          try {
            if ( pessoa.id ) {
              await hubspotService.cirarContatoAPI(pessoa.id);
            }
          }
          catch (error) {
            console.log(error);
          }
        }
      }
    },
    async getBancos() {
      const that = this;

      await collectionRef('banks')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((banco) => {
            const obj = banco.data();
            obj.id = banco.id;
            that.bancos.push(obj);
          });
        })
        .catch((error) => console.error('error', error));
    },
    async save() {
      if (!this.$v.settings.cnpj.valid) {
        return this.$notify({
          title: 'Informe um CNPJ válido!',
          message: 'Informe um número de CNPJ válido.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 7000,
        });
      }

      this.$refs.topProgress.start();
      this.settings.dados_bancarios = this.dados_bancarios;
      this.settings.dados_bancarios_plataforma = this.dados_bancarios_plataforma;

      try {
        await settingsService.updatePublicSettings(this.settingsPublic);
        await settingsService.updatePrivateSettings(this.settings);

        this.$refs.topProgress.done();
        this.$notify({
          title: 'Configurações atualizadas!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    funcionalidadesCheck(element) {
      let elementType = element.srcElement.getAttribute('data-type');
      if ( elementType == "permitir" ) {
        let ref = element.srcElement.getAttribute('for');
        this.funcionalidadesCheckPermitir(this.$refs[ref]);
      }
    },
    funcionalidadesCheckPermitir(element) {
      element.click();
    },

    integracaoSafe2PayCheck(element) {
      let elementType = element.srcElement.getAttribute('data-type');
      if ( elementType == "habilitar" ) {
        let ref = element.srcElement.getAttribute('for');
        this.integracaoSafe2PayCheckHabilitar(this.$refs[ref]);
      }
    },
    integracaoSafe2PayCheckHabilitar(element) {
      element.click();
    },
    checkHabilitarEmail() {
      this.$refs.email_habilitar.click();
    },
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill;
    },
  },
  mounted() {
    this.loadSettings();
    this.getBancos();
  },
};
</script>

<template>
  <div class="add-user">
    <form @submit.prevent="save()">
      <div style="text-align: right;">
        <button type="submit" class="btn btn-sm mt-3">
          Salvar configurações
        </button>
      </div>

      <div>
        <zz-tabs>
          <zz-tab id="configuracoes-gerais" name="Configurações Gerais">
              
            <div class="row">
              <div class="col-md-3 mt-3">
                <base-input
                  label="Nome da empresa da Plataforma"
                  placeholder="Informe o nome da empresa"
                  v-model="settings.companyName"
                >
                </base-input>
              </div>
              <div class="col-md-3 mt-3">
                <base-input
                  label="Nome da Plataforma"
                  placeholder="Informe o nome da plataforma"
                  v-model="settings.platformName"
                >
                </base-input>
              </div>
              <div class="col-md-3 mt-3">
                <base-input
                  label="Identificação da Plataforma"
                  placeholder="Informe a identificação"
                  v-model="settings.platformIdentifier"
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mt-3">
                <base-input
                  label="Site da Plataforma"
                  placeholder="Informe o site"
                  v-model="settings.site"
                >
                </base-input>
              </div>

              <div class="col-md-3 mt-3">
                <label> Investimento Mínimo Permitido </label>
                <currency-input
                  placeholder="Informe o valor mínimo"
                  v-model="settingsPublic.minGlobalInvestment"
                  currency="BRL"
                  locale="pt-br"
                  :auto-decimal-mode="true"
                  class="form-control"
                />
              </div>

              <div class="col-md-3 mt-3">
                <base-input
                  label="Material Didático"
                  placeholder="Informe o link"
                  v-model="settings.link_material_didatico"
                >
                </base-input>
              </div>

              <div class="col-md-3 mt-3">
                <base-input
                  label="Investimentos Dashboard"
                >
                  <select
                    class="form-control"
                    v-model="settings.investimento_dashboard"
                    required
                  >
                    <option :value="CWLInvestimentoDashboard.Pagos">Apresentar somente investimentos pagos</option>
                    <option :value="CWLInvestimentoDashboard.PagosPendentes">Apresentar investimentos pagos e pendentes</option>
                  </select>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-12">
                <input
                  name="overwriteMinGlobalInvestment"
                  ref="overwriteMinGlobalInvestment"
                  type="checkbox"
                  v-model="settingsPublic.overwriteMinGlobalInvestment"
                />
                <label
                  for="overwriteMinGlobalInvestment"
                  style="margin-left: 5px"
                  @click="checkOverwriteGlobalInvestment"
                >
                  Permitir Sobrescrever Investimento Mínimo Permitido</label
                >
              </div>
              <div class="col-md-12 mt-12">
                <input
                  name="validarLimiteInvestimento"
                  ref="validarLimiteInvestimento"
                  type="checkbox"
                  v-model="settingsPublic.validar_limite_investimento"
                />
                <label
                  for="validarLimiteInvestimento"
                  style="margin-left: 5px"
                  @click="checkValidarLimiteInvestimento"
                >
                  Travar investimentos maiores que o limite</label
                >
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-md-3 mt-3">
                <base-input
                  label="Razão Social Plataforma*"
                  placeholder="Informe a razão social"
                  v-model="settings.razao_social"
                  required
                >
                </base-input>
              </div>
              <div class="col-md-3 mt-3">
                <div class="form-group">
                  <base-input
                    label="CNPJ Plataforma*"
                    placeholder="Informe o CNPJ"
                    v-model="settings.cnpj"
                  >
                  </base-input>
                  <div
                    class="error"
                    v-if="settings.cnpj != '' && $v.settings.cnpj.$invalid"
                  >
                    Informe um CNPJ válido.
                  </div>
                </div>
              </div>
              <div class="col-md-3 mt-3">
                <base-input
                  label="Email Plataforma*"
                  placeholder="Informe o email"
                  v-model="settings.email_plataforma"
                  required
                >
                </base-input>
              </div>
              <div class="col-md-3 mt-3">
                <base-input required label="Banco Plataforma*">
                  <v-select
                    label="name"
                    name="banco_plataforma"
                    class="style-chooser"
                    :options="bancos"
                    v-model="dados_bancarios_plataforma.banco"
                    :components="{ Deselect }"
                    required
                  >
                    <template v-slot:no-options="{ search_plataforma, searching_plataforma }">
                      <template v-if="searching_plataforma">
                        <em style="opacity: 0.9; color: #555"
                          ><b>{{ search_plataforma }}</b> não encontrado</em
                        >
                      </template>
                    </template>
                  </v-select>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mt-3">
                <base-input
                  label="Agência Plataforma*"
                  placeholder="Informe sua agência"
                  v-mask="['####']"
                  v-model="dados_bancarios_plataforma.agencia_numero"
                  required
                ></base-input>
              </div>
              <div class="col-md-3 mt-3">
                <base-input
                  label="Dígito da agência*"
                  placeholder="Dígito"
                  v-mask="['#']"
                  v-model="dados_bancarios_plataforma.agencia_digito"
                  required
                ></base-input>
              </div>
              <div class="col-md-3 mt-3">
                <base-input
                  label="Conta Plataforma*"
                  placeholder="Informe o número da sua conta"
                  v-mask="['####################']"
                  v-model="dados_bancarios_plataforma.conta_numero"
                  required
                ></base-input>
              </div>
              <div class="col-md-3 mt-3">
                <base-input
                  label="Dígito da conta*"
                  placeholder="Dígito"
                  v-mask="['#']"
                  v-model="dados_bancarios_plataforma.conta_digito"
                  required
                ></base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mt-3">
                <base-input
                  label="Nome Responsável Plataforma*"
                  placeholder="Informe o nome"
                  v-model="settings.nome_responsavel_plataforma"
                  required
                >
                </base-input>
              </div>
              <div class="col-md-3 mt-3">
                <div class="form-group">
                  <base-input
                    label="CPF Responsável Plataforma*"
                    placeholder="Informe o CPF"
                    v-model="settings.cpf_responsavel_plataforma"
                    v-mask="'###.###.###-##'"
                  >
                  </base-input>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-12">
                <input
                  name="subcontaPlataformaSafe2Pay"
                  ref="subcontaPlataformaSafe2Pay"
                  type="checkbox"
                  v-model="settings.subconta_plataforma_safe2pay"
                />
                <label
                  for="subcontaPlataformaSafe2Pay"
                  style="margin-left: 5px"
                  @click="checkSubcontaPlataformaSafe2Pay"
                >
                  Utilizar dados bancários da plataforma acoplada na subconta Safe2Pay
                </label>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-md-3 mt-3">
                <base-input
                  label="Razão Social ECWL*"
                  placeholder="Informe a razão social"
                  v-model="settings.ecwl_razao_social"
                  required
                ></base-input>
              </div>
              <div class="col-md-3 mt-3">
                <base-input
                  label="CNPJ ECWL*"
                  placeholder="Informe o CNPJ"
                  v-model="settings.ecwl_cnpj"
                  required
                ></base-input>
              </div>
              <div class="col-md-3 mt-3">
                <base-input required label="Banco ECWL*">
                  <v-select
                    label="name"
                    class="style-chooser"
                    :options="bancos"
                    v-model="dados_bancarios.banco"
                    :components="{ Deselect }"
                    required
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        <em style="opacity: 0.9; color: #555"
                          ><b>{{ search }}</b> não encontrado</em
                        >
                      </template>
                    </template>
                  </v-select>
                </base-input>
              </div>
          </div>

          <div class="row">
            <div class="col-md-3 mt-3">
              <base-input
                label="Agência ECWL*"
                placeholder="Informe sua agência"
                v-mask="['####']"
                v-model="dados_bancarios.agencia_numero"
                required
              ></base-input>
            </div>
            <div class="col-md-3 mt-3">
              <base-input
                label="Dígito da agência*"
                placeholder="Dígito"
                v-mask="['#']"
                v-model="dados_bancarios.agencia_digito"
                required
              ></base-input>
            </div>
            <div class="col-md-3 mt-3">
              <base-input
                label="Conta ECWL*"
                placeholder="Informe o número da sua conta"
                v-mask="['####################']"
                v-model="dados_bancarios.conta_numero"
                required
              ></base-input>
            </div>
            <div class="col-md-3 mt-3">
              <base-input
                label="Dígito da conta*"
                placeholder="Dígito"
                v-mask="['#']"
                v-model="dados_bancarios.conta_digito"
                required
              ></base-input>
            </div>
          </div>

          </zz-tab>
          <zz-tab id="temos" name="Termos">
              
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <label for="quillEditor">Condições e Termos de Uso</label>
                    <quill-editor
                      ref="quillEditor"
                      v-model="settings.terms.condicoes_termos_uso"
                      :options="editorCondicoesTermosUsoOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)"
                      @change="onEditorChange($event)"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 mt-3">
                    <label for="quillEditor">Taxas e Prazos</label>
                    <quill-editor
                      ref="quillEditor"
                      v-model="settings.terms.taxas_prazos"
                      :options="editorTaxasPrazosOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)"
                      @change="onTaxasPrazoEditorChange($event)"
                    />
                  </div>
                </div>

                <h5 slot="header" class="subtitle">
                <b>Variáveis:</b> #data, #local, #nomeInvestidor, #cpfInvestidor,
                #razaoSocialEmissor, #montante
              </h5>

              <div class="row">
                <div class="col-md-12 mt-3">
                  <label for="quillEditor">Autodeclaração - Anexo 4-A</label>
                  <quill-editor
                    ref="quillEditor"
                    v-model="settings.termos_4a"
                    :options="editorTermoUso4A"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onTermos4AEditorChange($event)"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mt-3">
                  <label for="quillEditor">Autodeclaração - Anexo 4-B</label>
                  <quill-editor
                    ref="quillEditor"
                    v-model="settings.termos_4b"
                    :options="editorTermoUso4B"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onTermos4BEditorChange($event)"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mt-3">
                  <label for="quillEditor">Autodeclaração - Anexo 4-C</label>
                  <quill-editor
                    ref="quillEditor"
                    v-model="settings.termos_4c"
                    :options="editorTermoUso4C"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)"
                    @change="onTermos4CEditorChange($event)"
                  />
                </div>
              </div>
              
          </zz-tab>
          <zz-tab id="agente-api3" name="Agente (API3)" v-if="modalidade == 'doacao'">

                <div class="row">
                  <div class="col-md-12 mt-3">
                    <label for="quillEditor">Termos de Parceria de Serviço</label>
                    <quill-editor
                      ref="quillEditor"
                      v-model="settings.terms.agent_term"
                      :options="editorTermosAgenteOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)"
                      @change="onEditorAgentChange($event)"
                    />
                  </div>
                </div>
          </zz-tab>
          <zz-tab id="integracao" name="Integração">

              <div class="row">
                <div class="col-lg-2 col-md-3">
                  <div class="row align-items-center integracao-botao-abrir-modal" @click="$router.push('settings/safe2pay')">
                    <div class="col-md-9">
                      <img src="@/assets/images/integracao_logo_safe2pay.png" class="integracao-botao-abrir-modal-logo" />
                    </div>
                    <div class="col-md-3 text-right" style="margin-top: 0 !important;">
                      <img src="@/assets/images/check.png" class="integracao-botao-abrir-modal-img-checked" v-if="settingsLoaded && settings.integracao.safe2pay && settings.integracao.safe2pay.enabled"/>
                      <img src="@/assets/images/arrow-right.png" class="integracao-botao-abrir-modal-img-not-checked" v-if="settingsLoaded && (!settings.integracao.safe2pay || !settings.integracao.safe2pay.enabled)"/>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 offset-1">
                  <div class="row align-items-center integracao-botao-abrir-modal" @click="$router.push('settings/socinal')">
                    <div class="col-md-9">
                      <img src="@/assets/images/socinal_logo.png" class="integracao-botao-abrir-modal-logo" />
                    </div>
                    <div class="col-md-3 text-right" style="margin-top: 0 !important;">
                      <img src="@/assets/images/check.png" class="integracao-botao-abrir-modal-img-checked" v-if="settingsLoaded && settings.integracao.socinal && settings.integracao.socinal.enabled"/>
                      <img src="@/assets/images/arrow-right.png" class="integracao-botao-abrir-modal-img-not-checked" v-if="settingsLoaded && (!settings.integracao.socinal || !settings.integracao.socinal.enabled)"/>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 offset-1">
                  <div class="row align-items-center integracao-botao-abrir-modal" @click="$router.push('settings/fiducia')">
                    <div class="col-md-9">
                      <img src="@/assets/images/fiducia_logo.png" class="integracao-botao-abrir-modal-logo" />
                    </div>
                    <div class="col-md-3 text-right" style="margin-top: 0 !important;">
                      <img src="@/assets/images/check.png" class="integracao-botao-abrir-modal-img-checked" v-if="settingsLoaded && settings.integracao.fiducia && settings.integracao.fiducia.enabled"/>
                      <img src="@/assets/images/arrow-right.png" class="integracao-botao-abrir-modal-img-not-checked" v-if="settingsLoaded && (!settings.integracao.fiducia || !settings.integracao.fiducia.enabled)"/>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 offset-1">
                  <div class="row align-items-center integracao-botao-abrir-modal" @click="$router.push('settings/clicksign')">
                    <div class="col-md-9">
                      <img src="@/assets/images/clicksign_logo.png" class="integracao-botao-abrir-modal-logo" />
                    </div>
                    <div class="col-md-3 text-right" style="margin-top: 0 !important;">
                      <img src="@/assets/images/check.png" class="integracao-botao-abrir-modal-img-checked" v-if="settingsLoaded && settings.integracao.clicksign && settings.integracao.clicksign.enabled"/>
                      <img src="@/assets/images/arrow-right.png" class="integracao-botao-abrir-modal-img-not-checked" v-if="settingsLoaded && (!settings.integracao.clicksign || !settings.integracao.clicksign.enabled)"/>
                    </div>
                  </div>
                </div>
              </div>
          </zz-tab>
          <zz-tab id="email" name="E-mail">

              <div class="row">
                <div class="col-md-3 mt-3">
                    <input
                        name="email_habilitar"
                        ref="email_habilitar"
                        type="checkbox"
                        v-model="settings.emails.enabled"
                    />
                    <label
                        for="email_habilitar"
                        style="margin-left: 5px;"
                        @click="checkHabilitarEmail"
                    >
                        HABILITAR ENVIO DE E-MAILS
                    </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 mt-3">
                  <base-input
                    label="Host"
                    placeholder="Informe o host"
                    v-model="settings.emails.host"
                  >
                  </base-input>
                </div>
                <div class="col-md-2 mt-3">
                  <base-input
                    label="Porta"
                    placeholder="Informe a porta"
                    v-model="settings.emails.porta"
                    v-mask="'####'"
                  >
                  </base-input>
                </div>
                <div class="col-md-3 mt-3">
                  <base-input
                    label="Usuário"
                    placeholder="Informe o usuário"
                    v-model="settings.emails.usuario"
                  >
                  </base-input>
                </div>
                <div class="col-md-3 mt-3">
                  <base-input
                    label="Senha"
                    placeholder="Informe a senha"
                    v-model="settings.emails.senha"
                  >
                  </base-input>
                </div>
                <div class="col-md-1 mt-3">
                  <div class="form-group">
                    <label class="control-label"> Secure </label>
                    <input
                      class="form-control"
                      style="width: 20px; height: 20px; margin-left: 5px; margin-top: 10px;"
                      ref="email_secure"
                      type="checkbox"
                      v-model="settings.emails.secure"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 mt-3">
                  <base-input
                    label="'From' dos e-mails"
                    placeholder="Informe aqui"
                    v-model="settings.emails.from"
                  >
                  </base-input>
                </div>
                <div class="col-md-4 mt-3">
                  <base-input
                    label="Título dos e-mails"
                    placeholder="Informe o título"
                    v-model="settings.emails.titulo"
                  >
                  </base-input>
                </div>
                <div class="col-md-4 mt-3">
                  <base-input
                    label="Rodapé dos e-mails"
                    placeholder="Informe o rodapé"
                    v-model="settings.emails.rodape"
                  >
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 mt-3">
                  <div class="form-group">
                    <label class="control-label"> Aviso acima do Rodapé </label>
                    <textarea
                      type="text"
                      cols="100"
                      rows="5"
                      class="form-control"
                      placeholder="Informe o aviso"
                      style="border: 1px solid #ccc; border-radius: 10px"
                      v-model="settings.emails.aviso_acima_rodape"
                    >
                    </textarea>
                  </div>
                </div>
                <div class="col-md-2 mt-3">
                  <base-input
                    label="Cor do e-mail (hex)"
                    placeholder="Ex: #288cf7"
                    v-model="settings.emails.cor"
                  >
                  </base-input>
                </div>
                <div class="col-md-2 mt-3">
                  <base-input
                    label="Cor da fonte (hex)"
                    placeholder="Ex: #ffffff"
                    v-model="settings.emails.cor_fonte"
                  >
                  </base-input>
                </div>
              </div>
            
          </zz-tab>
          <zz-tab id="utilitarios" name="Utilitários">

              <button type="button" class="btn btn-sm mt-3" @click="integrarContatosCRM">
                Integrar Contatos com CRM
              </button>
            
          </zz-tab>
          <zz-tab id="funcionalidades" name="Funcionalidades">

              <div class="row">
                <div class="col-md-12">

                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr style="background-color: rgba(34, 42, 66, 0.05);">
                        <th>Funcionalidade</th>
                        <th style="text-align: center">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="2" style="text-align: center; font-size: 12px;"><b>USUÁRIOS</b></td>
                      </tr>
                      <tr>
                        <td>
                          Desativar Usuários sem Transações
                        </td>
                        <td style="text-align: center;">
                          <div class="row">
                            <div style="padding-top: 2px;">
                              <input
                                name="desativar_usuario_sem_transacao"
                                ref="desativar_usuario_sem_transacao"
                                type="checkbox"
                                v-model="settings._features.desativar_usuario_sem_transacao"
                                style="cursor: pointer;"
                              />
                            </div>
                            <div style="margin-left: 5px;">
                              <label
                                for="desativar_usuario_sem_transacao"
                                data-type="permitir"
                                @click="funcionalidadesCheck"
                              >
                                Habilitar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="text-align: center; font-size: 12px;"><b>OFERTAS</b></td>
                      </tr>
                      <tr>
                        <td>
                          Criar pelo Usuário
                        </td>
                        <td style="text-align: center;">
                          <div class="row">
                            <div style="padding-top: 2px;">
                              <input
                                name="criar_oferta_pelo_usuario"
                                ref="criar_oferta_pelo_usuario"
                                type="checkbox"
                                v-model="settings._features.criar_oferta_pelo_usuario"
                                style="cursor: pointer;"
                              />
                            </div>
                            <div style="margin-left: 5px;">
                              <label
                                for="criar_oferta_pelo_usuario"
                                data-type="permitir"
                                @click="funcionalidadesCheck"
                              >
                                Permitir
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" style="text-align: center; font-size: 12px;"><b>RELATÓRIOS</b></td>
                      </tr>
                      <tr>
                        <td>
                          Relatório Anual CVM
                        </td>
                        <td style="text-align: center;">
                          <div class="row">
                            <div style="padding-top: 2px;">
                              <input
                                name="visualizar_relatorios_cvm"
                                ref="visualizar_relatorios_cvm"
                                type="checkbox"
                                v-model="settings._features.visualizar_relatorios_cvm"
                                style="cursor: pointer;"
                              />
                            </div>
                            <div style="margin-left: 5px;">
                              <label
                                for="visualizar_relatorios_cvm"
                                data-type="permitir"
                                @click="funcionalidadesCheck"
                              >
                                Visualizar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Relatório de Vencimentos CCBs
                        </td>
                        <td style="text-align: center;">
                          <div class="row">
                            <div style="padding-top: 2px;">
                              <input
                                name="visualizar_relatorios_vencimento_ccbs"
                                ref="visualizar_relatorios_vencimento_ccbs"
                                type="checkbox"
                                v-model="settings._features.visualizar_relatorios_vencimento_ccbs"
                                style="cursor: pointer;"
                              />
                            </div>
                            <div style="margin-left: 5px;">
                              <label
                                for="visualizar_relatorios_vencimento_ccbs"
                                data-type="permitir"
                                @click="funcionalidadesCheck"
                              >
                                Visualizar
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            
          </zz-tab>
        </zz-tabs>
      </div>
    </form>
    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>

<style>
.modal-close-button {
  background-color: rgb(255, 255, 255);
  border: 1px solid #aaa;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  color: #888;
}
.custom-modal-card {
  background-color: #fff !important;
  max-width: 1024px !important;
}
.custom-modal-login-form {
  max-width: 1024px;
}
.forma-pagamento-configuracoes {
  padding-top: 0 !important;
}
.integracao-botao-abrir-modal {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 20px rgb(0 0 0 / 25%);
  height: 80px;
  cursor: pointer;
}
@media(max-width: 768px) {
  .integracao-botao-abrir-modal {
    margin: 30px !important;
  }
}
@media(max-width: 768px) {
  .integracao-botao-abrir-modal-logo {
    width: 120px;
  }
}
.integracao-botao-abrir-modal-img-checked {
  width: 20px;
  margin-top: -40px;
}
.integracao-botao-abrir-modal-img-not-checked {
  width: 20px;
}
.remove-margin-top-col-3 {
  margin-top: 0.5rem !important;
}
.custom-cursor-pointer {
  cursor: default !important;
}
.custom-table-checkbox-enabled {
  padding-top: 2px;
}
.custom-table-label-enabled {
  margin-left: 5px;
}
</style>