<script>
import bancarizadoresService from '@/services/Bancarizadores/bancarizadoresService';
import attachmentsService from '@/services/Attachments/attachmentsService'
import logsService from '@/services/Logs/logsService';
import pessoasService from '@/services/Pessoas/pessoasService';

import { collectionRef } from '@/firebase';

import {vueTopprogress} from 'vue-top-progress';
import VueFileAgent from 'vue-file-agent';
import Vue from 'vue';

import {
  EnderecoPadrao,
  BancarizadorIntegracaoID
} from '@/store/cwl_enums';

Vue.use(VueFileAgent);

export default {
  data() {
    return {
      object: {},
      
      // Busca endereço pelo cep
      cepForSearch: '',
      skipCepSearch: true,
      isLoadingEndereco: false,
      endereco: {},
      showAddressFields: false,

      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      tipos_bancarizadores: ['Integrado', 'Manual'],
      tipos_integracoes_bancarizadores: ['SOCINAL', 'FIDÚCIA'],

      list_sim_nao: [
        {
          value: false,
          label: "NÃO"
        },
        {
          value: true,
          label: "SIM"
        }
      ],

      // Imagem
      fileRecords: [],

      bancos: [],

      dados_bancarios: {
        banco: '',
        agencia_numero: '',
        agencia_digito: '',
        conta_numero: '',
        conta_digito: '',
      },
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    vueTopprogress,
  },
  mounted() {
    this.getBancos();
  },
  methods: {
    async add() {
      this.$refs.topProgress.start();

      this.object.endereco = this.endereco.rua;
      this.object.endereco_rua = this.endereco.rua;
      this.object.endereco_numero = this.endereco.numero;
      this.object.endereco_complemento = this.endereco.complemento;
      this.object.endereco_bairro = this.endereco.bairro;
      this.object.cidade = this.endereco.cidade;
      this.object.uf = this.endereco.estado;
      this.object.cep = this.cepForSearch;
      if ( this.endereco.numero.length > 0 ) {
        this.object.endereco = this.object.endereco + ", " + this.endereco.numero;
      }
      if ( this.endereco.complemento.length > 0 ) {
        this.object.endereco = this.object.endereco + ", " + this.endereco.complemento;
      }

      if ( this.object.tipo == "Manual" ) {
        this.object.integracao_id = BancarizadorIntegracaoID.Manual;
      }

      this.object.dados_bancarios = this.dados_bancarios;

      try {
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        logsService.createNewLog(logUser, '', 'Criar Bancarizador', '', this.object);
        if ( this.object.padrao.value ) {
          await bancarizadoresService.removerPadraoDeTodos();
        }
        const objCreated = await bancarizadoresService.add(this.object);

        const that = this;
        if (this.fileRecords.length > 0) {
          const file = this.fileRecords[0];
          await attachmentsService .addNewFinancingImageAttachment(
            file.file,
            file.file.name,
            'Bancarizadores',
            objCreated.id,
          )
          .then(async function (attachmentCreated) {
            const bucket = 'Bancarizadores/' + objCreated.id;
            that.object.logotipoURL = await attachmentsService.urlAttachment(
              attachmentCreated.ref.name,
              bucket,
            );
            await bancarizadoresService.update(objCreated.id, that.object);

            that.$refs.topProgress.done();
            that.$router.push('bancarizadores');

            that.$notify({
              title: 'Bancarizador cadastrado com sucesso!',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success',
              timeout: 3000,
            });
          });
        }
        else {
          this.$refs.topProgress.done();
          this.$router.push('bancarizadores');

          this.$notify({
            title: 'Bancarizador cadastrado com sucesso!',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
          });
        }
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          title: 'Erro ao cadastrar bancarizador, tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.object = {};
    },
    
    async changeCEP(cep) {
      const cepWithoutMask = cep
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cepWithoutMask.length == 8 && !this.skipCepSearch) {
        if (!this.isLoadingEndereco) {
          this.isLoadingEndereco = true;
          this.endereco = Object.assign({}, EnderecoPadrao);

          const enderecoFound = await pessoasService.searchAddressByZipcode(
            cep,
          );
          if (enderecoFound) {
            this.endereco = enderecoFound;
          }

          this.object.endereco = this.endereco.rua;
          this.object.endereco_numero = this.endereco.numero;
          this.object.endereco_complemento = this.endereco.complemento;
          this.object.cidade = this.endereco.cidade;
          this.object.uf = this.endereco.estado;

          this.showAddressFields = true;
          this.isLoadingEndereco = false;
        }
      } else if (!this.isLoadingEndereco && !this.skipCepSearch) {
        this.endereco = {};
        this.showAddressFields = false;
      }
      this.skipCepSearch = false;
    },

    async getBancos() {
      const that = this;

      await collectionRef('banks')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((banco) => {
            const obj = banco.data();
            obj.id = banco.id;
            that.bancos.push(obj);
          });
        })
        .catch((error) => console.error('error', error));
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="add()">
        <h5 slot="header" class="title">
          <router-link to="/bancarizadores" class="fa fa-arrow-left"></router-link>
          Adicionar Bancarizador
        </h5>

        <div class="row">
          <div class="col-md-3 mt-3">
            <base-input
              label="Nome*"
              placeholder="Informe o nome"
              v-model="object.name"
              required
            >
            </base-input>
          </div>

          <div class="col-md-3 mt-3">
            <base-input required label="Tipo*">
              <v-select
                class="style-chooser"
                :options="tipos_bancarizadores"
                v-model="object.tipo"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
          <div class="col-md-3 mt-3" v-if="object.tipo == 'Integrado'">
            <base-input required label="Integração*">
              <v-select
                class="style-chooser"
                :options="tipos_integracoes_bancarizadores"
                v-model="object.integracao_id"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input required label="Bancarizador Padrão*">
              <v-select
                label="label"
                class="style-chooser"
                :options="list_sim_nao"
                v-model="object.padrao"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Logotipo (tamanho sugerido: 596 x 186)*</label>
            <VueFileAgent
              v-model="fileRecords"
              :multiple="false"
              :deletable="false"
              :meta="false"
              :accept="'image/*'"
              :maxSize="'5MB'"
              :helpText="'Arraste uma imagem aqui'"
              :errorText="{
                type: 'Por favor, selecione uma imagem',
                size: 'A imagem não pode ter mais de 5MB',
              }"
            ></VueFileAgent>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 mt-3">
            <base-input
              label="Razão Social*"
              placeholder="Informe a razão social"
              v-model="object.razao_social"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="CNPJ*"
              placeholder="Informe o CNPJ"
              v-model="object.cnpj"
              v-mask="['##.###.###/####-##']"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="E-mail*"
              placeholder="Informe e-mail"
              v-model="object.email"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Natureza*"
              placeholder="Informe a natureza"
              v-model="object.natureza"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Praça Pagamento*"
              placeholder="Informe a praça de pagamento"
              v-model="object.praca_pagamento"
              required
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="CEP*"
              v-model="cepForSearch"
              placeholder="Informe o CEP do endereço"
              v-mask="['##.###-###']"
              v-on:input="changeCEP"
              required
            ></base-input>
            <label v-if="isLoadingEndereco" style="cursor: default"
              ><i>Pesquisando...</i></label
            >
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Endereço*"
              placeholder="Informe o endereço"
              v-model="endereco.rua"
              required
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Número*"
              placeholder="Informe o número do endereço"
              v-model="endereco.numero"
              required
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Complemento"
              placeholder="Informe o complemento do endereço"
              v-model="endereco.complemento"
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Bairro"
              placeholder="Informe o bairro do endereço"
              v-model="endereco.bairro"
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="Cidade*"
              placeholder="Informe a cidade"
              v-model="endereco.cidade"
              required
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input
              label="UF*"
              placeholder="Informe o UF"
              v-model="endereco.estado"
              required
              v-if="this.showAddressFields"
            >
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 mt-3">
            <base-input required label="Banco*">
              <v-select
                label="name"
                class="style-chooser"
                :options="bancos"
                v-model="dados_bancarios.banco"
                :components="{ Deselect }"
                required
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrado</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>
          <div class="col-md-3 mt-3">
            <base-input required label="Subconta Safe2Pay*">
              <v-select
                label="label"
                class="style-chooser"
                :options="list_sim_nao"
                v-model="object.subconta_safe2pay"
                :components="{ Deselect }"
              ></v-select>
            </base-input>
          </div>
      </div>
      <div class="row">
        <div class="col-md-3 mt-3">
          <base-input
            label="Agência*"
            placeholder="Informe sua agência"
            v-mask="['####']"
            v-model="dados_bancarios.agencia_numero"
            required
          ></base-input>
        </div>
        <div class="col-md-3 mt-3">
          <base-input
            label="Dígito da agência*"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.agencia_digito"
            required
          ></base-input>
        </div>
        <div class="col-md-3 mt-3">
          <base-input
            label="Conta*"
            placeholder="Informe o número da sua conta"
            v-mask="['####################']"
            v-model="dados_bancarios.conta_numero"
            required
          ></base-input>
        </div>
        <div class="col-md-3 mt-3">
          <base-input
            label="Dígito da conta*"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.conta_digito"
            required
          ></base-input>
        </div>
      </div>

        <button type="submit" class="btn btn-sm">Adicionar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
