var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-user"},[_c('card',[(_vm.loading)?_c('double-bounce'):_vm._e(),_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Registro de "+_vm._s(_vm.$t('social_transformation.title'))+" ")]),(!_vm.loading && _vm.value.status === 'draft')?_c('div',[_c('address-form',{attrs:{"title":"Preencha seu endereço residencial","initialValue":_vm.value.address,"error":_vm.$v.value.address.$invalid ? 'Informe seu endereço.' : ''},on:{"change":_vm.handleAddress}}),_c('company-identification-form',{attrs:{"title":"Preencha os dados de identificação de sua empresa","initialValue":_vm.value.company.identification,"error":_vm.$v.value.company.identification.$invalid
            ? 'Informe os dados da empresa.'
            : ''},on:{"change":_vm.handleCompanyIdentification}}),(_vm.value.company.identification.identificationNumber)?_c('address-form',{attrs:{"title":"Preencha o endereço de sua empresa","initialValue":_vm.value.company.address,"error":_vm.$v.value.company.address.$invalid
            ? 'Informe o endereço da empresa.'
            : ''},on:{"change":_vm.handleCompanyAddress}}):_vm._e(),_c('company-partner-confirmation-form',{attrs:{"title":"Termos de Parceria de Serviço","initialValue":_vm.value.company.partner,"error":_vm.$v.value.company.partner.$invalid
            ? 'Você precisa estar de acordo.'
            : ''},on:{"change":_vm.handleCompanyPartner}}),(_vm.value.company.id)?_c('bank-account-information-form',{attrs:{"initialValue":_vm.value.company.bankAccount,"title":"Dados Bancários da Empresa","subtitle":"Para podermos realizar a remessa dos valores, informe a conta de pessoa jurídica do CNPJ de sua empresa.","error":_vm.$v.value.company.bankAccount.$invalid
            ? 'Informe os dados bancários da empresa.'
            : ''},on:{"change":_vm.handleCompanyBankAccount}}):_vm._e()],1):_vm._e(),(!_vm.loading && _vm.value.status === 'draft')?_c('card',[_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Concluir Registro")]),(_vm.$v.$invalid)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" Informe todos os dados requeridos. ")]):_vm._e(),_c('button',{staticClass:"btn btn-sm",attrs:{"disabled":_vm.$v.$invalid},on:{"click":_vm.send}},[_vm._v(" enviar o cadastro para análise ")])]):_vm._e(),(!_vm.loading && _vm.value.status === 'review_requested')?_c('card',[_c('h3',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Seus dados foram enviados para análise. ")]),_c('h4',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Você poderá criar campanhas como "+_vm._s(_vm.$t('social_transformation.title'))+" apenas após aprovação do cadastro ")])]):_vm._e(),(!_vm.loading && _vm.value.status === 'approved')?_c('card',{staticClass:"alert alert-success"},[_c('h3',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Você é um "+_vm._s(_vm.$t('social_transformation.title'))+" ! ")]),_c('h4',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Seu registro está aprovado e já pode criar campanhas como um Agente (API3). ")])]):_vm._e(),(!_vm.loading && _vm.value.status === 'disapproved')?_c('card',[_c('h3',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Seu registro não foi aprovado. Verifique seu e-mail ou entre em contato com a plataforma. ")]),_c('button',{staticClass:"btn btn-sm",on:{"click":function($event){return _vm.fixRegistration()}}},[_vm._v(" Ir para o registro ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }