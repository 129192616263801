<script>
import settingsService from '@/services/Settings/settingsService';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  data() {
    return {
      privateSettings: {
        integracao: {
            fiducia: {}
        }
      },
      publicSettings: {},
      list: [],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      settingsLoaded: false,
      isLoading: true
    };
  },
  mounted() {
    this.get();
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    DoubleBounce
  },
  methods: {
    async get() {
        this.publicSettings = await settingsService.getPublicSettings();
        this.privateSettings = await settingsService.getPrivateSettings();

        this.settingsLoaded = true;
        this.isLoading = false;
    },

    async save() {
      this.isLoading = true;

      try {
        
        await settingsService.updatePublicSettings(this.publicSettings);
        await settingsService.updatePrivateSettings(this.privateSettings);

        this.$router.push('/settings#integracao');

        this.isLoading = false;
        this.privateSettings = {};
        this.publicSettings = {};

        this.$notify({
            title: 'Integração realizada com sucesso!',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.$notify({
          title: 'Erro ao salvar integração, tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    checkHabilitarIntegracao() {
      this.$refs.integracao_fiducia_habilitar.click();
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="save()">
        <h5 slot="header" class="title">
          <router-link to="/settings#integracao" class="fa fa-arrow-left"></router-link>
          Voltar
        </h5>

        <div v-if="isLoading" class="loading-spinner">
            <double-bounce></double-bounce>
        </div>

        <div class="row" v-if="settingsLoaded && !isLoading">
            <div class="col-12">
                <div class="row">
                  <div class="col-md-3 mt-3">
                      <input
                          name="integracao_fiducia_habilitar"
                          ref="integracao_fiducia_habilitar"
                          type="checkbox"
                          v-model="privateSettings.integracao.fiducia.enabled"
                      />
                      <label
                          for="integracao_fiducia_habilitar"
                          style="margin-left: 5px;"
                          @click="checkHabilitarIntegracao"
                      >
                          HABILITAR INTEGRAÇÃO
                      </label>
                  </div>
                </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-md-6 mt-3">
                  <base-input
                    name="socinal_base_url"
                    label="Base URL"
                    placeholder="Informe a URL"
                    v-model="privateSettings.integracao.fiducia.base_url"
                  >
                  </base-input>
                </div>
                <div class="col-md-3 mt-3">
                  <base-input
                    name="fiducia_usuario_autenticacao"
                    label="Usuário Autenticação"
                    placeholder="Informe o usuário da autenticação"
                    v-model="privateSettings.integracao.fiducia.username"
                  >
                  </base-input>
                </div>
                <div class="col-md-3 mt-3">
                  <base-input
                    name="fiducia_senha_autenticacao"
                    label="Senha Autenticação"
                    placeholder="Informe a senha da autenticação"
                    v-model="privateSettings.integracao.fiducia.password"
                  >
                  </base-input>
                </div>
              </div>

                <button type="submit" class="btn btn-sm">Salvar</button>
            </div>
        </div>
      </form>
    </card>
  </div>
</template>
