import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  banksRef() {
    return firebase.firestore().collection('banks');
  },

  getAllBanks() {
    return withLog(async () => {
      const snapshot = await this.banksRef().orderBy('name', 'asc').get();
      return snapshot.docs.map((e) => ({
        ...e.data(),
        id: e.id,
      }));
    });
  },

  getByCode(code) {
    return withLog(() => this.banksRef().where('code', '==', code).get());
  },

  createNewBank(bank) {
    withLog(() => this.banksRef().add(bank));
  },

  deleteBank(bank) {
    withLog(() => this.banksRef().doc(bank).delete());
  },

  getBank(id) {
    return withLog(() => this.banksRef().doc(id).get());
  },

  updateBank(id, bank) {
    return withLog(() => this.banksRef().doc(id).update(bank));
  },
};
