import firebase from 'firebase';
import pessoasService from '@/services/Pessoas/pessoasService';

export default {
  pessoaRef(userid) {
    try {
      if (!userid) {
        throw 'userid is required';
      }
      return firebase.firestore().collection('pessoas').doc(userid);
    } catch (error) {
      console.error('pessoa ref error', error);
      throw error;
    }
  },

  async isSocialAgentByUserId(id) {
    return await pessoasService.hasPersona(id, 'social-transformation-agent');
  },

  async updateStatusByUserId(status, userid) {
    const ref = this.pessoaRef(userid);
    const snapshot = await ref.get();
    if (!snapshot.exists) {
      throw 'user data not found';
    }

    await ref.update({
      social_transformation_agent: {
        ...snapshot.data().social_transformation_agent,
        status: status || 'draft',
      },
    });
  },

  async getUsers() {
    try {
      const ref = firebase.firestore().collection('pessoas');
      const snapshot = await ref
        .where('social_transformation_agent.status', 'in', [
          'draft',
          'review_requested',
          'approved',
          'disapproved',
        ])
        .get();
      return (snapshot.docs || []).map((e) => e.data());
    } catch (error) {
      console.error('socialtransformation get users error', error);
      return [];
    }
  },
  async approveSocialAgent(userId) {
    try {
      await this.updateStatusByUserId('approved', userId);
      await pessoasService.addPersona(userId, 'social-transformation-agent');
    } catch (error) {
      console.error('approve social agent error', error);
    }
  },
  async disapproveSocialAgent(userId) {
    try {
      await this.updateStatusByUserId('disapproved', userId);
      await pessoasService.removePersona(userId, 'social-transformation-agent');
    } catch (error) {
      console.error('disapprove social agent error', error);
    }
  },
};
