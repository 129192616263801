<script>
import Vue from 'vue';

import axios from 'axios';
import { EnderecoPadrao } from '@/store/cwl_enums';
import pessoasService from '@/services/Pessoas/pessoasService';
import stateService from '@/services/States/statesService';
import { DoubleBounce } from 'vue-loading-spinner';
import vSelect from 'vue-select';

Vue.component('v-select', vSelect);

export default {
  name: 'address-form',
  props: {
    error: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Endereço Residencial',
    },
    onBack: {
      type: Function,
      required: false,
    },
    initialValue: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      address: { ...EnderecoPadrao, ...this.initialValue },
      states: [],
      cities: [],
    };
  },
  computed: {},
  components: {
    DoubleBounce,
  },
  methods: {
    handleChange() {
      console.debug('changed');
      this.$emit('change', this.address);
    },
    async searchCEP() {
      if (!this.address.cep) {
        return;
      }
      this.loading = true;
      try {
        const numberPattern = /\d+/g;
        const cep = this.address.cep.match(numberPattern).join('');
        if (cep.length == 8) {
          const address = await pessoasService.searchAddressByZipcode(cep);
          if (address) {
            const { rua, numero, complemento, bairro, cidade, estado, pais } =
              address;
            this.address = {
              ...this.address,
              rua: rua || this.address.rua,
              numero: numero || this.address.numero,
              complemento: complemento || this.address.complemento,
              bairro: bairro || this.address.bairro,
              cidade,
              estado,
              pais: pais || this.address.pais,
            };
          }
        }
      } catch (error) {
        console.error('error on seach address', error);
      }

      this.loading = false;
    },
    async loadCitiesByState(state) {
      this.cities = [];
      this.address.cidade = undefined;

      if (!state) {
        return;
      }

      this.address.estado = state.uf;

      this.cities = await stateService.getCitiesByState(state.uf);

      this.loading = false;
    },
  },
  async mounted() {
    try {
      if (this.readonly) return;
      this.states = await stateService.getAllStates();
    } catch (error) {
      console.error('error on load address form', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<template>
  <card>
    <double-bounce v-if="loading"></double-bounce>
    <form
      @submit.prevent="handleChange()"
      @change="handleChange()"
      v-if="!loading"
    >
      <h6 slot="header" class="title">
        <a v-if="onBack" v-on:click="onBack()" class="fa fa-arrow-left"></a>
        {{ title }}
      </h6>

      <div style="color: red" v-if="error">{{ error }}</div>

      <div class="row">
        <div class="col-md-3">
          <base-input
            label="CEP*"
            v-model="address.cep"
            placeholder="Informe o CEP do endereço"
            v-mask="['##.###-###']"
            v-on:change="searchCEP"
            required
            :disabled="readonly"
          ></base-input>
        </div>
        <div class="col-md-3">
          <base-input
            label="Logradouro*"
            v-model="address.rua"
            placeholder="Informe o logradouro do endereço"
            required
            :disabled="readonly"
          ></base-input>
        </div>
        <div class="col-md-3">
          <base-input
            label="Número*"
            v-model="address.numero"
            placeholder="Informe o número do endereço"
            required
            :disabled="readonly"
          ></base-input>
        </div>
        <div class="col-md-3">
          <base-input
            label="Complemento"
            v-model="address.complemento"
            placeholder="Informe o complemento do endereço"
            :disabled="readonly"
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <base-input
            label="Bairro*"
            v-model="address.bairro"
            placeholder="Informe o bairro do endereço"
            required
            :disabled="readonly"
          ></base-input>
        </div>
        <div class="col-md-3">
          <base-input required label="Estado*">
            <v-select
              label="uf"
              class="style-chooser"
              :options="states"
              v-model="address.estado"
              @input="loadCitiesByState"
              :disabled="readonly"
              v-on:input="handleChange"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <em style="opacity: 0.9; color: #555"
                    ><b>{{ search }}</b> não encontrada</em
                  >
                </template>
              </template>
            </v-select>
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input required label="Cidade*">
            <v-select
              label="nome"
              class="style-chooser"
              :options="cities"
              v-model="address.cidade"
              :disabled="readonly"
              v-on:input="handleChange"
            >
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                ></div>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <em style="opacity: 0.9; color: #555"
                    ><b>{{ search }}</b> não encontrada</em
                  >
                </template>
                <em style="opacity: 0.9; color: #555" v-else
                  >Selecione um <b>estado</b></em
                >
              </template>
            </v-select>
          </base-input>
        </div>
      </div>
    </form>
  </card>
</template>
