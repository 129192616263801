export default {
  componentUpdated: function (el, binding) {
    const tags = ['input', 'button', 'textarea', 'select'];
    tags.forEach((tagName) => {
      const nodes = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].disabled = true;
        nodes[i].tabIndex = -1;
      }
    });
  },
};
