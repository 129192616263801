<script>

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import settingsService from '@/services/Settings/settingsService';

import AttachmentTable from '@/components/Attachment/AttachmentTable';
import EmptyTermsOfUse from '@/components/Notifications/TermsOfUse/EmptyTermsOfUse';
import GetTermsOfUseError from '@/components/Notifications/TermsOfUse/GetTermsOfUseError';
import DeleteTermOfUseError from '@/components/Notifications/TermsOfUse/DeleteTermOfUseError';
import DeleteTermOfUseSuccess from '@/components/Notifications/TermsOfUse/DeleteTermOfUseSuccess';
import DownloadsError from '@/components/Notifications/Downloads/DownloadsError';

import attachmentsService from '@/services/Attachments/attachmentsService';
import investorsService from '@/services/Investors/investorsService';

import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';

const tableColumns = ['Nome'];

export default {
  components: {
    vueTopprogress,
    DoubleBounce,
    AttachmentTable,
    quillEditor,
  },
  data() {
    return {
      submodalidade: process.env.VUE_APP_SUBTYPE,
      table: {
        title: 'Anexos',
        columns: [...tableColumns],
      },
      attachments: [],
      isLoading: true,

      content_contrato_padrao: '',
      content_contrato_ccb: '',
      content_contrato_ccb_endosso: '',
      content_contrato_ccb_endosso_complemento: '',
      content_termo_ciencia_risco: '',
      editorContratoPadraoOptions: {
        placeholder:
          'Digite aqui o contrato padrão da plataforma.',
      },
      editorTermoCienciaRiscoOptions: {
        placeholder:
          'Digite aqui o termo de ciência de risco da plataforma.',
      },
      editorContratoCCBOptions: {
        placeholder:
          'Digite aqui o contrato CCB da plataforma.',
      },
      editorContratoCCBEndossoOptions: {
        placeholder:
          'Digite aqui o endosso CCB da plataforma.',
      },
      editorContratoCCBEndossoComplementoOptions: {
        placeholder:
          'Digite aqui o complemento do endosso CCB da plataforma.',
      },
      settingsPublic: {
        terms: {}
      },
    };
  },
  mounted() {
    this.getAttachments();
    this.loadSettings();
  },
  methods: {
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onContratoCCBChange({ html }) {
      this.content_contrato_ccb = html;
      this.settingsPublic.terms.contrato_ccb = html;
    },
    onContratoCCBEndossoChange({ html }) {
      this.content_contrato_ccb_endosso = html;
      this.settingsPublic.terms.contrato_ccb_endosso = html;
    },
    onContratoCCBEndossoComplementoChange({ html }) {
      this.content_contrato_ccb_endosso_complemento = html;
      this.settingsPublic.terms.contrato_ccb_endosso_complemento = html;
    },
    onContratoPadraoChange({ html }) {
      this.content_contrato_padrao = html;
      this.settingsPublic.terms.contrato_padrao = html;
    },
    onTermoCienciaRiscoChange({ html }) {
      this.content_termo_ciencia_risco = html;
      this.settingsPublic.terms.termo_ciencia_risco = html;
    },
    async loadSettings() {
      this.settingsPublic = await settingsService.getPublicSettings();
    },
    async salvarContratos() {
      this.$refs.topProgress.start();
      try {
        await settingsService.updatePublicSettings(this.settingsPublic);
        this.$notify({
          title: "Contratos atualizados!",
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$refs.topProgress.done();
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
    async getAttachments() {
      this.attachments = [];
      this.$refs.topProgress.start();

      try {
        const attachments = await attachmentsService.getAttachments('InvestmentTerms');
        const attachmentsItems = await attachments.items;

        if ( attachmentsItems.length == 0 ) {
            this.$notify({
                component: EmptyTermsOfUse,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
            });
        }
        else {
            await attachmentsItems.map((item) => {
              this.attachments.push(item);
            });
        }
      } catch (e) {
        this.$notify({
          component: GetTermsOfUseError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;
      this.$refs.topProgress.done();
    },
    async deleteAttachments(preDeletedItem) {
      try {
        attachmentsService.deleteAttachment(preDeletedItem.item.name, 'InvestmentTerms');
        await investorsService.updateTermsOfUseAcceptedToFalse();

        this.getAttachments();

        this.$notify({
          component: DeleteTermOfUseSuccess,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.preDeletedItem = {};
      } catch (e) {
        this.$notify({
          component: DeleteTermOfUseError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    downloadItem(user) {
      try {
        attachmentsService.downloadAttachment(user.name, 'InvestmentTerms');
      } catch (e) {
        this.$notify({
          component: DownloadsError,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <card>
      <h4 class="card-title">
        {{ $t('contracts.title') }}
      </h4>

      <!-- Removido, pois para BACEN CCB não faz sentido -->
      <div class="row" v-if="submodalidade == '588'">
        
        <div class="col-md-12 mt-3">
          <b>{{ $t('contracts.defaultContractTitle') }}</b> <br />
          <label>{{ $t('contracts.variables') }}</label> <br />

          <quill-editor
            ref="quillEditor"
            v-model="settingsPublic.terms.contrato_padrao"
            :options="editorContratoPadraoOptions"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onContratoPadraoChange($event)"
          />
        </div>

      </div>

      <div class="row" v-if="submodalidade == 'bacen'">
        
        <div class="col-md-12 mt-3">
          <b>{{ $t('contracts.CCBContractTitle') }}</b> <br />
          <label>{{ $t('contracts.variables_investidor') }}</label> <br />
          <label>{{ $t('contracts.variables_oferta') }}</label> <br />
          <label>{{ $t('contracts.variables_aporte') }}</label> <br />
          <label>{{ $t('contracts.variables_bancarizador') }}</label> <br />
          <label>{{ $t('contracts.variables_outros') }}</label> <br />

          <quill-editor
              ref="quillEditor"
              v-model="settingsPublic.terms.contrato_ccb"
              :options="editorContratoCCBOptions"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onContratoCCBChange($event)"
            />
        </div>
        
      </div>

      <div class="row" v-if="submodalidade == 'bacen'">
        
        <div class="col-md-12 mt-3">
          <b>{{ $t('contracts.CCBContractEndossoTitle') }}</b> <br />
          <label>{{ $t('contracts.variables_investidor') }}</label> <br />
          <label>{{ $t('contracts.variables_oferta') }}</label> <br />
          <label>{{ $t('contracts.variables_aporte') }}</label> <br />
          <label>{{ $t('contracts.variables_bancarizador') }}</label> <br />
          <label>{{ $t('contracts.variables_outros') }}</label> <br />

          <quill-editor
              ref="quillEditor"
              v-model="settingsPublic.terms.contrato_ccb_endosso"
              :options="editorContratoCCBEndossoOptions"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onContratoCCBEndossoChange($event)"
            />
        </div>
        
      </div>

      <div class="row" v-if="submodalidade == 'bacen'">
        
        <div class="col-md-12 mt-3">
          <b>{{ $t('contracts.CCBContractEndossoComplementoTitle') }}</b> <br />
          <label>{{ $t('contracts.variables_investidor') }}</label> <br />
          <label>{{ $t('contracts.variables_oferta') }}</label> <br />
          <label>{{ $t('contracts.variables_aporte') }}</label> <br />
          <label>{{ $t('contracts.variables_bancarizador') }}</label> <br />
          <label>{{ $t('contracts.variables_outros') }}</label> <br />

          <quill-editor
              ref="quillEditor"
              v-model="settingsPublic.terms.contrato_ccb_endosso_complemento"
              :options="editorContratoCCBEndossoComplementoOptions"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onContratoCCBEndossoComplementoChange($event)"
            />
        </div>
        
      </div>

      <div class="row">
        
        <div class="col-md-12 mt-3">
          <b>{{ $t('contracts.riskTermTitle') }}</b> <br />
          <label>{{ $t('contracts.variables_investidor') }}</label> <br />
          <label>{{ $t('contracts.variables_oferta') }}</label> <br />
          <label>{{ $t('contracts.variables_aporte') }}</label> <br />
          <label>{{ $t('contracts.variables_bancarizador') }}</label> <br />
          <label>{{ $t('contracts.variables_outros') }}</label> <br />

          <quill-editor
              ref="quillEditor"
              v-model="settingsPublic.terms.termo_ciencia_risco"
              :options="editorTermoCienciaRiscoOptions"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onTermoCienciaRiscoChange($event)"
            />
        </div>
        
      </div>

      <button
        type="button"
        class="btn btn-sm mt-3"
        @click="salvarContratos"
      >
        {{ $t('contracts.saveContracts') }}
      </button>
    </card>

    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Anexo'"
          :routerType="'term-of-use'"
        >
          <div class="table-responsive">
            <attachment-table
              :data="attachments"
              :columns="table.columns"
              :hasActions="true"
              :itemType="'Anexo'"
              :routerType="'term-of-use'"
              :isNonEditable="true"
              :isDownloadable="true"
              thead-classes="text-primary"
              @deleteItem="deleteAttachments"
              @downloadItem="downloadItem"
            ></attachment-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
