import firebase from 'firebase';
import { currentUserId } from '../../firebase';

export default {
  pessoaRef() {
    try {
      return firebase.firestore().collection('pessoas');
    } catch (error) {
      console.error('pessoa ref error', error);
      throw error;
    }
  },

  async saveAddressByUserId(address, userid) {
    try {
      if (!userid) {
        throw 'userid is required';
      }
      var pessoaRef = this.pessoaRef().doc(userid);

      Object.keys(address).forEach(
        (key) =>
          (address[key] =
            typeof address[key] === 'undefined' ? '' : address[key]),
      );

      await pessoaRef.update({ endereco: address });
    } catch (error) {
      console.error('save address social transformation error', error);
    }
  },

  async saveCurrentUserAddress(address) {
    try {
      var uid = currentUserId();
      await this.saveAddressByUserId(address, uid);
    } catch (error) {
      console.error('save address social transformation error', error);
    }
  },
};
