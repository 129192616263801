<script>
import banksService from '@/services/Banks/banksService';
import SuccessNotification from '@/components/Notifications/General/SuccessNotification';
import ErrorRegisterNotification from '@/components/Notifications/General/ErrorRegisterNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      bank: {},
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  components: {
    vueTopprogress,
  },
  methods: {
    async addNewBank() {
      this.$refs.topProgress.start();

      try {
        const bankFound = await banksService.getByCode(this.bank.code);
        if ( bankFound.size > 0 ) {
          this.$notify({
            title: 'Ooopss',
            message: 'Desculpe, banco já existente.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
          this.$refs.topProgress.done();
          return;
        }
        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        logsService.createNewLog(logUser, '', 'Criar Banco', '', this.bank);
        await banksService.createNewBank(this.bank);

        this.$refs.topProgress.done();

        this.$router.push('banks');

        this.$notify({
          component: SuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$refs.topProgress.done();
        this.$notify({
          component: ErrorRegisterNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.bank = {};
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="addNewBank()">
        <h5 slot="header" class="title">
          <router-link to="/banks" class="fa fa-arrow-left"></router-link>
          Adicionar Banco
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
          <base-input
              label="Código*"
              placeholder="Código"
              v-mask="['########']"
              v-model="bank.code"
              required
            ></base-input>
          </div>

          <div class="col-md-4 pl-md-1">
            <base-input
              label="Nome do Banco*"
              placeholder="Nome do Banco"
              v-model="bank.name"
              required
            >
            </base-input>
          </div>
        </div>

        <button type="submit" class="btn btn-sm">Adicionar</button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
