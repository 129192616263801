import Vue from 'vue';
import InstancePlugin from '@/plugins/InstancePlugin';
Vue.use(InstancePlugin);

import BaseInput from './Inputs/BaseInput.vue';

import BaseCheckbox from './BaseCheckbox.vue';
import BaseRadio from './BaseRadio.vue';
import BaseDropdown from './BaseDropdown.vue';
import BaseTable from './BaseTable.vue';
import BaseButton from './BaseButton';
import BaseAlert from './BaseAlert';
import BaseNav from './BaseNav';
import Modal from './Modal';
import CloseButton from './CloseButton';

import Card from './Cards/Card.vue';
import StatsCard from './Cards/StatsCard.vue';

const SidebarPlugin = Vue.prototype.$CWLGetComponentInstance('SidebarPlugin/index');

export {
  BaseInput,
  Card,
  Modal,
  CloseButton,
  StatsCard,
  BaseTable,
  BaseCheckbox,
  BaseRadio,
  BaseDropdown,
  BaseButton,
  BaseAlert,
  SidebarPlugin,
  BaseNav,
};
