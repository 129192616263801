<script>
import ConfirmationModal from '@/components/ConfirmationModal';
import Vue from 'vue';
import moment from 'moment';
import XLSX from 'xlsx';

import { SlideYUpTransition } from 'vue2-transitions';
import HelperPlugin from '@/plugins/HelperPlugin';
import { CWLStatusCampanha } from '@/store/cwl_enums';

import ordersService from '@/services/Orders/ordersService';
import hubspotService from '@/services/HubSpot/hubspotService';

import store from '@/store/index';

import { formatCurrency } from '@/formatter/NumberFormatter';

Vue.use(HelperPlugin);

export default {
  name: 'campaign-statement-table',
  props: {
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    financing: {
      type: Object,
      default: () => undefined
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConfirmationModal,
    SlideYUpTransition,
  },
  data() {
    return {
      page: 1,
      perPage: 1024,
      pages: [],
      showModal: false,
      searchBar: '',
      campaignStatus: CWLStatusCampanha,
      selectedStatus: '',
      items: [],
      items_exportar: [],
      selectedIndex: '',
      itemObservation: '',
      totalizador: {
        total_aporte: 0,
        total_pendente: 0,
        total_reservado: 0,
        total_confirmado: 0,
        total_devolucao_pendente: 0,
        total_devolucao_realizada: 0,
      },
      showSolicitarDevolucao: false,
      showInformarPagamento: false,
      showDesfazerPagamento: false,
      item_modal: {},
      modalidade: process.env.VUE_APP_TYPE,
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    totalizerBoleto() {
      return this.displayedData.reduce(
        (acc, curr) =>
          acc +
          (curr.tipo_pagamento == 'Boleto'
            ? curr.valor_aporte_sem_formatacao
            : 0),
        0,
      );
    },
    totalizerCartaoCredito() {
      return this.displayedData.reduce(
        (acc, curr) =>
          acc +
          (curr.tipo_pagamento == 'CartaoCredito'
            ? curr.valor_aporte_sem_formatacao
            : 0),
        0,
      );
    },
    filteredData() {
      this.items = [];
      this.items_exportar = [];

      this.totalizador = {
        total_aporte: 0,
        total_pendente: 0,
        total_reservado: 0,
        total_confirmado: 0,
        total_vencido: 0,
        total_devolucao_pendente: 0,
        total_devolucao_realizada: 0,
      };

      const filteredItems = this.data.filter((obj) => {
        return (obj.status ? obj.status : '')
          .toLowerCase()
          .includes(this.selectedStatus);
      });

      filteredItems.map((item) => {
        const valorTotal = (item.item.value || 0) * (item.item.quantity || 1);
        const valorTotalFormatted = formatCurrency(valorTotal);
        const zeroFormatted = 'R$ 0,00';

        this.totalizador.total_aporte += valorTotal;
        this.totalizador.total_pendente +=
          item.status === 'Pendente' ? valorTotal : 0;
        this.totalizador.total_reservado +=
          item.status === 'Reservado' ? valorTotal : 0;
        this.totalizador.total_confirmado +=
          item.status === 'Pago' ? valorTotal : 0;
        this.totalizador.total_vencido +=
          item.status === 'Vencido' ? valorTotal : 0;
        this.totalizador.total_devolucao_pendente +=
          item.status === 'DevoluçãoPendente' ? valorTotal : 0;
        this.totalizador.total_devolucao_realizada +=
          item.status === 'DevoluçãoRealizada' ? valorTotal : 0;

        this.items.push({
          id: item.id,
          investidor: item.pessoa.nome,
          codigo_transacao: item.transacao_id,
          data_aporte: moment(item.order_date).format('DD/MM/YYYY'),
          valor_aporte: valorTotalFormatted,
          tipo_pagamento: item.tipo_pagamento,
          quantidade_cotas: item.item.quantity || 1,
          // valor_reservado: this.$options.filters.currency(item.status === 'Reservado' ? (item.item.value * item.item.quantity) : 0),
          // valor_reservado_sem_formatacao: item.status === 'Reservado' ? (item.item.value * item.item.quantity) : 0,
          valor_pendente:
            item.status === 'Pendente' ? valorTotalFormatted : zeroFormatted,
          valor_confirmado:
            item.status === 'Pago' ? valorTotalFormatted : zeroFormatted,
          valor_vencido:
            item.status === 'Vencido' ? valorTotalFormatted : zeroFormatted,
          valor_devolucao_pendente:
            item.status === 'DevoluçãoPendente'
              ? valorTotalFormatted
              : zeroFormatted,
          valor_devolucao_realizada:
            item.status === 'DevoluçãoRealizada'
              ? valorTotalFormatted
              : zeroFormatted,
          devolucao_observacao: item.devolucao_observacao
            ? item.devolucao_observacao
            : '',
          status: item.status,
          pagamento_manual: item.pagamento_manual
        });

        if (this.modalidade == 'doacao') {
          this.items_exportar.push({
            id: item.id,
            doador: item.pessoa.nome,
            codigo_transacao: item.transacao_id,
            data_aporte: moment(item.order_date).format('DD/MM/YYYY'),
            valor_aporte: valorTotalFormatted,
            tipo_pagamento: item.tipo_pagamento,
            quantidade_cotas: item.item.quantity || 1,
            valor_pendente:
              item.status === 'Pendente' ? valorTotalFormatted : zeroFormatted,
            valor_confirmado:
              item.status === 'Pago' ? valorTotalFormatted : zeroFormatted,
            valor_vencido:
              item.status === 'Vencido' ? valorTotalFormatted : zeroFormatted,
            valor_devolucao_pendente:
              item.status === 'DevoluçãoPendente'
                ? valorTotalFormatted
                : zeroFormatted,
            valor_devolucao_realizada:
              item.status === 'DevoluçãoRealizada'
                ? valorTotalFormatted
                : zeroFormatted,
            devolucao_observacao: item.devolucao_observacao
              ? item.devolucao_observacao
              : '',
            status: item.status,
          });
        } else {
          this.items_exportar.push({
            id: item.id,
            investidor: item.pessoa.nome,
            codigo_transacao: item.transacao_id,
            data_aporte: moment(item.order_date).format('DD/MM/YYYY'),
            valor_aporte: valorTotalFormatted,
            tipo_pagamento: item.tipo_pagamento,
            quantidade_cotas: item.item.quantity || 1,
            valor_pendente:
              item.status === 'Pendente' ? valorTotalFormatted : zeroFormatted,
            valor_confirmado:
              item.status === 'Pago' ? valorTotalFormatted : zeroFormatted,
            valor_vencido:
              item.status === 'Vencido' ? valorTotalFormatted : zeroFormatted,
            valor_devolucao_pendente:
              item.status === 'DevoluçãoPendente'
                ? valorTotalFormatted
                : zeroFormatted,
            valor_devolucao_realizada:
              item.status === 'DevoluçãoRealizada'
                ? valorTotalFormatted
                : zeroFormatted,
            devolucao_observacao: item.devolucao_observacao
              ? item.devolucao_observacao
              : '',
            status: item.status,
          });
        }
      });

      return this.items;
    },
  },
  methods: {
    formatDate(date) {
      return this.$CWLFormatDate(date);
    },

    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },

    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.filteredData.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = page * perPage - perPage;
      const to = page * perPage;

      return data.slice(from, to);
    },

    closeModal() {
      this.showPublicarModal = false;
      this.showEnviarParaAnaliseModal = false;
    },
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    download() {
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'cwl.xlsx');
    },
    downloadCSV() {
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'cwl.csv');
    },
    openModalSolicitarDevolucao(item) {
      this.item_modal = item;
      this.showSolicitarDevolucao = true;
    },
    openModalDesfazerPagamento(item) {
      this.item_modal = item;
      this.showDesfazerPagamento = true;
    },
    openModalInformarPagamento(item) {
      this.item_modal = item;
      this.showInformarPagamento = true;
    },
    async salvarSolicitacaoDevolucao() {
      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.devolucao_observacao = this.item_modal.devolucao_observacao;
      order.status = 'DevoluçãoPendente';
      await ordersService.update(order.id, order);

      await hubspotService.atualizarContatoAPI(order.pessoa.id);

      this.selectedIndex = '';
      this.closeModal();
    },
    async salvarDevolucaoRealizada() {
      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.status = 'DevoluçãoRealizada';
      await ordersService.update(order.id, order);

      await hubspotService.atualizarContatoAPI(order.pessoa.id);

      this.selectedIndex = '';
      this.closeModal();
    },
    async salvarInformarPagamento() {
      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.status = 'Pago';
      order.pagamento_manual = true;

      await ordersService.update(order.id, order);

      await hubspotService.atualizarValorUltimoInvestimentoRealizadoContatoAPI(order.pessoa.id, order.item.value);

      this.selectedIndex = '';
      this.closeModal();
    },
    async salvarDesfazerPagamento() {
      const orderFirebase = await ordersService.get(this.item_modal.id);
      var order = orderFirebase.data();
      order.id = orderFirebase.id;
      order.status = 'Pendente';

      await ordersService.update(order.id, order);

      await hubspotService.atualizarContatoAPI(order.pessoa.id);

      this.selectedIndex = '';
      this.closeModal();
    },
    closeModal() {
      this.item_modal = {};
      this.showSolicitarDevolucao = false;
      this.showInformarPagamento = false;
      this.showDesfazerPagamento = false;
      this.$emit('reloadData');
    },
  },
  watch: {
    filteredData() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <table class="table table-hover" :class="tableClass" ref="table">
      <thead class="thead-light">
        <tr>
          <slot name="columns">
            <th v-if="modalidade == 'equity'">Investidor</th>
            <th v-if="modalidade == 'doacao'">Doador</th>
            <th>Cód. Transação</th>
            <th>Data do aporte</th>
            <th>Valor aporte</th>
            <th>Tipo Pagamento</th>
            <th>Qtd de cotas</th>
            <th>Valor pendente</th>
            <!-- <th>Valor reservado</th> -->
            <th>Valor confirmado</th>
            <th>Valor vencido</th>
            <th>Pendente de devolução</th>
            <th>Devolução Realizada</th>
          </slot>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in displayedData" :key="index">
          <td style="max-width: 150px">{{ item.investidor }}</td>
          <td>{{ item.codigo_transacao }}</td>
          <td>{{ item.data_aporte }}</td>
          <td>{{ item.valor_aporte }}</td>
          <td>{{ item.tipo_pagamento }}</td>
          <td>{{ item.quantidade_cotas }}</td>
          <td>
            {{ item.valor_pendente }}
            <a
              href="#"
              v-if="
                item.pagamento_manual && item.status != 'Pendente' &&
                ( financing.status == 'em-andamento' || financing.status == 'removida-do-ar' ) &&
                ( item.ccb == undefined || item.ccb.importacao == undefined || item.ccb.importacao.operacao == undefined || item.ccb.importacao.status != 'importado' )
              "
              title="Desfazer Pagamento"
              @click="openModalDesfazerPagamento(item)"
            >
              <i class="tim-icons icon-coins"></i>
            </a>
          </td>
          <!-- <td>{{ item.valor_reservado }}</td> -->
          <td>
            {{ item.valor_confirmado }}
            <a
              href="#"
              v-if="
                item.status !== 'Pago' &&
                item.status !== 'DevoluçãoPendente' &&
                item.status !== 'DevoluçãoRealizada'
              "
              title="Informar Pagamento"
              @click="openModalInformarPagamento(item)"
            >
              <i class="tim-icons icon-coins"></i>
            </a>
          </td>
          <td>
            {{ item.valor_vencido }}
          </td>
          <td
            :title="
              item.status === 'DevoluçãoPendente'
                ? item.devolucao_observacao
                : ''
            "
          >
            {{ item.valor_devolucao_pendente }}
            <a
              href="#"
              v-if="item.status !== 'DevoluçãoRealizada'"
              title="Solicitar Devolução"
              @click="openModalSolicitarDevolucao(item)"
            >
              <i class="tim-icons icon-coins"></i>
            </a>
          </td>
          <td
            :title="
              item.status === 'DevoluçãoRealizada'
                ? item.devolucao_observacao
                : ''
            "
          >
            {{ item.valor_devolucao_realizada }}
          </td>
        </tr>
      </tbody>

      <tfoot class="thead-light">
        <tr>
          <th colspan="3" style="text-align: center">Totais</th>
          <th>{{ totalizador.total_aporte | currency }}</th>
          <th></th>
          <th></th>
          <th>{{ totalizador.total_pendente | currency }}</th>
          <!-- <th> {{ totalizador.total_reservado | currency }} </th> -->
          <th>{{ totalizador.total_confirmado | currency }}</th>
          <th>{{ totalizador.total_vencido | currency }}</th>
          <th>{{ totalizador.total_devolucao_pendente | currency }}</th>
          <th>{{ totalizador.total_devolucao_realizada | currency }}</th>
        </tr>
      </tfoot>
    </table>

    <div class="no-data" v-if="!displayedData.length && modalidade == 'doacao'">
      Não há aportes para esta campanha ainda :(
    </div>

    <div class="no-data" v-if="!displayedData.length && modalidade == 'equity'">
      Não há aportes para esta oferta ainda :(
    </div>

    <div
      class="totalizer"
      v-if="totalizerCartaoCredito"
      style="
        margin: 38px 0 -30px 0;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
      "
    >
      Total Cartão de Crédito: {{ totalizerCartaoCredito | currency }}
    </div>

    <div
      class="totalizer"
      v-if="totalizerBoleto"
      style="
        margin: 38px 0 -30px 0;
        display: flex;
        justify-content: flex-end;
        font-size: 14px; 
      "
    >
      Total Boleto: {{ totalizerBoleto | currency }}
    </div>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--" 
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <div class="export-button">
      <button
        class="btn btn-primary"
        @click="download"
        :disabled="!displayedData.length"
      >
        <i class="tim-icons icon-cloud-download-93"></i>
        Exportar para Excel
      </button>
      <button
        class="btn btn-primary"
        @click="downloadCSV"
        :disabled="!displayedData.length"
      >
        <i class="tim-icons icon-cloud-download-93"></i>
        Exportar para CSV
      </button>
    </div>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showSolicitarDevolucao },
            { 'd-none': !showSolicitarDevolucao },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showSolicitarDevolucao"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showSolicitarDevolucao"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Por favor, informe o motivo da devolução.
              </p>

              <textarea
                type="text"
                cols="100"
                rows="5"
                class="form-control"
                v-model="item_modal.devolucao_observacao"
              >
              </textarea>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Fechar
                </button>

                <button
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarSolicitacaoDevolucao()"
                >
                  Salvar
                </button>

                <button
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarDevolucaoRealizada()"
                >
                  Confirmar Devolução
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showInformarPagamento },
            { 'd-none': !showInformarPagamento },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showInformarPagamento"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showInformarPagamento"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Tem certeza que deseja informar o pagamento?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;"
                  @click="salvarInformarPagamento()"
                >
                  Sim, tenho certeza
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showDesfazerPagamento },
            { 'd-none': !showDesfazerPagamento },
            { 'modal-mini': type === 'mini' },
          ]"
          v-show="showDesfazerPagamento"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showDesfazerPagamento"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-3x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                Tem certeza que deseja desfazer o pagamento?
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="salvarDesfazerPagamento()"
                >
                  Sim, tenho certeza
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>
  </div>
</template>

<style
  lang="scss"
  scoped
  src="@/assets/sass/black-dashboard/components/_campaigns-general-table.scss"
></style>
