<script>
import InvalidDocuments from '@/components/Notifications/Documents/InvalidDocuments';

import settingsService from '@/services/Settings/settingsService';
import attachmentsService from '@/services/Attachments/attachmentsService';

import pessoasService from '@/services/Pessoas/pessoasService';

import {
  PessoaCaracteristica
} from '@/store/cwl_enums';

export default {
  data() {
    return {
      documentTypes: [
        'IdentificationDocument',
        // 'IncomeDocument',
        // 'AddressDocument',
        // 'OtherDocument',
      ],
      settings: {},
      getUploadedDocuments: {},
      getFullDocuments: {},
      pessoa: this.$CWLNewPessoaPadrao(),
      isLoading: true,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.isLoggedIn;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    pessoaId() {
      return this.$route.params.id;
    },
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    isGestorOrCurador() {
      return this.isCurador || this.isGestor;
    }
  },
  mounted() {
    this.getUserDocuments();
    this.getSettings();
  },
  methods: {
    translatedDocuments(document) {
      return {
        IdentificationDocument: 'Documento de Identificação',
        IncomeDocument: 'Documento de Renda',
        AddressDocument: 'Documento de Endereço',
        OtherDocument: 'Outro Documento',
        Default: 'Outro Documento',
      }[document ? document : 'Default'];
    },
    reverseTranslatedDocuments(document) {
      return {
        'Documento de Identificação': 'IdentificationDocument',
        'Documento de Renda': 'IncomeDocument',
        'Documento de Endereço': 'AddressDocument',
        'Outro Documento': 'OtherDocument',
      }[document ? document : 'Outro Documento'];
    },
    convertedRoutes(document) {
      if ( this.pessoaId ) {
        return {
          IdentificationDocument: '/documents-list/' + this.pessoaId,
          IncomeDocument: '/documents-list/' + this.pessoaId + '/income-document',
          AddressDocument: '/documents-list/' + this.pessoaId + '/address-document',
          OtherDocument: '/documents-list/' + this.pessoaId + '/other-document',
          Default: '/documents-list/' + this.pessoaId,
        }[document ? document : 'Default'];
      }
      else {
        return {
          IdentificationDocument: '/documents-list',
          IncomeDocument: '/documents-list/income-document',
          AddressDocument: '/documents-list/address-document',
          OtherDocument: '/documents-list/other-document',
          Default: '/documents-list',
        }[document ? document : 'Default'];
      }
    },
    backProfileValidation() {
      let allDocumentUploaded = null;

      if (
        // this.getUploadedDocuments.AddressDocument &&
        // this.getUploadedDocuments.IncomeDocument &&
        this.getUploadedDocuments.IdentificationDocument
      ) {
        allDocumentUploaded = true;
      }

      // if (this.settings.isMandatoryDocument & !allDocumentUploaded) {
      //   this.$notify({
      //     component: InvalidDocuments,
      //     icon: 'tim-icons icon-bell-55',
      //     horizontalAlign: 'center',
      //     verticalAlign: 'top',
      //     type: 'warning',
      //     timeout: 3000,
      //   });
      // } else {
        if ( this.pessoaId ) {
          this.$router.push('/profile/' + this.pessoaId);
        }
        else {
          this.$router.push('/profile');
        }
      // }
    },
    async getSettings() {
      this.settings = await settingsService.getPublicSettings();
    },
    async getUserDocuments() {
      this.isLoading = true;
      if ( this.pessoaId ) {
        this.pessoa = await pessoasService.findPessoaById(this.pessoaId);
      }

      var email = this.loggedUser.email;
      if ( this.pessoaId ) {
        email = this.pessoa.email.email;
      }
      const result = await attachmentsService.getUserDocuments(
        'InvestorDocuments',
        email,
      );

      result.items.map((item) => {
        this.getUploadedDocuments[
          this.reverseTranslatedDocuments(item.name)
        ] = true;
        this.getFullDocuments[this.reverseTranslatedDocuments(item.name)] =
          item;
      });

      this.isLoading = false;
      this.$forceUpdate();
    },
    async downloadItem(document) {
      const item = this.getFullDocuments[document];
      var email = this.loggedUser.email;
      if ( this.pessoaId ) {
        email = this.pessoa.email.email;
      }

      try {
        const path = 'InvestorDocuments/' + email;
        attachmentsService.downloadAttachment(item.name, path);
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="documents-list">
    <div class="row">
      <div class="col-12">
        <card>
          <h4 class="card-title">
            <i class="fa fa-arrow-left" @click="backProfileValidation()"></i>
            Lista de Documentos
          </h4>

          <p class="description">
            Os tipos de documentos que devem ser carregados estão abaixo:
          </p>

          <div class="row">
            <div
              class="col-lg-3 col-md-6 col-sm-12 px-3"
              v-for="(document, index) in documentTypes"
              :key="index"
            >
              <label>{{ translatedDocuments(document) }}</label>
              <router-link
                :to="convertedRoutes(document)"
                tag="button"
                class="btn btn-sm"
                v-if="!isLoading"
              >
                <span v-if="getUploadedDocuments[document]">
                  Enviado <i class="fas fa-check"></i>
                </span>
                <span v-if="!getUploadedDocuments[document]"> Selecionar </span>
              </router-link>
              <router-link
                to=""
                class="btn btn-sm"
                @click.native="downloadItem(document)"
                v-if="getFullDocuments[document]"
              >
                Download
              </router-link>
              <span v-if="isLoading" style="font-size: 10px; font-style: italic;">
                Aguarde, consultando...
              </span>
            </div>
          </div>
        </card>
      </div>

      <div class="col-12">
        <card>
          <router-view @uploadDocument="getUserDocuments"></router-view>
        </card>
      </div>
    </div>
  </div>
</template>
