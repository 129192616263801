<script>
import SocialAgentTable from '@/components/SocialTransformation/SocialAgentTable';

import pessoasService from '@/services/Pessoas/pessoasService';
import socialTransformationService from '@/services/SocialTransformation/socialTransformationService';
import authenticationService from '@/services/Authentication/authenticationService';
import claimsService from '@/services/Authentication/claimsService';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';

import Vue from 'vue';

import { TelefonePadrao } from '@/store/cwl_enums';

import logsService from '@/services/Logs/logsService';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

export default {
  components: {
    SocialAgentTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: '',
      },
      socialAgents: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getSocialAgents();
    this.table.title = 'Lista de ' + this.$t('social_transformation.title');
  },
  methods: {
    async getSocialAgents() {
      this.$refs.topProgress.start();

      const pessoas = await socialTransformationService.getUsers();
      this.socialAgents = pessoas.map((e) => ({
        userId: e.id,
        status: e.social_transformation_agent.status,
        name: e.nome,
        email: e.email ? e.email.email : null,
        phone: e.telefone ? e.telefone.telefone : null,
      }));

      if (this.socialAgents.length === 0) {
        this.$notify({
          title: 'Não há registros de agentes!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.isLoading = false;

      this.$refs.topProgress.done();
    },
    async deletePessoa(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(
          logUser,
          '',
          'Remover Usuário',
          '',
          logContent,
        );

        pessoasService.deletePessoa(preDeletedItem.item.id);

        authenticationService.deleteUser(preDeletedItem.item);

        claimsService.deleteClaim(preDeletedItem);

        this.pessoas.splice(preDeletedItem.index, 1);

        this.$notify({
          title: 'Usuário removido com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card :title="table.title" :titleBold="true">
          <div class="table-responsive">
            <social-agent-table
              :data="socialAgents"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deletePessoa"
            ></social-agent-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
