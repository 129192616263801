import firebase from 'firebase';
import { withLog } from '../../logger/with_log';
import logsService from '@/services/Logs/logsService';

export default {
  categoriasRef() {
    return firebase.firestore().collection('categorias');
  },

  async getAllCategorias() {
    try {
      const snapshot = await this.categoriasRef().orderBy('name', 'asc').get();
      if (snapshot.empty) {
        return [];
      } else {
        return snapshot.docs.map((e) => ({
          id: e.id,
          name: '',
          ...e.data(),
        }));
      }
    } catch (error) {
      console.error('get all categorias error', error);
    }
  },

  async getByCode(code) {
    try {
      const categorias = await this.getAllCategorias();
      return categorias.find(
        (e) => e.name.toLowerCase() === code.toLowerCase(),
      );
    } catch (error) {
      console.error('get categoria by code error', error);
      throw new Error('Não foi possível consultar as categorias.');
    }
  },

  async existsByCode(code) {
    console.debug('exists categoria', code);
    const categoria = await this.getByCode(code);
    return categoria !== undefined;
  },

  async createNewCategoria(categoria, user) {
    const exists = await this.existsByCode(categoria.name);
    if (exists) {
      throw new Error('Já existe uma categoria de mesmo nome!');
    }

    try {
      const doc = this.categoriasRef().doc();
      const value = {
        ...categoria,
        id: doc.id,
      };
      await doc.set(value);

      logsService.createNewLog({ user }, '', 'Criar Categoria', '', value);
    } catch (error) {
      throw new Error('Não foi possívei criar a categoria!');
    }
  },

  deleteCategoria(categoria) {
    withLog(() => this.categoriasRef().doc(categoria).delete());
  },

  async findById(id) {
    try {
      return (await this.categoriasRef().doc(id).get()).data();
    } catch (error) {
      throw new Error('Categoria não encontrada!');
    }
  },

  async update(categoria, user) {
    if (!categoria.id) {
      throw new Error('Categoria não possui identificador para atualização!');
    } else if (!categoria.name) {
      throw new Error('Categoria deve possuir um nome!');
    }

    const value = await this.getByCode(categoria.name);
    if (value && value.id !== categoria.id) {
      throw new Error('Já existe uma categoria com este nome!');
    }

    logsService.createNewLog(
      { user },
      '',
      'Atualizar Categoria',
      '',
      categoria,
    );

    return this.categoriasRef().doc(categoria.id).update(categoria);
  },
};
