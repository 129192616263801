<script>
import { PessoaCaracteristica } from '@/store/cwl_enums';
import settingsService from '@/services/Settings/settingsService';

export default {
  data() {
    return {
      reports: [{ name: 'CampaignsGeneral', permission: true }],
      modalidade: process.env.VUE_APP_TYPE,
      features: {}
    };
  },
  async mounted() {
    this.features = await settingsService.getFeaturesToggle();
    this.exibirRelatoriosPermitidos();
  },
  computed: {
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
  },
  methods: {
    exibirRelatoriosPermitidos() {
      if ( this.features.visualizar_relatorios_cvm ) {
        this.reports.push({
          name: 'AnnualReport', permission: true
        });
      }
      if ( this.features.visualizar_relatorios_vencimento_ccbs ) {
        this.reports.push({
          name: 'VencimentoCCBs', permission: true
        });
      }
    },
    translatedReports(report) {
      if (this.modalidade == 'doacao') {
        return {
          PaymentReport: 'Relatório de Pagamentos',
          OffersReport: 'Relatório de Ofertas',
          CampaignsGeneral: 'Financeiro Geral de Campanhas',
          Default: 'Relatório Anual CVM',
        }[report ? report : 'Default'];
      } else {
        return {
          AnnualReport: 'Relatório Anual CVM',
          VencimentoCCBs: 'Relatório de Vencimento CCBs',
          PaymentReport: 'Relatório de Pagamentos',
          OffersReport: 'Relatório de Ofertas',
          CampaignsGeneral: 'Financeiro Geral de Ofertas',
          Default: 'Relatório Anual CVM',
        }[report ? report : 'Default'];
      }
    },
    convertedRoutes(report) {
      return {
        AnnualReport: '/reports/annual-report',
        VencimentoCCBs: '/reports/vencimento-ccbs',
        PaymentReport: '/reports/payment',
        OffersReport: '/reports/offers',
        CampaignsGeneral: '/reports/campaigns-general',
        Default: '/reports',
      }[report ? report : 'Default'];
    },
  },
};
</script>

<template>
  <div class="reports-list">
    <div class="row">
      <div class="col-12">
        <card :title="'Relatórios'">
          <p>Selecione o tipo de relatório que você deseja gerar:</p>

          <div class="row mt-4">
            <router-link
              v-for="(report, index) in reports"
              :key="index"
              tag="div"
              :to="convertedRoutes(report.name)"
              class="col-lg-4 col-md-6 col-sm-12 px-3"
            >
              <label>{{ translatedReports(report.name) }}</label>
              <button class="btn btn-sm">Selecionar</button>
            </router-link>
          </div>
        </card>
      </div>

      <div class="col-12">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
