<script>
import statesService from '@/services/States/statesService';
import UpdatedStateNotification from '@/components/Notifications/States/UpdatedStateNotification';
import UpdatedErrorNotification from '@/components/Notifications/General/UpdatedErrorNotification';
import GetStateErrorNotification from '@/components/Notifications/States/GetStateErrorNotification';
import {vueTopprogress} from 'vue-top-progress';
import logsService from '@/services/Logs/logsService';

export default {
  data() {
    return {
      state: {},
      cachedState: {},
      customMask: {
        mask: 'FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
        tokens: {
          'F': {
            pattern: /^[a-zA-Z\s]*$/
          },
        }
      },
    };
  },
  components: {
    vueTopprogress,
  },
  computed: {
    stateId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    }
  },
  mounted() {
    this.getState();
  },
  methods: {
    async getState() {
      this.$refs.topProgress.start();

      try {
        statesService.getState(this.stateId)
            .then(function(state) {
              this.state = state.data();
              this.cachedState = state.data();

              this.$refs.topProgress.done();
            }.bind(this));
      } catch (error) {
        this.$notify({
          component: GetStateErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async updateState() {
      this.$refs.topProgress.start();

      try {
        this.$refs.topProgress.done();

        const logUser = {
            user: {
                email: this.currentPessoa.email.email
            }
        };
        const logContent = this.state;
        logsService.createNewLog(logUser, '', 'Atualizar Estado', '', logContent);

        await statesService.updateState(this.stateId, this.state);

        this.$router.push('/states');

        this.$notify({
          component: UpdatedStateNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          component: UpdatedErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="add-user">
    <card>
      <form @submit.prevent="updateState()">
        <h5 slot="header" class="title">
          <router-link to="/states" class="fa fa-arrow-left"></router-link>
          Editar Estado
        </h5>

        <div class="row">
          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Nome do Estado*"
              placeholder="Nome do Estado"
              v-model="state.name"
              required
              v-mask="customMask"
            ></base-input>
          </div>

          <div class="col-md-4 pr-md-1 mb-2">
            <base-input
              label="Nome do UF*"
              placeholder="Nome do UF"
              v-model="state.uf"
              v-mask="['AA']"
              required
            ></base-input>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-sm"
        >
          Editar
        </button>
      </form>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
