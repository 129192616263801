<script>
import SegmentsTable from '@/components/Segments/SegmentsTable';
import segmentsService from '@/services/Segments/segmentsService';

import EmptySegmentNotification from '@/components/Notifications/Segments/EmptySegmentNotification';
import DeleteSegmentSuccessNotification from '@/components/Notifications/Segments/DeleteSegmentSuccessNotification';
import DeleteSegmentErrorNotification from '@/components/Notifications/Segments/DeleteSegmentErrorNotification';

import { vueTopprogress } from 'vue-top-progress';
import { DoubleBounce } from 'vue-loading-spinner';

import logsService from '@/services/Logs/logsService';

const tableColumns = ['Nome'];

export default {
  components: {
    SegmentsTable,
    vueTopprogress,
    DoubleBounce,
  },
  data() {
    return {
      table: {
        title: 'Lista Interesse de Segmentos',
        columns: [...tableColumns],
      },
      segments: [],
      isLoading: true,
      closeModal: false,
    };
  },
  computed: {
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getSegments();
  },
  methods: {
    async getSegments() {
      this.segments = [];
      this.$refs.topProgress.start();

      try {
        this.segments = await segmentsService.getAllSegments();
        if (this.segments.length === 0) {
          this.$notify({
            component: EmptySegmentNotification,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });
        }
      } catch (error) {}

      this.isLoading = false;

      this.$refs.topProgress.done();
    },
    deleteSegment(preDeletedItem) {
      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = preDeletedItem.item;
        logsService.createNewLog(
          logUser,
          '',
          'Remover Segmento',
          '',
          logContent,
        );

        segmentsService.deleteSegment(preDeletedItem.item.id);

        this.getSegments();

        this.$notify({
          component: DeleteSegmentSuccessNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        preDeletedItem = {};
      } catch (error) {
        this.$notify({
          component: DeleteSegmentErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card
          :title="table.title"
          :addItems="true"
          :itemType="'Segmento'"
          :routerType="'segment'"
        >
          <div class="table-responsive">
            <segments-table
              :data="segments"
              :columns="table.columns"
              :hasActions="true"
              thead-classes="text-primary"
              @deleteItem="deleteSegment"
            ></segments-table>

            <double-bounce v-if="isLoading"></double-bounce>
          </div>
        </card>
      </div>
    </div>

    <vue-topprogress ref="topProgress"></vue-topprogress>
  </div>
</template>
