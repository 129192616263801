import firebase from 'firebase';
import { withLog } from '../../logger/with_log';

export default {
  segmentRef() {
    return firebase.firestore().collection('segment-interest');
  },

  async getAllSegments() {
    try {
      const snapshot = await this.segmentRef().orderBy('name', 'asc').get();
      if (snapshot.empty) {
        return [];
      }
      return snapshot.docs.map((e) => ({
        id: e.id,
        ...e.data(),
      }));
    } catch (error) {
      console.error('get all segments error', error);
    }
  },

  createNewSegment(segment) {
    withLog(() => this.segmentRef().add(segment));
  },

  deleteSegment(bank) {
    withLog(() => this.segmentRef().doc(bank).delete());
  },

  getSegment(id) {
    return withLog(() => this.segmentRef().doc(id).get());
  },

  updateSegment(id, segment) {
    return withLog(() => this.segmentRef().doc(id).update(segment));
  },
};
