export const formatCurrency = (value) => {
  return (
    'R$ ' +
    parseFloat(value || 0)
      .toFixed(2)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  );
};

export default {
  formatCurrency,
};
